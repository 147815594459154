/**
 * Populates a custom series name like "{Page} from {Browser}" with dimension values fromthe conditions object
 * Note: This method was copied over from Jarvis and slightly refactored
 */
export const populateTemplate = (template: string, templateVariables: Map<string, string>): string => {
  if (!templateVariables) {
    return template;
  }

  template = template.replace(/{([^\\{\\}]+)}/gi, (_, condition) => {
    return templateVariables.get(condition) ?? "";
  });

  if (!template) {
    return "Total";
  }

  return template;
};
