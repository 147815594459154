import { DataSourceInstanceSettings } from "@grafana/data";
import BackendDataSource from "BackendDataSource";
import { GenevaJsonData } from "types";
import { createLoader } from "util/createLoader";

export const cloudConfigLoader = createLoader({
  id: "loadCloudConfig",

  load: async (dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>) => {
    const backend = new BackendDataSource(dataSourceInstanceSettings);
    const cloudConfig = await backend.loadCloudConfig();

    return cloudConfig;
  },
});
