// disabling since none of the vulnerabilities came from user input
/* eslint-disable security/detect-object-injection */
import {
  ArrayVector,
  DataQueryResponseData,
  DataSourceInstanceSettings,
  FieldDTO,
  FieldType,
  MutableDataFrame,
  NodeGraphDataFrameFieldNames,
} from "@grafana/data";
import { MinInterval, ResourceHealthStatus } from "../api/constants";
import _, { defaults } from "lodash";
import {
  AnnotationDataFrameFieldNames,
  defaultQuery,
  GenevaAnnotation,
  GenevaJsonData,
  GenevaQuery,
  HealthResource,
  HealthTransforms,
  Watchdog,
} from "../types";
import { v4 } from "uuid";
import { getFolderDisplayName, getMonitorDisplayName } from "util/monitorUtils";

const transformPercent = (val: number): number => {
  switch (val) {
    case 0:
      return 0;
    case 1:
      return 100;
    case 2:
      return 50;
    default:
      return val;
  }
};

export const convertHealthHistorySeriesResponse = (
  target: GenevaQuery,
  response: number[][],
  from: number,
  to: number
): DataQueryResponseData => {
  const query = defaults(target, defaultQuery);
  const { refId } = query;
  return convertHealthHistoryResponse(
    response,
    target.selectedResources?.map((r) => r.name) || [],
    target.healthHistoryValueTransform || "raw",
    refId,
    from,
    to
  );
};

export const convertHealthHistoryResponse = (
  response: number[][],
  resourceNames: string[],
  transform: HealthTransforms | undefined,
  refId: string,
  from: number,
  to: number
): DataQueryResponseData => {
  // duration of the time range, in milliseconds.
  const duration = to - from;
  const fields = [{ name: "time", type: FieldType.time }];

  const resourcesMap = new Map<string, string>();
  for (const resource of resourceNames) {
    const key = v4();
    fields.push({ name: key, type: FieldType.number });
    resourcesMap.set(key, resource);
  }
  const frame = new MutableDataFrame({
    refId,
    fields,
  });

  let count = 0;
  const step = MinInterval;
  for (let t = 0; t < duration; t += step) {
    // TODO fix this usage of any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = { time: from + t };

    let i = 0;
    for (const key of resourcesMap.keys()) {
      const series = response[i];
      if (transform === "percent") {
        obj[`${key}`] = transformPercent(series[count]);
      } else {
        obj[`${key}`] = series[count];
      }
      i++;
    }

    frame.add(obj);
    count++;
  }

  for (const field of frame.fields) {
    if (resourcesMap.has(field.name)) {
      field.name = resourcesMap.get(field.name) || field.name;
    }
  }
  return frame;
};

export const convertHealthAnnotationsResponse = (
  allAnnotations: GenevaAnnotation[][],
  refId: string
): DataQueryResponseData => {
  const nodeFields: Record<string, Omit<FieldDTO, "name"> & { values: ArrayVector }> = {
    [AnnotationDataFrameFieldNames.id]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
    [AnnotationDataFrameFieldNames.title]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
    [AnnotationDataFrameFieldNames.text]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
    [AnnotationDataFrameFieldNames.time]: {
      values: new ArrayVector(),
      type: FieldType.number,
    },
  };
  const annoFrame = new MutableDataFrame({
    name: "annotations",
    refId: refId,
    fields: Object.keys(nodeFields).map((key) => ({
      ...nodeFields[key],
      name: key,
    })),
  });

  for (const annotations of allAnnotations) {
    for (const anno of annotations) {
      const timestamp = new Date(anno.timestamp);
      nodeFields.id.values.add(anno.id);
      nodeFields.title.values.add(anno.displayName);
      nodeFields.text.values.add(anno.content);
      nodeFields.time.values.add(timestamp.getTime());
    }
  }

  return annoFrame;
};

const getNodeFields = (): Record<string, Omit<FieldDTO, "name"> & { values: ArrayVector }> => {
  return {
    [NodeGraphDataFrameFieldNames.id]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
    ["name"]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
    ["healthStatus"]: {
      values: new ArrayVector(),
      type: FieldType.number,
      config: { displayName: "Health Status" },
    },
    ["hasChildren"]: {
      values: new ArrayVector(),
      type: FieldType.boolean,
    },
    ["hasResourceId"]: {
      values: new ArrayVector(),
      type: FieldType.boolean,
    },
    ["parentId"]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
    ["suppressionState"]: {
      values: new ArrayVector(),
      type: FieldType.number,
    },
    ["suppressionReason"]: {
      values: new ArrayVector(),
      type: FieldType.number,
    },
    ["resourceType"]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
    ["resourceName"]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },

    //Monitor specific properties
    ["metadata"]: {
      values: new ArrayVector(),
      type: FieldType.other,
    },
    ["displayName"]: {
      values: new ArrayVector(),
      type: FieldType.string,
    },
  };
};

export const convertWatchdogTopologyResponse = (watchdogs: Watchdog[], refId?: string): MutableDataFrame => {
  const nodeFields = getNodeFields();
  const nodeFrame = new MutableDataFrame({
    name: "nodes",
    refId: refId,
    fields: Object.keys(nodeFields).map((key) => ({
      ...nodeFields[key],
      name: key,
    })),
  });

  for (const watchdog of watchdogs) {
    const parentId = v4();
    nodeFields.id.values.add(parentId);
    nodeFields.name.values.add(watchdog.category);
    nodeFields.displayName.values.add(getFolderDisplayName(watchdog.category));
    nodeFields.parentId.values.add(null);
    nodeFields.hasChildren.values.add(watchdog.monitors && watchdog.monitors.length > 0);
    nodeFields.hasResourceId.values.add(false);
    nodeFields.resourceType.values.add(null);
    nodeFields.resourceName.values.add(null);
    nodeFields.suppressionState.values.add(null);
    nodeFields.suppressionReason.values.add(null);
    nodeFields.metadata.values.add({});
    for (const monitor of watchdog.monitors) {
      nodeFields.id.values.add(v4());
      nodeFields.name.values.add(monitor.name);
      nodeFields.displayName.values.add(getMonitorDisplayName(monitor));
      nodeFields.hasChildren.values.add(false);
      nodeFields.metadata.values.add(monitor.metadata);
      nodeFields.parentId.values.add(parentId);

      nodeFields.hasResourceId.values.add(false);
      nodeFields.resourceType.values.add(null);
      nodeFields.resourceName.values.add(null);
      nodeFields.suppressionState.values.add(null);
      nodeFields.suppressionReason.values.add(null);
    }

    const monitorHealth = watchdog.monitors.map((m) => m.healthStatus);
    const anyDegraded = monitorHealth.some((h) => h === ResourceHealthStatus.Degraded);
    const anyFailing = monitorHealth.some((h) => h === ResourceHealthStatus.Unhealthy);
    let allHealth: number[] = [];
    if (anyFailing) {
      allHealth = [ResourceHealthStatus.Unhealthy, ...monitorHealth];
    } else if (anyDegraded) {
      allHealth = [ResourceHealthStatus.Degraded, ...monitorHealth];
    } else {
      allHealth = [ResourceHealthStatus.Healthy, ...monitorHealth];
    }
    for (const h of allHealth) {
      nodeFields.healthStatus.values.add(h);
    }
  }
  return nodeFrame;
};

export const convertHealthTopologyResponse = (
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>,
  resource?: HealthResource,
  refId?: string,
  account?: string,
  stamp?: string
): MutableDataFrame => {
  if (!resource || !resource.children) {
    return new MutableDataFrame(undefined);
  }
  const nodeFields = getNodeFields();
  const customProps = {
    Geneva_Account: account,
    Geneva_Stamp: stamp,
    Geneva_Health_Resource: resource.id,
    // Our use case with the health panel is unique. Typical Grafana panels have all
    // of their data provided via the DataSource query method. The health panel itself,
    // however, will make queries as the user explores the hierarchy of health
    // resources. In order to make these queries the panel needs access to which data
    // source the user has selected. Unfortunately, this info isn't provided by Grafana's
    // panel API. So instead we have this hack here to give the panel the data source
    // information it needs to function properly.
    dataSourceInstanceSettings,
  };

  const nodeFrame = new MutableDataFrame({
    name: "nodes",
    refId: refId,
    fields: Object.keys(nodeFields).map((key) => ({
      ...nodeFields[key],
      name: key,
    })),
    meta: { custom: customProps },
  });

  nodeFields.id.values.add(resource.id);
  nodeFields.name.values.add(resource.name);
  nodeFields.healthStatus.values.add(resource.healthStatus);
  nodeFields.hasChildren.values.add(resource.hasChildren);
  nodeFields.hasResourceId.values.add(!!resource.resourceId);
  nodeFields.resourceType.values.add(resource.resourceId?.type);
  nodeFields.resourceName.values.add(resource.resourceId?.name);
  nodeFields.suppressionState.values.add(resource.suppressionState);
  nodeFields.suppressionReason.values.add(resource.suppressionReason);
  nodeFields.parentId.values.add(null);
  for (const child of resource.children) {
    nodeFields.id.values.add(child.id);
    nodeFields.name.values.add(child.name);
    nodeFields.healthStatus.values.add(child.healthStatus);
    nodeFields.hasChildren.values.add(child.hasChildren);
    nodeFields.hasResourceId.values.add(!!child.resourceId);
    nodeFields.resourceType.values.add(child.resourceId?.type);
    nodeFields.resourceName.values.add(child.resourceId?.name);
    nodeFields.suppressionState.values.add(child.suppressionState);
    nodeFields.suppressionReason.values.add(child.suppressionReason);
    nodeFields.parentId.values.add(resource.id);
  }
  return nodeFrame;
};
