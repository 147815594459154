/* eslint-disable import/no-unused-modules */
import { css } from "@emotion/css";
import { AppEvents } from "@grafana/data";
import { config, getAppEvents, getBackendSrv } from "@grafana/runtime";
import { Button, Card, FilterInput, LinkButton, RadioButtonGroup, TagList } from "@grafana/ui";
import { designDashboard, lensExplorerDashboard, promDashboard } from "galleryDashboards/galleryDashboards";
import { GrafanaIcon } from "pages/Icons/GrafanaIcon";
import React, { memo, useState } from "react";
import { GalleryDashboard, GalleryTag } from "types/GalleryTypes";
import { GalleryBrowseActions } from "../GalleryActions/GalleryBrowseActions";
import { useSearchStateManager } from "../SearchState/SearchStateManager";

//track the list of dashboards that have been shared by you and other users
export const sharedList: GalleryDashboard[] = [
  {
    title: "Design Team Dashboard",
    description: "Keep tabs on design team usage metrics",
    tags: [GalleryTag.am],
    logo: undefined,
    json: designDashboard,
  },
  {
    title: "Prometheus Team Dashboard",
    description: "Track Prometheus team's user metrics",
    tags: [GalleryTag.prom, GalleryTag.adx],
    logo: undefined,
    json: promDashboard,
  },
  {
    title: "Lens Explorer Dashboard",
    description: "Queries Azure Data Explorer and Geneva Datasources",
    tags: [GalleryTag.adx, GalleryTag.geneva],
    logo: undefined,
    json: lensExplorerDashboard,
  },
];
// track the list of dashboards that you have shared
export const yourList: GalleryDashboard[] = [
  {
    title: "Prometheus Team Dashboard",
    description: "Track Prometheus team's user metrics",
    tags: [GalleryTag.prom, GalleryTag.adx],
    logo: undefined,
    json: promDashboard,
  },
  {
    title: "Lens Explorer Dashboard",
    description: "Queries Azure Data Explorer and Geneva Datasources",
    tags: [GalleryTag.adx, GalleryTag.geneva],
    logo: undefined,
    json: lensExplorerDashboard,
  },
];
const appEvents = getAppEvents();

const BrowseDashboardsPage = memo(() => {
  const styles = getStyles();
  const [searchState, stateManager] = useSearchStateManager();
  const [currTab, onTabChange] = useState<"Shared" | "Shared by You">("Shared");
  const currDashes = currTab === "Shared" ? sharedList : yourList;

  return (
    <div>
      <div className={styles.radio}>
        <RadioButtonGroup
          options={[
            { label: "Shared", value: "Shared" },
            { label: "Shared by You", value: "Shared by You" },
          ]}
          onChange={onTabChange}
          value={currTab}
          size="md"
        />
        <LinkButton variant="primary" href={`${config.appSubUrl}/a/geneva-app/dashboardgallery/share`}>
          Share a dashboard
        </LinkButton>
      </div>
      <div className={styles.search}>
        <FilterInput
          placeholder={searchState.currTab === "Shared" ? "Search shared dashboards" : "Search your dashboards"}
          value={searchState.query}
          escapeRegex={false}
          onChange={(q) => stateManager.onQueryChange(q)}
        />
      </div>
      <div className={styles.padding}>
        <GalleryBrowseActions />
      </div>
      <div className={styles.tileContainer}>
        {currDashes.map((tile) => (
          <div>
            <Card className={styles.tile}>
              <Card.Figure className={styles.leftTileItems}>
                {tile.logo ? <img src={tile.logo} alt="Logo" width="40" height="40" /> : <GrafanaIcon />}
              </Card.Figure>
              <Card.Heading className={css({ alignItems: "start" })}>{tile.title}</Card.Heading>
              <Card.Description className={styles.leftTileItems}>{tile.description}</Card.Description>
              <Card.Tags className={styles.leftTileItems}>
                <TagList tags={tile.tags} />
              </Card.Tags>
              <Button
                className={styles.rightTileItems}
                variant="primary"
                fill="solid"
                size="md"
                style={{ margin: "5px" }}
                onClick={() => {
                  getBackendSrv().post("/api/dashboards/import", {
                    dashboard: tile.json ? tile.json : undefined,
                    folderUid: "",
                    inputs: [],
                    overwrite: true,
                  });
                  appEvents.publish({
                    type: AppEvents.alertSuccess.name,
                    payload: ["Dashboard imported successfully"],
                  });
                  return appEvents;
                }}
              >
                {currTab === "Shared" ? "Import" : "Edit"}
              </Button>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
});

const getStyles = () => {
  return {
    tile: css({
      alignItems: "flex-end",
      alignSelf: "flex-end",
      width: "280px",
      height: "220px",
      display: "flex",
      flexDirection: "column",
      justifySelf: "flex-end",
    }),
    tileContainer: css({
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      flexWrap: "wrap",
    }),
    leftTileItems: css({
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignSelf: "flex-start",
    }),
    rightTileItems: css({
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignSelf: "flex-end",
    }),
    radio: css({
      flexDirection: "row",
      padding: "5px",
      display: "flex",
      justifyContent: "space-between",
    }),
    search: css({
      display: "flex",
      gap: "20px",
      padding: "5px",
    }),
    padding: css({ padding: "5px" }),
  };
};

// eslint-disable-next-line import/no-named-as-default-member
export class BrowseGalleryPage extends React.PureComponent {
  override render() {
    return <BrowseDashboardsPage />;
  }
}
