import { getBackendSrv } from "@grafana/runtime";

//https://grafana.com/docs/grafana/latest/http_api/user/#actual-user
interface User {
  id: string;
  email: string;
  name: string;
}

export const getUserEmail = () => {
  return getBackendSrv()
    .get("/api/user")
    .then((data: User) => {
      if (data && data.email) {
        return data.email;
      }
      return "";
    })
    .catch(() => {
      return undefined;
    });
};
