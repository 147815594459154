import { DataSourceInstanceSettings } from "@grafana/data";
import { getTemplateSrv } from "@grafana/runtime";
import BackendDataSource from "BackendDataSource";
import { GenevaJsonData } from "types";
import { createLoader } from "util/createLoader";

export const stampLoader = createLoader({
  id: "stamps",

  replaceVariables: (dataSourceInstanceSettings, account) =>
    [dataSourceInstanceSettings, getTemplateSrv().replace(account)] as const,

  load: async (dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>, account: string) => {
    const backend = new BackendDataSource(dataSourceInstanceSettings);
    const stamp = await backend.getStamps(account);
    if (!stamp) {
      // explicitly return undefined,
      // as editor will check for this value to render the stamp selector
      return undefined;
    }
    return stamp;
  },
});
