import { DataSourceInstanceSettings } from "@grafana/data";
import React, { createContext, useContext } from "react";
import { GenevaJsonData } from "../types";

const context = createContext<DataSourceInstanceSettings<GenevaJsonData> | null>(null);

interface Props {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  children?: React.ReactNode;
}

export function ProvideDataSourceInstanceSettings(props: Props) {
  return <context.Provider value={props.dataSourceInstanceSettings}>{props.children}</context.Provider>;
}

export function useDataSourceInstanceSettings() {
  const dataSourceInstanceSettings = useContext(context);

  if (dataSourceInstanceSettings === null) {
    throw new Error("Provide data source instance settings before consuming them.");
  }

  return dataSourceInstanceSettings;
}
