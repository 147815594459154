/* eslint-disable security/detect-object-injection */
import { unescapeParamName } from "@azure-observability-ecg-grafana/data-migration";
import {
  DataFrame,
  Field,
  FieldType,
  MutableDataFrame,
  ScopedVars,
  SelectableValue,
  TypedVariableModel,
} from "@grafana/data";
import { DataSourceSrv, getDataSourceSrv, getTemplateSrv } from "@grafana/runtime";
import { VariableFormatID } from "@grafana/schema";
import _ from "lodash";
import { useMemo } from "react";
import {
  DGREP_LABEL,
  DGREP_SERVICE,
  GenevaServiceConfig,
  GenevaServiceType,
  GenevaVariableQuery,
  GenevaVariableQueryType,
  HEALTH_LABEL,
  HEALTH_SERVICE,
  JSEXP_LABEL,
  JSEXP_SERVICE,
  METRICS_LABEL,
  METRICS_SERVICE,
  TRACES_LABEL,
  TRACES_SERVICE,
  TraceFilterName,
  TransactionSearchFilter,
} from "types";

export const dashboardTime = "dashboard time";

const duration = (() => {
  const seconds = 1000;
  const minutes = seconds * 60;
  const hours = minutes * 60;
  const days = hours * 24;
  return { seconds, minutes, hours, days };
})();

export function namespaceInQueryText(qt: string) {
  const qTRegex = /metricNamespace[(]"\S+"[)]/;
  return !!qTRegex.exec(qt ?? "");
}

export const arrToRec = (dims: string[] | undefined, values: string[][] | undefined) => {
  const filtersRec: Record<string, string[]> = {};

  if (values === undefined || dims === undefined) {
    return filtersRec;
  }

  for (let i = 0; i < values.length; i++) {
    const key = dims[i];
    filtersRec[`${key}`] = values[i];
  }

  return filtersRec;
};

// Sort selectable value string arrays alphabetically
export const sortOptions = (options: SelectableValue<string>[]): SelectableValue<string>[] => {
  return options.sort((a, b) => {
    const aLabel = a.label?.toUpperCase() ?? "";
    const bLabel = b.label?.toUpperCase() ?? "";
    if (!aLabel) {
      return 1;
    } else if (!bLabel) {
      return -1;
    }
    return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
  });
};

export const parseInterval = (interval: string | undefined) => {
  if (!!interval) {
    const parsed = interval?.split(/(\D)/, 2);
    if (Number(parsed[0]) < 60 && parsed[1] === "s") {
      return ["1", "m"];
    }
    return parsed;
  }
  return ["1", "m"];
};

// Params in API calls need double encoded
export const doubleEncode = (value?: string) =>
  encodeURIComponent(encodeURIComponent(value || ""))
    .replace(/\./gi, "%252E")
    .replace(/'/g, "%27");

export const useVariableOptions = (options: SelectableValue[], variableOptions: SelectableValue) =>
  useMemo(
    () =>
      _.concat(options, {
        label: "Template Variables",
        options: variableOptions,
      }),
    [options, variableOptions]
  );

export const isVariableValueAll = (variables: TypedVariableModel[], variableName: string): boolean => {
  const valueStart = variableName.indexOf("$");
  if (valueStart >= 0) {
    const value = variableName.substring(valueStart + 1);
    return variables.some(
      (variable) =>
        variable.name === value &&
        (_.get(variable, "current.text") === "All" || _.get(variable, "current.text.0") === "All")
    );
  }
  // not a variable, so just return false
  return false;
};

export const generateFilterQueryText = (
  dimensionFilter: string,
  dimensionFilters: string[],
  dimensionFilterValues: string[][] | string[]
): string => {
  let filterQueryText = "";
  const set = new Set<string>();
  for (let t = 0; t < dimensionFilters.length; t++) {
    const dimensionFilterVal = unescapeParamName(dimensionFilters[t]);
    if (set.has(dimensionFilterVal)) {
      // prevent duplicate filters
      continue;
    }
    set.add(dimensionFilterVal);
    if (
      dimensionFilterVal === dimensionFilter ||
      (dimensionFilterVal.length > 0 && !dimensionFilterValues[t]?.length)
    ) {
      filterQueryText += `/${doubleEncode(dimensionFilterVal)}/{{*}}`;
    } else if (typeof dimensionFilterValues[t] === "string") {
      const filterString =
        dimensionFilterValues[t] === "None" ? "{{*}}" : doubleEncode(dimensionFilterValues[t] as string);
      filterQueryText += `/${doubleEncode(dimensionFilterVal)}/${filterString}`;
    } else {
      for (let s = 0; s < dimensionFilterValues[t].length; s++) {
        const filterString =
          dimensionFilterValues[t][s] === "None" ||
          dimensionFilterValues[t][s] === "All" ||
          dimensionFilterValues[t][s] === ""
            ? "{{*}}"
            : doubleEncode(dimensionFilterValues[t][s]);
        filterQueryText += `/${doubleEncode(dimensionFilterVal)}/${filterString}`;
      }
    }
  }
  return !filterQueryText
    ? (filterQueryText = `/${doubleEncode(dimensionFilter)}/{{*}}/${doubleEncode(dimensionFilter)}/value`)
    : `${filterQueryText}/${doubleEncode(dimensionFilter)}/value`;
};

export function stringifyTimeRangeValue(milliseconds: number) {
  if (milliseconds >= 0) {
    return stringifyMilliseconds(milliseconds);
  }

  return stringifyMilliseconds(-milliseconds);
}

function stringifyMilliseconds(milliseconds: number) {
  const units = [
    { value: milliseconds / duration.seconds, symbol: "s", threshold: 60 },
    { value: milliseconds / duration.minutes, symbol: "m", threshold: 60 },
    { value: milliseconds / duration.hours, symbol: "h", threshold: 24 },
    { value: milliseconds / duration.days, symbol: "d", threshold: Infinity },
  ];

  let i = 0;
  while (units[i].value >= units[i].threshold) {
    i++;
  }

  // If unit remainder is greater than 5% use the previous unit to represent
  // this value e.g. a duration of 1.5 hours will be returned as 90 minutes.
  while (Math.abs(Math.round(units[i].value) - units[i].value) > 0.05 && i > 0) {
    i--;
  }

  const value: string = Math.round(units[i].value).toString();
  const unit: string = units[i].symbol;
  return [value, unit];
}

export function getAbsoluteTime(milliseconds: number) {
  if (milliseconds >= 0) {
    return milliseconds;
  }

  return Date.now() + milliseconds;
}

export const getStampsFromVar = (stampParam?: string | string[]) => {
  const stampVars = typeof stampParam === "string" ? [stampParam] : stampParam;
  return stampVars
    ?.map((stampVar) => {
      const stampVarTemplate = getTemplateSrv().replace(stampVar);
      const stamp = stampVarTemplate.includes("{")
        ? stampVarTemplate.slice(1, stampVarTemplate.length - 1).split(",")
        : stampVarTemplate;
      return stamp;
    })
    .flat();
};

export function findQueryParameterValues(
  queryText: string,
  variables: TypedVariableModel[],
  existingQueryParamValues?: Record<string, string | number>
): Record<string, string | number> {
  const record: Record<string, string | number> = {};

  // Parameters are defined as "declare query_parameters(PARAM_NAME:DATA_TYPE[=DEFAULT_VAL], PARAM_NAME:DATA_TYPE[=DEFAULT_VAL], ...);"
  // We need to find all the Param_Name that are defined for the KQLM matching this pattern.
  const regexBase = "^\\s*declare query_parameters\\s*\\(([^)]*)\\);";
  const findAllRegex = new RegExp(regexBase, "img");

  const declarations = queryText.match(findAllRegex);
  if (!declarations) return record;

  declarations.forEach((declaration) => {
    const findGroupRegex = new RegExp(regexBase, "im");
    const declaredParameters = declaration.match(findGroupRegex);
    if (!declaredParameters || declaredParameters.length !== 2) {
      return;
    }
    declaredParameters[1].split(",").forEach((pair) => {
      // note: defaultValue can sometimes be null if not specified with a default value in query
      const [nameAndType, defaultValue] = pair.split("=");
      const [name, type] = nameAndType.split(":");
      const trimmedName = name.trim();
      const dashboardVariable = variables.find((variable) => {
        return variable.id === name;
      });
      let valueToUse: string | number =
        type?.trim() === "string"
          ? defaultValue?.trim().replace(/^"(.*)"$/, "$1")
          : defaultValue?.trim().replace(/^(.*)$/, "$1");
      if (dashboardVariable) {
        valueToUse = `$${dashboardVariable.id}`;
      }
      record[`${trimmedName}`] = existingQueryParamValues?.[`${trimmedName}`] ?? valueToUse;
    });
  });
  return record;
}

/**
 * This method is used as a Function for templateSrv.replace() to replace variables with array values to a string.
 * ie: templateSrv.replace(<some Query>, scopedVars, convertPossibleArrayToString);
 * _variable and _formatVariable value are not used, but set here as placeholders for the function signature.
 * @param v
 * @param _variable
 * @param _formatVariableValue
 * @returns string with resolved variable values
 */
export const convertPossibleArrayToString = (
  v: string | string[],
  _variable?: TypedVariableModel,
  _formatVariableValue?: Function
) => (Array.isArray(v) ? v.join('", "') : v);

export function getFilterValues(filters: TransactionSearchFilter[], filterName: TraceFilterName): string[] {
  const filterToUse: TransactionSearchFilter | undefined = filters.find((filter) => filter.fieldName === filterName);
  const filterValues = filterToUse?.values;
  if (filterValues && filterValues.length > 0) {
    return filterValues;
  }

  return [];
}

export function processTransactionSearchFilters(
  transactionSearchFilters: TransactionSearchFilter[],
  additionalFilters: TransactionSearchFilter[]
): TransactionSearchFilter[] {
  const newFilters = [...transactionSearchFilters];

  for (const additionalFilter of additionalFilters) {
    // check if filter already exists in transaction search filters
    if (!!additionalFilter.fieldName && !!additionalFilter.operator && !!additionalFilter.values) {
      const filterExists = newFilters.findIndex((filter) => filter.fieldName === additionalFilter.fieldName);

      if (filterExists === -1) {
        // filter does not exist - push it
        newFilters.push(additionalFilter);
      } else {
        // filter exists, update operator and values
        newFilters[filterExists].operator = additionalFilter.operator;
        newFilters[filterExists].values = additionalFilter.values;
      }
    }
  }
  return newFilters;
}

export function parseFilterSelectionAll(
  filterName: TraceFilterName,
  selectedFilterValues: string[],
  currentFilterValues: string[]
): string[] {
  const containsAll =
    (filterName === "kind" && selectedFilterValues.includes("5")) ||
    (filterName === "dataRegion" && selectedFilterValues.includes("all"));
  const allValue = filterName === "kind" ? "5" : "all";

  // if all is the newly selected filter values
  if (containsAll) {
    if (currentFilterValues.includes(allValue)) {
      // check if the pervious filter values contain all, if the previously filter values already had all, remove it
      return selectedFilterValues.filter((val) => val !== allValue);
    } else {
      // otherwise, remove everything but all
      return selectedFilterValues.filter((val) => val === allValue);
    }
  }
  return selectedFilterValues;
}

export function generateExploreQuery(service: string, serviceType?: string) {
  const datasourceSrv: DataSourceSrv = getDataSourceSrv();
  const allDs = datasourceSrv.getList();
  const filtered = allDs.filter(
    (datasource) =>
      datasource.type === "geneva-datasource" &&
      // disabling for next type since azureCredentials is explicit to datasources that use azure auth
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (datasource.jsonData as any).azureCredentials?.authType === "currentuser"
  );
  const selectedDatasource = filtered[0];
  const datasourceObj = !!selectedDatasource
    ? { uid: selectedDatasource.uid, type: selectedDatasource.type }
    : "Geneva Datasource";
  const query = {
    datasource: "Geneva Datasource",
    queries: [
      {
        refId: "A",
        datasource: datasourceObj,
        service: service,
        traceQueryType: serviceType ?? undefined,
        queryType: service,
      },
    ],
    range: {
      from: "now - 6h",
      to: "now",
    },
  };

  return encodeURIComponent(JSON.stringify(query));
}

function getParamsFromDimensionValuesVariable(
  query: string,
  withStamp?: boolean
): {
  account: string;
  namespace: string;
  metric: string;
  dimension: string;
  stamp?: string;
  deps: string[];
} | null {
  const regex = withStamp
    ? /^DimensionValuesWithStamp\(["']?([^\)]+?)["']?\)/i
    : /^DimensionValues\(["']?([\w\W]+)["']?\)/i;
  const dimensionValuesQuery = query.match(regex);
  if (dimensionValuesQuery) {
    // Get params before '[', i.e. account, namespace, metric and dimension.
    const matches = _.get(dimensionValuesQuery[1].match(/.+?(?=\,\s*\[)/g), 0, dimensionValuesQuery[1]).split(",");

    if (withStamp) {
      const [account, namespace, metric, dimension, stamp] = matches.slice(-5).map((v: string) => v.trim());
      // Get params between '[' and ']', i.e. dependent dimensions.
      // If dimension name starts with $, it is a variable and we get its variable name.
      const deps =
        _.first(dimensionValuesQuery[1].match(/\[(.*?]*)\]/g))
          ?.replace(/[\[\]']+/g, "")
          ?.split(",")
          .map((v: string) => v.trim()) ?? [];

      return { account, namespace, metric, stamp, dimension, deps };
    } else {
      const [account, namespace, metric, dimension] = matches.slice(-4).map((v: string) => v.trim());
      // Get params between '[' and ']', i.e. dependent dimensions.
      // If dimension name starts with $, it is a variable and we get its variable name.
      const deps =
        _.first(dimensionValuesQuery[1].match(/\[(.*?]*)\]/g))
          ?.replace(/[\[\]']+/g, "")
          ?.split(",")
          .map((v: string) => v.trim()) ?? [];

      return { account, namespace, metric, dimension, deps };
    }
  } else {
    return null;
  }
}

/**
 * Converts old query text into a GenevaVariableQuery object
 * @param query as a string
 * @returns query as a GenevaVariableQuery object
 */
export function migrateVariableQuery(query: string): GenevaVariableQuery {
  const refId = "GenevaDatasource-VariableQuery";
  const queryBase: GenevaVariableQuery = {
    refId: refId,
    type: "account",
  };
  const accountsQuery = query.match(/^Accounts\(.*\)/i);
  if (accountsQuery) {
    return queryBase;
  }

  const stampsQuery = query.match(/^Stamps\(([^;]*)\)/i);
  if (stampsQuery) {
    const account = stampsQuery[1];
    return {
      ...queryBase,
      type: "stamp",
      account,
    };
  }

  const namespacesQuery = query.match(/^Namespaces\(["']?([^\)]+?)["']?\)/i);
  if (namespacesQuery) {
    const [account] = namespacesQuery[1].split(",").slice(-1);
    return {
      ...queryBase,
      type: "namespace",
      account,
    };
  }

  const metricsQuery = query.match(/^Metrics\(["']?([^\)]+?)["']?\)/i);
  if (metricsQuery) {
    const [account, namespace] = metricsQuery[1]
      .split(", ")
      .slice(-2)
      .map((v) => v.trim());
    return {
      ...queryBase,
      type: "metric",
      account,
      namespace,
    };
  }

  const dimensionValueParams = getParamsFromDimensionValuesVariable(query);
  if (dimensionValueParams) {
    const { account, namespace, metric, dimension, deps } = dimensionValueParams;
    const hinting = deps && deps[0] !== "" ? deps : [];

    return {
      ...queryBase,
      type: "dimensionValues",
      account,
      namespace,
      metric,
      dimension,
      hinting,
    };
  }

  const healthResourcesQuery = query.match(/^HealthResources\(["']?([^\)]+?)["']?\)/i);
  if (healthResourcesQuery) {
    // eslint-disable-next-line prefer-const
    const [account] = healthResourcesQuery[1].split(",").slice(-1);
    return {
      ...queryBase,
      type: "healthResource",
      account,
    };
  }

  // Since both env and stamp are optional, we use different method names to distinguish them.
  const namespacesQueryWithStamp = query.match(/^NamespacesWithStamp\(["']?([^\)]+?)["']?\)/i);
  if (namespacesQueryWithStamp) {
    const [account, stamp] = namespacesQueryWithStamp[1]
      .split(",")
      .slice(-2)
      .map((v) => v.trim());
    return {
      ...queryBase,
      type: "namespace",
      account,
      stamps: [stamp],
    };
  }

  const metricsQueryWithStamp = query.match(/^MetricsWithStamp\(["']?([^\)]+?)["']?\)/i);
  if (metricsQueryWithStamp) {
    const [account, namespace, stamp] = metricsQueryWithStamp[1]
      .split(", ")
      .slice(-3)
      .map((v) => v.trim());
    return {
      ...queryBase,
      type: "metric",
      account,
      namespace,
      stamps: [stamp],
    };
  }

  const dimensionValueStampsParams = getParamsFromDimensionValuesVariable(query, true);
  if (dimensionValueStampsParams) {
    const { account, namespace, metric, dimension, stamp, deps } = dimensionValueStampsParams;
    const hinting = deps && deps[0] !== "" ? deps : [];

    return {
      ...queryBase,
      type: "dimensionValues",
      account,
      namespace,
      metric,
      dimension,
      hinting,
      stamps: stamp ? [stamp] : [],
    };
  }

  const healthResourcesQueryWithStamp = query.match(/^HealthResourcesWithStamp\(["']?([^\)]+?)["']?\)/i);
  if (healthResourcesQueryWithStamp) {
    // eslint-disable-next-line prefer-const
    const [account, stamp] = healthResourcesQueryWithStamp[1].split(",").slice(-2);
    return {
      ...queryBase,
      type: "healthResource",
      account,
      stamps: [stamp],
    };
  }

  // if no known matches, default to Account
  return queryBase;
}

// Determine if the given dataframe parameter is a timeseries frame
export function isTimeSeriesDataFrame(frame: DataFrame) {
  // if there is a field with type "time", return true
  return Boolean(frame.fields.find((field) => field.type === FieldType.time));
}

/**
 * predicate for showing a variable in the geneva dropdown
 * 1. if the variable is not a query variable, show it
 * 2. if the variable is a query variable, show it if it is a string
 * 3. if the variable is a query variable, show it if it not using a geneva datasource (eg. some other datasource like ADX can generate account names)
 * 4. if the variable is a query variable, show it if it is a geneva datasource and the query type is the same as the selector type
 * @param variable
 * @param selectorType
 * @returns
 */
export function canShowVariableForGenevaVarSelector(
  variable: TypedVariableModel,
  genevaVariableQueryType: GenevaVariableQueryType
) {
  return (
    variable.type !== "query" ||
    typeof variable.query === "string" ||
    variable.datasource?.type != "geneva-datasource" ||
    variable.query?.type === genevaVariableQueryType
  );
}

/**
 * Converts time series frames to table rows for use with sparkline cell type.
 * Each frame in a response is converted to a row containing values for name, sparkline, mean, min, and max
 *
 * TODO: Data manipulation should be moved to the backend so info is not being passed from the frontend, to the backend, and back again
 * This WI contains the description of this need:
 * https://msazure.visualstudio.com/One/_sprints/taskboard/Azure%20Observability%20Experiences%20and%20Canvases%20-%20Grafana/One/Custom/Observability/Experiences%20and%20Canvases/Germanium/231020?workitem=25537072
 *
 * @param data - Array of data frames to transform
 * @returns Array of transformed data frames
 */
export function sparklineTableTransform(data: DataFrame[]): DataFrame[] {
  // initialize result as an array of dataframes
  const result: DataFrame[] = [];
  // loop through all frames of the dataframe array
  for (const frame of data) {
    // get showSummary boolean for this data frame
    const showSummary = frame.meta?.custom?.["showSummary"];
    // push frame as-is and then skip if it is not a time series frame
    if (!isTimeSeriesDataFrame(frame)) {
      result.push(frame);
      continue;
    }
    // instantiate time field for the sparkline cells
    const timeField = frame.fields.find((x) => x.type === FieldType.time) as Field<Number>;
    const xValuesField = {
      name: "time",
      type: FieldType.time,
      config: {},
      values: timeField.values,
    };
    //initiate new columns for the table
    const titleField: Field<string> = {
      name: "Series Name", // Names of the series
      type: FieldType.string,
      config: {},
      values: [],
    };
    const sparklineField: Field<DataFrame> = {
      name: "Trend", // Sparkline cells
      /*
       * If a cell contains a data frame with time and number fields (x and y values),
       * Grafana will automatically recognize it, setting the cell type to "sparkline"
       */
      type: FieldType.frame,
      config: {},
      values: [],
    };
    const minField: Field<number> = {
      name: "Min", // Minimum values of the series
      type: FieldType.number,
      config: {},
      values: [],
    };
    const maxField: Field<number> = {
      name: "Max", // Maximum values of the series
      type: FieldType.number,
      config: {},
      values: [],
    };
    const meanField: Field<number> = {
      name: "Mean", // Average values of the series
      type: FieldType.number,
      config: {},
      values: [],
    };
    // loop through all fields of the current frame and add values to the table columns
    for (const field of frame.fields) {
      if (field.type === "number") {
        const valuesArray = field.values.toArray(); // necessary for field.values operations to work in 9.5.x, will be removed for Grafana 10.x
        const sparklineFrame = new MutableDataFrame();
        const yValuesField = {
          name: "values",
          type: FieldType.number,
          config: {},
          values: valuesArray,
        };
        // add timeseries to the sparkline dataframe
        sparklineFrame.addField(xValuesField);
        sparklineFrame.addField(yValuesField);
        // add all calculated column values for the current field
        titleField.values.push(field.name);
        sparklineField.values.push(sparklineFrame);
        // only add to summary columns if toggled on
        if (showSummary) {
          meanField.values.push(valuesArray.reduce((a, b) => a + b) / valuesArray.length);
          maxField.values.push(Math.max(...valuesArray));
          minField.values.push(Math.min(...valuesArray));
        }
      }
    }
    // add all fields to a table (dataframe) and push the table to the result
    const table = new MutableDataFrame();
    table.addField(titleField);
    table.addField(sparklineField);
    // only push summary columns to table if toggled on
    if (showSummary) {
      table.addField(meanField);
      table.addField(minField);
      table.addField(maxField);
    }
    result.push(table);
  }
  // return array of dataframes (tables)
  return result;
}

export function getLabelForService(service: GenevaServiceType): string {
  switch (service) {
    case METRICS_SERVICE:
      return METRICS_LABEL;
    case DGREP_SERVICE:
      return DGREP_LABEL;
    case HEALTH_SERVICE:
      return HEALTH_LABEL;
    case TRACES_SERVICE:
      return TRACES_LABEL;
    case JSEXP_SERVICE:
      return JSEXP_LABEL;
    default:
      return "";
  }
}

export function expandArrayValues(values?: string[], scopedVars?: ScopedVars): string[] {
  if (!values) return [];

  return _.uniq(
    values.reduce((acc: string[], value: string) => {
      // Use the provided templateReplace function to replace variables in the event string.
      const replacedValue = getTemplateSrv().replace(value, scopedVars, VariableFormatID.Pipe);
      // Split the possibly expanded value by "|" and add each part to the accumulator.
      const expandedValues = replacedValue.split("|");
      return [...acc, ...expandedValues];
    }, [])
  );
}

export function getServiceConfig(
  availableServices: GenevaServiceConfig[] | undefined,
  serviceName: GenevaServiceType
): GenevaServiceConfig | undefined {
  return availableServices?.find((service) => service.name === serviceName);
}
