import { DataQueryRequest, DataQueryResponse, DataSourceInstanceSettings } from "@grafana/data";
import BackendDataSource from "BackendDataSource";
import { convertTags } from "converters/convertTraceResponse";
import { lastValueFrom } from "rxjs";
import { GenevaJsonData, GenevaQuery } from "types";
import { IDataProvider } from "./IDataProvider";

export class TracesProvider implements IDataProvider {
  backend: BackendDataSource;
  instanceSettings: DataSourceInstanceSettings<GenevaJsonData>;

  constructor(backend: BackendDataSource, instanceSettings: DataSourceInstanceSettings<GenevaJsonData>) {
    this.backend = backend;
    this.instanceSettings = instanceSettings;
  }
  filterQuery(query: GenevaQuery): boolean {
    const filter = query.hide !== true;
    if (query.service === "traces" && query.traceQueryType === "traceID") {
      return filter && !!query.traceId;
    } else {
      const transactionSearchParams = query.transactionSearchParams;
      const endpointFilter = transactionSearchParams?.filters?.find((filter) => filter.fieldName === "logsEnvironment");
      const namespaceFilter = transactionSearchParams?.filters?.find((filter) => filter.fieldName === "namespace");
      const endpoint = endpointFilter && endpointFilter.values ? endpointFilter.values[0] : "";
      const traceNamespaces = namespaceFilter && namespaceFilter.values ? namespaceFilter.values : [];
      return filter && !!endpoint && !!traceNamespaces;
    }
  }
  prepareTarget(target: GenevaQuery, operationId: string, _: DataQueryRequest<GenevaQuery>): void {
    const record: Record<string, string> = {
      operation_parentId: operationId,
    };
    target.telemetryProperties = record;
  }
  query(req: DataQueryRequest<GenevaQuery>): Promise<DataQueryResponse> {
    const refIdToTraceType: Record<string, boolean> = {};
    for (const target of req.targets) {
      refIdToTraceType[target.refId] = target.traceQueryType === "transactionSearch";
    }
    return lastValueFrom(this.backend.query(req)).then((res) => {
      return convertTags(res, refIdToTraceType);
    });
  }
}
