import { ArrayVector, DataQueryResponse, Field, FieldType, TraceKeyValuePair } from "@grafana/data";
import { TraceTagType } from "types";

/**
 * converts service tags and tags from original response
 * this had to be done in the front end because the backend did not allow custom vetor types for the values
 */
export const convertTags = (res: DataQueryResponse, refIdToTraceType: Record<string, boolean>) => {
  let idx = 0;
  for (const data of res.data) {
    const fields: Field[] = data?.fields ?? [];
    if (refIdToTraceType[data.refId]) {
      // incoming query is transaction search query, skip tag conversion
      continue;
    }

    // make service tags
    const serviceTagsField = makeTags(fields, "serviceTags");

    // remove fields that contained the serviceTags appendage - these will now be inside of serviceTags
    const newFields = fields.filter((field) => {
      return !field.name?.startsWith("serviceTags") && !field.name?.startsWith("tags");
    });

    // add service tags field
    // keep order of dataframe
    const indexOfServiceName = newFields
      .map((field) => {
        return field.name;
      })
      .indexOf("serviceName");
    if (indexOfServiceName !== -1) {
      newFields.splice(indexOfServiceName + 1, 0, serviceTagsField);
    } else {
      newFields.push(serviceTagsField);
    }

    // convert tags
    const tagsField = makeTags(fields, "tags");
    newFields.push(tagsField);
    if (res.data && res.data.length > 0) {
      // no user input here
      // eslint-disable-next-line security/detect-object-injection
      res.data[idx].fields = newFields;
    }
    idx++;
  }
  return res;
};

export const cleanName = (name: string, tagType: TraceTagType) => {
  //remove the serviceTags or tags appendage and switch for a user friendly name
  const splitName = name.split(".");
  const noServiceTags = splitName.filter((name) => {
    return name.toLowerCase() !== tagType.toLowerCase();
  });

  return noServiceTags.join(" ").trim();
};

const makeTags = (fields: Field[], tagType: TraceTagType) => {
  const tags = [];
  // leave only the fields that are going to be used to make the service tags
  const tagFields = fields.filter((field) => {
    return field.name?.startsWith(tagType);
  });
  const tagsValues = tagFields[0]?.values.length;

  for (let i = 0; i < tagsValues; i++) {
    const serviceTagValues = [];
    for (const [key, value] of Object.entries(tagFields[0]?.values.get(i))) {
      if (!!key && !!value) {
        const traceValKey = {
          key: cleanName(key, tagType),
          value: value,
        };
        serviceTagValues.push(traceValKey);
      }
    }

    tags.push(serviceTagValues);
  }

  const tagsField: Field = {
    name: tagType,
    values: new ArrayVector<TraceKeyValuePair[]>(tags),
    type: FieldType.other,
    config: tagFields[0]?.config,
  };
  return tagsField;
};
