import { generateQueryText } from "@azure-observability-ecg-grafana/data-migration";
import { Health } from "api/constants";
import _ from "lodash";
import { MetaDataCollection, Monitor, MonitorConfiguration, MonitorTemplateType } from "types";

export const enum MetadataKeys {
  id = "monitor.Id",
  displayName = "monitor.DisplayName",
  computedReport = "computedReport",
  component = "monitor.Component",
  event = "monitor.Event",
  dataSourceType = "monitorDataSourceType",
  dimensions = "monitor.Dimension",
  silentMonitor = "monitor.SilentMonitor",
}

interface MetaDataCollectionV2 extends MetaDataCollection {
  [MetadataKeys.displayName]: string;
}

export const getMonitorId = (metaData: MetaDataCollection): string => {
  const id = <string>metaData[MetadataKeys.id];
  if (!id) {
    throw new Error(`missing property 'id' from monitor`);
  }
  return id;
};

export const getNamespace = (metaData: MetaDataCollection): string => {
  const component = <string>metaData[MetadataKeys.component];
  if (!component) {
    throw new Error(`missing property 'component' from monitor ${getMonitorId(metaData)}`);
  }
  return component;
};

export const getMetric = (metaData: MetaDataCollection): string => {
  const event = <string>metaData[MetadataKeys.event];
  if (!event) {
    throw new Error(`missing property 'event' from monitor ${getMonitorId(metaData)}`);
  }
  return event;
};

export const getDataSourceType = (metaData: MetaDataCollection): string => {
  return <string>metaData[MetadataKeys.dataSourceType] || "Metrics";
};

export const getMonitorDisplayName = (monitor: Monitor) => {
  const name = getMonitorName(monitor);
  return isComputedMonitor(monitor.metadata) ? `(Computed) ${name}` : name;
};

//exported for testing purposes
export const getMonitorName = (monitor: Monitor) => {
  return isV2Monitor(monitor.metadata) ? `${monitor.metadata[MetadataKeys.displayName]} [v2]` : monitor.name;
};

export const getMonitorDimensions = (metaData?: MetaDataCollection, prefix?: boolean) => {
  return _.reduce(
    metaData,
    (
      result: {
        [key: string]: string | number | boolean;
      },
      value,
      key
    ) => {
      if (key.startsWith(MetadataKeys.dimensions)) {
        result[prefix ? key : key.split(".").slice(2).join(".")] = value;
      }
      return result;
    },
    {}
  );
};

export const getFolderDisplayName = (name: string): string => {
  if (name.length < 1) {
    name = "Default Monitors";
  } else {
    name = name[0].toUpperCase() + name.slice(1);
  }

  const haystack = name.trim().toLowerCase(),
    needle = "monitor";

  const nounIndex = haystack.lastIndexOf(needle);
  if (
    nounIndex !== -1 &&
    (nounIndex === haystack.length - needle.length ||
      (nounIndex === haystack.length - needle.length - 1 && haystack[haystack.length - 1] === "s"))
  ) {
    return name;
  } else {
    return name + " Monitors";
  }
};

export const isV2Monitor = (metadata: MetaDataCollection): metadata is MetaDataCollectionV2 => {
  return MetadataKeys.displayName in metadata;
};

export const isSilentMonitor = (metadata: MetaDataCollection): boolean => !!metadata[MetadataKeys.silentMonitor];

//exported for testing purposes
export const isComputedMonitor = (metaData: MetaDataCollection): boolean => {
  return !!metaData[MetadataKeys.computedReport];
};

export const isMonitorQuery = (queryType?: string): boolean =>
  queryType === Health.MonitorEvaluation || queryType === Health.SourceMetric;

export const generateMonitorQuery = (monitorConfig: MonitorConfiguration, metadata: MetaDataCollection): string => {
  let metrics, dimensions;
  switch (monitorConfig.templateType) {
    case MonitorTemplateType.CorrelatedFailingBucket:
      metrics = _.get(monitorConfig, [
        "templateConfiguration",
        "correlatedFailingBucketTemplate",
        "expressions",
        0,
        "percentageTemplate",
        "numerator",
        0,
        "fetchMetrics",
      ]);

      dimensions = getMonitorDimensions(metadata);
      return generateQueryText(
        getMetric(metadata),
        "",
        "avg",
        "desc",
        metrics?.samplingType,
        "",
        "",
        "",
        _.keys(dimensions),
        _.values(dimensions).map((dim) => [String(dim)])
      );

      break;
    case MonitorTemplateType.CorrelatedEvents:
      metrics = _.get(monitorConfig, [
        "templateConfiguration",
        "correlatedEventsTemplate",
        "events",
        0,
        "template",
        "percentageTemplate",
        "numerator",
        0,
        "fetchMetrics",
      ]);

      dimensions = getMonitorDimensions(metadata);
      return generateQueryText(
        getMetric(metadata),
        "",
        "avg",
        "desc",
        metrics?.samplingType,
        "",
        "",
        "",
        _.keys(dimensions),
        _.values(dimensions).map((dim) => [String(dim)])
      );

      break;

    default:
      return "";
  }
};
