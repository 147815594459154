import { DataFrameView, SelectableValue } from "@grafana/data";
import { TermCount } from "pages/SearchComponents/Tags/GalleryTagFilter";

//constants
export const SEARCH_SELECTED_LAYOUT = "grafana.search.layout";
export const SEARCH_SELECTED_SORT = "grafana.search.sort";

// fields needed by a SearchQuery
interface SearchQueryParams {
  query?: string | null;
  sort?: string | null;
  starred?: boolean | null;
  tag?: string[] | null;
  currTab?: SearchTab | null;
  folder?: string | null;
}

// have default query fields if not provided
export const defaultQueryParams: SearchQueryParams = {
  sort: null,
  starred: null,
  query: null,
  tag: null,
  currTab: null,
};

interface FacetField {
  field: string;
  count?: number;
}

// the actual query fields that are sent from a search
export interface SearchQuery {
  query?: string;
  location?: string;
  sort?: string;
  ds_uid?: string;
  ds_type?: string;
  saved_query_uid?: string; // TODO: not implemented yet
  tags?: string[];
  kind?: string[];
  panel_type?: string;
  uid?: string[];
  facet?: FacetField[];
  explain?: boolean;
  withAllowedActions?: boolean;
  accessInfo?: boolean;
  hasPreview?: string; // theme
  limit?: number;
  from?: number;
  starred?: boolean;
}

// keeps track of the current state that the page's search is in
export interface SearchState {
  query: string;
  tag: string[];
  starred: boolean;
  explain?: boolean; // adds debug info
  datasource?: string;
  panel_type?: string;
  sort?: string;
  prevSort?: string; // Save sorting data between layouts
  result?: QueryResponse;
  loading?: boolean;
  folderUid?: string;
  currTab?: string;
}

// enumeration of tab types that can be switched between
export enum SearchTab {
  Shared = "shared",
  SharedByYou = "shared by you",
}

// default state of the search page at the start
export const initialState: SearchState = {
  query: "",
  tag: [],
  starred: false,
  currTab: SearchTab.Shared,
  sort: undefined,
  prevSort: undefined,
};

interface DashboardQueryResult {
  kind: string; // panel, dashboard, folder
  name: string;
  uid: string;
  url: string; // link to value (unique)
  panel_type: string;
  tags: string[];
  location: string; // url that can be split
  ds_uid: string[];

  // debugging fields
  score: number;
  explain: {};

  // enterprise sends extra properties through for sorting (views, errors, etc)
  [key: string]: unknown;
}

// response received after sending a SearchQuery
interface QueryResponse {
  view: DataFrameView<DashboardQueryResult>;

  /** Supports lazy loading.  This will mutate the `view` object above, adding rows as needed */
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<void>;

  /** Checks if a row in the view needs to be added */
  isItemLoaded: (index: number) => boolean;

  /** the total query results size */
  totalRows: number;
}

// Does the searching using a SearchQuery
export interface GrafanaSearcher {
  search: (query: SearchQuery) => Promise<QueryResponse>;
  starred: (query: SearchQuery) => Promise<QueryResponse>;
  tags: (query: SearchQuery) => Promise<TermCount[]>;
  getSortOptions: () => Promise<SelectableValue[]>;
  sortPlaceholder?: string;

  /** Gets the default sort used for the Folder view */
  getFolderViewSort: () => string;
}
