import React from "react";

export const HealthIcon = () => {
  return (
    <div>
      <svg width="50" height="50" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#health-clip)">
          <path
            d="M12 21.0007C21.6471 14.4045 22 10.6032 22 9.37341C22 7.69639 21.7647 3.33615 17.0588 3.00074C14.8235 2.88894 12.7059 4.11875 12 6.24298C11.2941 4.11875 9.17647 2.88894 6.82353 3.00074C2.23529 3.33615 2 7.69639 2 9.37341C2 10.6032 2.35294 14.4045 12 21.0007Z"
            fill="url(#health-linear-gradient)"
          />
          <path
            d="M22 8.70186H17.2941C17.1765 8.70186 17.1765 8.70186 17.1765 8.81366L15.7647 11.1615C15.7647 11.2733 15.6471 11.2733 15.5294 11.1615L13.4118 7.47205C13.2941 7.36025 13.0588 7.24845 12.8235 7.36025L12.7059 7.47205L10.7059 13.0621C10.7059 13.0621 10.5882 13.1739 10.4706 13.0621C10.4706 13.0621 10.3529 13.0621 10.3529 12.9503L8.70588 9.37267C8.58823 9.14907 8.47059 9.14907 8.23529 9.14907C8.11765 9.14907 8.11765 9.26087 8.11765 9.26087L5.76471 13.2857L5.64706 13.3975H3.52941C3.88235 13.8447 4.23529 14.2919 4.58824 14.7391H6.58824C6.70588 14.7391 6.70588 14.7391 6.70588 14.6273L8.11765 12.1677C8.11765 12.0559 8.23529 12.0559 8.35294 12.0559C8.35294 12.0559 8.35294 12.0559 8.47059 12.1677L10.3529 16.4161C10.4706 16.6398 10.5882 16.6398 10.8235 16.6398C10.9412 16.6398 10.9412 16.5279 11.0588 16.4161L13.0588 10.6025C13.0588 10.4907 13.1765 10.4907 13.2941 10.4907C13.2941 10.4907 13.4118 10.4907 13.4118 10.6025L15.1765 13.7329C15.2941 13.8447 15.5294 13.9565 15.6471 13.8447C15.7647 13.8447 15.7647 13.7329 15.7647 13.7329L18 10.2671L18.1176 10.1553H21.8824"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="health-linear-gradient"
            x1="12.0007"
            y1="20.9834"
            x2="12.0007"
            y2="3.00074"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1988D9" />
            <stop offset="0.22" stopColor="#218DDC" />
            <stop offset="0.56" stopColor="#379CE5" />
            <stop offset="0.9" stopColor="#54AEF0" />
          </linearGradient>
          <clipPath id="health-clip">
            <rect x="2" y="3" width="20" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
