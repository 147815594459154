/* eslint-disable import/no-unused-modules */
import React from "react";

import { SelectableValue } from "@grafana/data";
import { Icon, Select } from "@grafana/ui";

export interface Props {
  onChange: (sortValue: SelectableValue) => void;
  value?: string;
  placeholder?: string;
  isClearable?: boolean;
}

export function GallerySortPicker({ onChange, value, placeholder, isClearable }: Props) {
  const options = [
    { value: "name_sort", label: "Alphabetically (A-Z)" },
    { value: "-name_sort", label: "Alphabetically (Z-A)" },
    { value: "created_at", label: "by Shared (Most Recent)" },
    { value: "-created_at", label: "by Shared (Oldest)" },
    { value: "updated_at", label: "by Updated (Most Recent)" },
    { value: "-updated_at", label: "by Updated (Oldest)" },
  ];

  const isDesc = Boolean(value?.includes("desc") || value?.startsWith("-")); // bluge syntax starts with "-"
  return (
    <Select
      key={value}
      width={28}
      onChange={onChange}
      value={options.find((opt) => opt.value === value) ?? null}
      options={options}
      aria-label="Sort"
      placeholder={placeholder ?? "Sort"}
      prefix={<Icon name={isDesc ? "sort-amount-down" : "sort-amount-up"} />}
      isClearable={isClearable}
    />
  );
}
