import { DataQueryRequest, DataQueryResponse, DataSourceInstanceSettings } from "@grafana/data";
import { trackEvent } from "appInsights";
import BackendDataSource from "BackendDataSource";
import { lastValueFrom } from "rxjs";
import { DgrepQuery, FilterQueryType, GenevaJsonData, GenevaQuery } from "types";
import { v4 } from "uuid";
import { IDataProvider } from "./IDataProvider";

interface DGrepCacheModel extends DgrepQuery {
  referenceTime: Date;
  offsetSign: string;
  offsetUnit: string;
  offset: number;
}

export class DgrepProvider implements IDataProvider {
  backend: BackendDataSource;
  instanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  queryCache: Map<string, string> = new Map();
  constructor(backend: BackendDataSource, instanceSettings: DataSourceInstanceSettings<GenevaJsonData>) {
    this.instanceSettings = instanceSettings;
    this.backend = backend;
  }
  filterQuery(query: GenevaQuery): boolean {
    return (
      query.service === "dgrep" &&
      !!query.env &&
      !!query.namespaces &&
      query.namespaces.length > 0 &&
      !!query.events &&
      query.events.length > 0
    );
  }
  prepareTarget(target: GenevaQuery, __: string, ___: DataQueryRequest<GenevaQuery>): void {
    target.queryType = "dgrep";
    // use either the filterQuery or the filters, but not both
    if (target.filterQueryType === FilterQueryType.KQL) {
      target.filters = undefined;
      // Use "source" as default value for filterQuery when filterQueryType is KQL
      target.filterQuery = (target.filterQuery?.trim() || "source").replace(/(\r\n|\n|\r)/gm, " ");
    } else {
      target.filterQuery = "";
    }

    target.kqlQuery = (target.kqlQuery?.trim() || "").replace(/(\r\n|\n|\r)/gm, " ");
  }
  async query(req: DataQueryRequest<GenevaQuery>): Promise<DataQueryResponse> {
    req.targets = req.targets.filter((target) => target.service === "dgrep");
    for (const target of req.targets) {
      const dQuery = target as GenevaQuery as DgrepQuery;
      const startTime = new Date(req.range.from.valueOf());
      const endTime = new Date(req.range.to.valueOf());
      const cacheItem: DGrepCacheModel = {
        ...dQuery,
        referenceTime: startTime,
        offsetSign: "-",
        offsetUnit: "Minutes",
        offset: (endTime.getTime() - startTime.getTime()) / (1000 * 60),
      };
      if (this.queryCache.has(JSON.stringify(cacheItem))) {
        dQuery.queryId = this.queryCache.get(JSON.stringify(cacheItem));
        dQuery.isCached = true;
        trackEvent({ name: "DGrepQueryCacheHit" }, { query: JSON.stringify(cacheItem), id: dQuery.queryId });
      } else {
        const queryId = v4();
        this.queryCache.set(JSON.stringify(cacheItem), queryId);
        dQuery.queryId = queryId;
        dQuery.isCached = false;
        trackEvent({ name: "DGrepQueryNoCacheHit" }, { query: JSON.stringify(cacheItem), id: dQuery.queryId });
      }
    }
    return lastValueFrom(this.backend.query(req));
  }
}
