import { css } from "@emotion/css";
import React, { useCallback } from "react";

import { GrafanaTheme2 } from "@grafana/data";
import { Icon, useStyles2 } from "@grafana/ui";

import { trackEvent } from "appInsights";
import { GrafanaIcon } from "pages/Icons/GrafanaIcon";
import { HealthIcon } from "pages/Icons/HealthIcon";
import { IcMIcon } from "pages/Icons/IcMIcon";
import { InsightsIcon } from "pages/Icons/InsightsIcon";
import { TracesIcon } from "pages/Icons/TracesIcon";
import { MetricsIcon } from "../pages/Icons/MetricsIcon";
import { TileContent } from "../types";

interface TileProps {
  tileContent: TileContent;
}

const cardStyle = (theme: GrafanaTheme2) => {
  const borderGradient = "linear-gradient(to right, #5182cc 0%, #245baf 100%)";

  return `
      background-color: ${theme.colors.background.secondary};
      margin-right: ${theme.spacing(4)};
      margin-bottom: ${theme.spacing(4)};
      border: 1px solid ${theme.colors.border.weak};
      border-bottom-left-radius: ${theme.shape.borderRadius(2)};
      border-bottom-right-radius: ${theme.shape.borderRadius(2)};
      position: relative;
      height: 400px;
      width: 400px;
      flex: 1;


      &::before {
        display: block;
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        top: 0;
        background-image: ${borderGradient};
      }
`;
};

const cardContent = css`
  padding: 16px;
`;
export const Tile = ({ tileContent }: TileProps) => {
  const styles = useStyles2(useCallback((theme: GrafanaTheme2) => getStyles(theme), []));

  let icon: JSX.Element | undefined = undefined;
  switch (tileContent.icon) {
    case "GrafanaIcon":
      icon = <GrafanaIcon />;
      break;
    case "MetricsIcon":
      icon = <MetricsIcon />;
      break;
    case "TracesIcon":
      icon = <TracesIcon />;
      break;
    case "HealthIcon":
      icon = <HealthIcon />;
      break;
    case "InsightsIcon":
      icon = <InsightsIcon />;
      break;
    case "IcMIcon":
      icon = <IcMIcon />;
      break;
    default:
      break;
  }

  return (
    <div className={styles.card}>
      <div className={cardContent}>
        <div>
          <div>{icon}</div>
          <div className={styles.heading}>{tileContent.heading}</div>
        </div>
        <div>{tileContent.description}</div>
        <div>
          <br />
          <br />
          Documentation:{" "}
          <a
            href={tileContent.learnHref}
            target="_blank"
            rel="noreferrer"
            className={styles.learnHref}
            onClick={() => {
              trackEvent(
                { name: "Tile Click" },
                { tile: tileContent.heading, link: tileContent.learnHref, text: tileContent.learnHrefText }
              );
            }}
          >
            {tileContent.learnHrefText} <Icon name="external-link-alt" />
          </a>
        </div>
      </div>
      <div className={styles.nextSteps}>
        {tileContent.nextSteps.map((nextStep) => (
          <a
            className={styles.url}
            href={nextStep.link}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackEvent(
                { name: "Tile Click" },
                { tile: tileContent.heading, link: nextStep.link, text: nextStep.text }
              );
            }}
          >
            {nextStep.text}
          </a>
        ))}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    card: css`
      ${cardStyle(theme)}
    `,
    heading: css`
      text-transform: uppercase;
      color: #50a1eb;
      margin-bottom: ${theme.spacing(2)};
    `,
    title: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    url: css`
      display: inline-block;
      padding-right: 10px;
      color: #50a1eb;
    `,
    nextSteps: css`
      border-top: 1px solid ${theme.colors.border.weak};
      position: absolute;
      bottom: 0;
      padding: 8px 16px;
      width: 100%;
    `,
    learnHref: css`
      color: #62b4fb;
    `,
  };
};
