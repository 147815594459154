// despite applied changes, warning was still being thrown
/* eslint-disable security/detect-non-literal-regexp */
import { escapeRegExp } from "lodash";

const incidentLinkRegexes = [
  "https://prod.microsofticm.com/imp/IncidentDetails.aspx?id=",
  "https://ppe.microsofticm.com/imp/IncidentDetails.aspx?id=",
  "https://icm.ad.msoppe.msft.net/imp/IncidentDetails.aspx?id=",
  "https://icm.ad.msft.net/imp/IncidentDetails.aspx?id=",
  "https://portal.microsofticm.com/imp/IncidentDetails.aspx?id=",
  "https://ppeportal.microsofticm.com/imp/IncidentDetails.aspx?id=",
  "https://portal.microsofticm.com/imp/v3/incidents/details/",
  "https://ppeportal.microsofticm.com/imp/v3/incidents/details/",
].map((link) => {
  const escaped = escapeRegExp(link);
  return new RegExp(`(${escaped})(\\d+)`, "gi");
});

/**
 * This puts incident links in html style a tags so that Grafana will render
 * them as links.
 *
 * It also updates links from the old format to a new format client-side
 * as is done here: https://msazure.visualstudio.com/One/_git/EngSys-Amalgam/pullrequest/4802746
 * This replacement will be made unnecessary once this health bug is
 * resolved and these links are passed down correctly:
 * https://msazure.visualstudio.com/One/_workitems/edit/10362381
 */
export function transformAnnotationLinks(content: string) {
  for (const regex of incidentLinkRegexes) {
    for (const [link, prefix, incidentId] of content.matchAll(regex)) {
      const modifiedPrefix = prefix
        .replace(/prod.microsofticm.com/gi, "portal.microsofticm.com")
        .replace(/ppe.microsofticm.com/gi, "ppeportal.microsofticm.com");

      if (modifiedPrefix === prefix) {
        continue;
      }
      const escaped = escapeRegExp(link);
      content = content.replace(new RegExp(`${escaped}`, "gi"), modifiedPrefix + incidentId);
    }
  }

  content = content.replace(
    // Regex pulled from here: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi,
    '<a href="$1" target="_blank">$1</a>'
  );

  return content;
}
