import { DataQueryRequest, DataQueryResponse, DataSourceInstanceSettings } from "@grafana/data";
import { GenevaJsonData, GenevaQuery } from "types";
import { IDataProvider } from "./IDataProvider";
import { makeHealthQuery } from "api/health/makeHealthQuery";

export class HealthProvider implements IDataProvider {
  instanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  constructor(instanceSettings: DataSourceInstanceSettings<GenevaJsonData>) {
    this.instanceSettings = instanceSettings;
  }
  filterQuery(query: GenevaQuery): boolean {
    return query.service === "health" && !!query.account && !!query.namespace && !query.hide;
  }
  prepareTarget(_: GenevaQuery, __: string, ___: DataQueryRequest<GenevaQuery>): void {}
  async query(req: DataQueryRequest<GenevaQuery>): Promise<DataQueryResponse> {
    const healthData = await makeHealthQuery(this.instanceSettings, req.targets, req.range, req.scopedVars);
    return { data: [...healthData.data] };
  }
}
