import { trackPageView } from "appInsights";
import * as React from "react";
import { generateExploreQuery } from "util/dataUtil";

export class MetricsExplorePage extends React.PureComponent {
  override render() {
    trackPageView({ name: "MetricsExplorePage", properties: { path: window.location.pathname } });
    window.location.replace(`explore?orgId=1&left=${generateExploreQuery("metrics")}`);
    return null;
  }
}
