import React from "react";

export const IcMIcon = () => {
  return (
    <div>
      <svg width="50" height="50" viewBox="0 0 101 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="34" y="23" fill="#0078D4" width="7" height="34" />
        <polyline
          fill="#0078D4"
          points="96,36.75 96,57 101,57 101,23 96,23 85,50.25 74,23 69,23 69,57 74,57 74,36.75 82.75,57 87.25,57 96,36.75 "
        />
        <polyline
          fill="#0078D4"
          points="96,36.75 96,57 101,57 101,23 96,23 85,50.25 74,23 69,23 69,57 74,57 74,36.75 82.75,57 87.25,57 96,36.75 "
        />
        <path
          fill="#0078D4"
          d="M58.5,52c-4.142,0-7.5-3.358-7.5-7.5s3.358-7.5,7.5-7.5c2.176,0,4.13,0.933,5.5,2.413v-6.569c-1.67-0.79-3.53-1.244-5.5-1.244c-7.125,0-12.9,5.776-12.9,12.9s5.775,12.9,12.9,12.9c1.97,0,3.83-0.455,5.5-1.244v-6.569C62.63,51.067,60.676,52,58.5,52z"
        />
        <path
          fill="#0078D4"
          d="M58.5,52c-4.142,0-7.5-3.358-7.5-7.5s3.358-7.5,7.5-7.5c2.176,0,4.13,0.933,5.5,2.413v-6.569c-1.67-0.79-3.53-1.244-5.5-1.244c-7.125,0-12.9,5.776-12.9,12.9s5.775,12.9,12.9,12.9c1.97,0,3.83-0.455,5.5-1.244v-6.569C62.63,51.067,60.676,52,58.5,52z"
        />
        <g>
          <path
            fill="#0078D4"
            d="M64.025,60l2.925,4.746L66.804,65H8.32l-0.195-0.34l29.39-57.249l11.863,24.086c1.904-1.34,4.113-2.264,6.502-2.663L41.576,0h-8.123L0.154,64.856L4.279,72h66.566l4.076-7.059L71.896,60H64.025z"
          />
        </g>
      </svg>
    </div>
  );
};
