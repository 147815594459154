// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="safeEval.d.ts" />
const workerPath = "public/plugins/geneva-app/services/safeEvalWorker.js";

const expressionEvaluationTimeout = 3000;

type SafeEvaluationResponseData = "ready" | unknown[];

interface SafeEvaluationResponseMessage extends MessageEvent {
  data: SafeEvaluationResponseData;
}

/**
 * A collection of expressions to be evaluated together
 */
interface Batch<T> {
  /**
   * A list of expressions to evaluate
   */
  evaluations: SafeEvaluation[];

  /**
   * The promise that will be resolved when evaluation of the batch is complete
   */
  response: Promise<T[]> | null;
}

/**
 * How often (in milliseconds) to execute batches of requests
 */
const dispatchDelay = 50;

/**
 * The shared web worker used to evaluate expressions
 */
let sharedWebWorker: Worker;

/**
 * A collection of batches of requests which have been filled; the first is
 * currently evaluating and any subsequent ones are waiting for the web worker
 * to become available
 */
const waitingBatches: Batch<unknown>[] = [];

/**
 * The current batch being filled with received requests for evaluation
 */
let currentBatch: Batch<unknown> | null = null;

/**
 * Evaluate a JS expression in a web worker sandbox with a limited set of allow-listed objects exposed
 * (see `allowedProperties` in safeEvalWorker.ts)
 *
 * @param evaluations An expression described with a `SafeEvaluation` object to execute
 * @returns The result of the expression evaluation
 **/
export function safeEvaluateExpression<T>(expression: string, context: Object): Promise<T> {
  if (!currentBatch) {
    currentBatch = {
      evaluations: [],
      response: null,
    };
  }

  const index = currentBatch.evaluations.length;
  currentBatch.evaluations.push({
    expression: expression,
    context: context,
  });

  if (!currentBatch.response) {
    currentBatch.response = new Promise<T[]>((resolve, reject) => {
      setTimeout(() => {
        if (currentBatch) {
          const batchToEvaluate = currentBatch;
          // swap this batch out of currentBatch so a new batch can start
          // accepting new requests while this one executes
          waitingBatches.push(currentBatch);
          currentBatch = null;
          // wait for the previous batch in the queue to execute. it doesn't matter if the
          // previous batch succeeded or failed, just that the worker is now available
          const previousBatch = waitingBatches.length > 1 && waitingBatches[waitingBatches.length - 2];
          const previousBatchCompleted = previousBatch && previousBatch.response?.catch(() => null);
          (previousBatchCompleted || Promise.resolve(null)).then(() => {
            evaluateBatch<T>(batchToEvaluate.evaluations).then(
              (results) => {
                waitingBatches.splice(waitingBatches.indexOf(batchToEvaluate), 1);
                resolve(results);
              },
              (err) => reject(err)
            );
          });
        }
      }, dispatchDelay);
    });
  }

  return currentBatch.response.then((results) => {
    return results[`${index}`] as unknown as T;
  });
}

/**
 * Evaluate a set of expressions and contexts in the isolation of a web worker
 * @param evaluations
 */
function evaluateBatch<T>(evaluations: SafeEvaluation[]): Promise<T[]> {
  return getActiveWorker().then((worker) => {
    let timeout: number;
    return new Promise<T[]>((resolve, reject) => {
      timeout = setTimeout(() => {
        recycleWorker();
        reject({
          type: "Timeout",
          message: `Expression evaluation timed out after ${expressionEvaluationTimeout} ms`,
        });
      }, expressionEvaluationTimeout) as unknown as number;

      worker.onmessage = (message: SafeEvaluationResponseMessage) => {
        if (message.data !== "ready") {
          clearTimeout(timeout);
          resolve(message.data as unknown as T[]);
        }
      };

      worker.onerror = (err) => {
        clearTimeout(timeout);
        reject(err);
      };

      worker.postMessage(evaluations);
    });
  });
}

/**
 * Returns a web worker ready to accept requests, reusing the
 * existing one if possible
 */
function getActiveWorker(): Promise<Worker> {
  return new Promise<Worker>((resolve, _) => {
    if (sharedWebWorker) {
      resolve(sharedWebWorker);
      return;
    }

    const newWorker = new Worker(new URL(window.location.origin + "/" + workerPath));
    newWorker.onmessage = (message: SafeEvaluationResponseMessage) => {
      if (message.data === "ready") {
        sharedWebWorker = newWorker;
        resolve(sharedWebWorker);
      }
    };
  });
}

/**
 * Kill the current web worker and start up a new one
 */
function recycleWorker(): void {
  if (sharedWebWorker) {
    sharedWebWorker.terminate();
    getActiveWorker();
  }
}
