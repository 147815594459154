import { ArrayVector, DataFrame, DataQueryResponse, Field, FieldType } from "@grafana/data";
import { GenevaQuery } from "types";
import { safeEvaluateExpression } from "../../services/safeEval";

export const isFrameInExpression = (refId: string | undefined, jsExpression: string | undefined) => {
  if (!jsExpression) {
    return false;
  }
  // eslint-disable-next-line security/detect-non-literal-regexp
  const layerRegexp = new RegExp(`layer\\.${refId}(?![0-9a-zA-Z])`, "ig");
  return layerRegexp.test(jsExpression);
};

export const evaluateExpression = async (query: GenevaQuery, response: DataQueryResponse) => {
  const data: DataFrame[] = response.data;
  const jsExpression = query.jsExpression?.trim();
  if (!jsExpression) {
    return;
  }
  const expressionsReturnData: DataFrame[] = [];
  const expressionFrames = data.filter((frame) => {
    // need refid as part of regex
    return isFrameInExpression(frame.refId, jsExpression);
  });
  let combinations = matchingLabels(expressionFrames);
  let hasMatchingLabels = true;
  if (!combinations || !combinations.length) {
    hasMatchingLabels = false;
    combinations = multiDimensionalCrossProduct(
      expressionFrames.map((frame) => frame.fields && frame.fields.filter((field) => field.type === FieldType.number))
    );
  }
  combinations.sort((a, b) => {
    return a[0].name.localeCompare(b[0].name);
  });
  for (const combination of combinations) {
    let customSeriesName = null;
    for (const comb of combination) {
      if (comb.config.custom?.customSeriesName) {
        customSeriesName = comb.config.custom.customSeriesName;
      }
    }
    let colName = customSeriesName || jsExpression;
    for (const combo of combination) {
      // need refid as part of regex
      // eslint-disable-next-line security/detect-non-literal-regexp
      const layerRegexp = new RegExp(`layer\\.${getFieldRefId(combo, expressionFrames)}(?![0-9a-zA-Z])`, "ig");
      colName = colName.replace(layerRegexp, combo.name);
      for (const key in combo.labels) {
        if (combo.labels.hasOwnProperty(key)) {
          continue;
        }
        // need regex to be dynamic here
        // eslint-disable-next-line security/detect-non-literal-regexp
        const dimensionRegexp = new RegExp(`{${key}}`, "ig");
        colName = colName.replace(dimensionRegexp, combo.labels[`${key}`]);
      }
    }

    if (colName.search("layer\\.") !== -1) {
      throw {
        message:
          "This expression cannot be evaluated as at least one referenced layer was missing from the returned results.",
      };
    }
    const timeField = getLongestTimeField(expressionFrames);
    if (!timeField) {
      throw {
        message: "This expression cannot be evaluated as no time field was returned from the query.",
      };
    }
    const expressionFields: Field[] = [{ name: "time", type: FieldType.time, values: timeField.values, config: {} }];
    expressionFields.push({
      name: hasMatchingLabels && !customSeriesName ? combination[0].name : colName,
      type: FieldType.number,
      values: new ArrayVector(),
      config: {},
    });

    const evalPromises: Promise<number>[] = [];
    for (let index = 0; index < timeField.values.length; index++) {
      const layer: { [key: string]: unknown } = {};
      for (const combo of combination) {
        layer[getFieldRefId(combo, expressionFrames) || ""] = combo.values.toArray()[`${index}`];
      }
      evalPromises.push(
        safeEvaluateExpression<number>(jsExpression, {
          layer: layer,
        })
      );
    }
    const allValues = await Promise.all(evalPromises);
    const transformNaNs = allValues.map((val) => {
      return isNaN(val) ? null : val;
    });
    expressionFields[1].values.toArray().push(...transformNaNs);
    expressionsReturnData.push({
      refId: query.refId,
      fields: expressionFields,
      length: expressionFields[0].values.length,
    });
  }
  return expressionsReturnData;
};

//The following functions are exported for testing purposes
export const getLongestTimeField = (frames: DataFrame[]): Field | undefined => {
  let longestField: Field | undefined;
  for (const frame of frames) {
    for (const field of frame.fields) {
      if (field.type === FieldType.time && (!longestField || field.values.length > longestField.values.length)) {
        longestField = field;
      }
    }
  }
  return longestField;
};

export const getFieldRefId = (field: Field, expressionFrames: DataFrame[]): string | undefined => {
  for (const frame of expressionFrames) {
    for (const f of frame.fields) {
      if (f === field) {
        return frame.refId;
      }
    }
  }
  return undefined;
};

export const matchingLabels = (frames: DataFrame[]) => {
  // get all dimensions and set of values across all frames
  const allDimensions: Map<string, Set<string>> = new Map();
  for (const frame of frames) {
    for (const field of frame.fields) {
      if (field.type !== FieldType.number) {
        continue;
      }
      if (field.labels) {
        for (const key in field.labels) {
          if (Object.prototype.hasOwnProperty.call(field.labels, key)) {
            if (!allDimensions.has(key)) {
              allDimensions.set(key, new Set<string>());
            } else {
              allDimensions.get(key)?.add(field.labels[`${key}`]);
            }
          }
        }
      }
    }
  }

  // get all dimensions that have more than one value
  const nonSingleValueDimensions: Set<string> = new Set();
  for (const [key, value] of allDimensions.entries()) {
    if (value.size > 1) {
      nonSingleValueDimensions.add(key);
    }
  }

  // match the series that have the same values for the non-single value dimensions
  const returnValMap: Map<string, Field[]> = new Map();
  for (const frame of frames) {
    const dimFields = frame.fields.filter((field) => {
      if (field.type === FieldType.number && field.labels) {
        for (const dim of nonSingleValueDimensions) {
          if (!field.labels[`${dim}`]) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    });
    for (const field of dimFields) {
      const dimValues: string[] = [];
      for (const dim of nonSingleValueDimensions) {
        const val = field.labels?.[`${dim}`];
        if (val) {
          dimValues.push(val);
        }
      }
      const combinedValues = dimValues.join(" ").toLocaleLowerCase();
      if (returnValMap.has(combinedValues)) {
        returnValMap.get(combinedValues)?.push(field);
      } else {
        returnValMap.set(combinedValues, [field]);
      }
    }
  }

  // return only the series that have the same number of fields as the number of frames
  const arrayVals = Array.from(returnValMap.values());
  if (arrayVals.some((fields) => fields.length !== frames.length)) {
    return [];
  } else {
    return arrayVals;
  }
};

export const multiDimensionalCrossProduct = <T>(arg: T[][], limit?: number): T[][] => {
  const r: T[][] = [],
    max = arg.length - 1;
  limit = limit || Infinity;

  if (!arg.length) {
    return [];
  }

  function helper(arr: T[], i: number): void {
    for (let j = 0, l = arg[`${i}`].length; j < l && r.length < (limit || 100); j++) {
      const a = arr.slice(0); // clone arr
      a.push(arg[`${i}`][`${j}`]);
      if (i === max) {
        r.push(a);
      } else {
        helper(a, i + 1);
      }
    }
  }

  helper([], 0);
  return r;
};
