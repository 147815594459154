import { css } from "@emotion/css";
import React from "react";

import { AppEvents, UrlQueryMap } from "@grafana/data";
import { selectors } from "@grafana/e2e-selectors";
import { config, getAppEvents, reportInteraction } from "@grafana/runtime";
import {
  Field,
  FileDropzone,
  FileDropzoneDefaultChildren,
  Form,
  HorizontalGroup,
  LinkButton,
  TextArea,
} from "@grafana/ui";
import { workbooksDashboard } from "galleryDashboards/galleryDashboards";
import { RouteComponentProps } from "react-router-dom";
import { GalleryTag } from "types/GalleryTypes";
import { sharedList, yourList } from "./BrowseGalleryPage";

// type DashboardImportPageRouteSearchParams = {
//   gcomDashboardId?: string;
// };

//GrafanaRouteComponentProps
interface GrafanaRouteComponentProps<T extends {} = {}, Q = UrlQueryMap> extends RouteComponentProps<T> {
  route: RouteDescriptor;
  queryParams: Q;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GrafanaRouteComponent<T extends {} = any> = React.ComponentType<GrafanaRouteComponentProps<T>>;
interface RouteDescriptor {
  path: string;
  component: GrafanaRouteComponent;
  roles?: () => string[];
  pageClass?: string;
  /** Can be used like an id for the route if the same component is used by many routes */
  routeName?: string;
  chromeless?: boolean;
  exact?: boolean;
  sensitive?: boolean;
}

const IMPORT_STARTED_EVENT_NAME = "dashboard_import_loaded";
const JSON_PLACEHOLDER = `Paste JSON here
`;

const shareDashboardItem = {
  title: "Workbooks Team Dashboard",
  description: "Dashboard for Workbooks Team",
  tags: [GalleryTag.am, GalleryTag.geneva],
  logo: undefined,
  json: workbooksDashboard,
};

const appEvents = getAppEvents();

//type OwnProps = Themeable2 & GrafanaRouteComponentProps<{}, DashboardImportPageRouteSearchParams>;
// type Props = OwnProps;

const validateDashboardJson = (json: string) => {
  let dashboard;
  try {
    dashboard = JSON.parse(json);
  } catch (error) {
    return "Not valid JSON";
  }
  if (dashboard && dashboard.hasOwnProperty("tags")) {
    if (Array.isArray(dashboard.tags)) {
      const hasInvalidTag = dashboard.tags.some((tag: string) => typeof tag !== "string");
      if (hasInvalidTag) {
        return "tags expected array of strings";
      }
    } else {
      return "tags expected array";
    }
  }
  return true;
};

const UploadDashboardPage = () => {
  // const constructor = (props: Props) => {
  //   super(props);
  // };

  const fileListRenderer = () => null;

  const onFileUpload = () => {
    reportInteraction(IMPORT_STARTED_EVENT_NAME, {
      import_source: "json_uploaded",
    });
  };

  const getDashboardFromJson = () => {
    reportInteraction(IMPORT_STARTED_EVENT_NAME, {
      import_source: "json_pasted",
    });
  };

  const renderImportForm = () => {
    //const styles = importStyles(props.theme);

    return (
      <>
        <div
          className={css`
            margin-bottom: "10px";
            max-width: 600px;
          `}
        >
          <FileDropzone
            options={{ multiple: false, accept: [".json", ".txt"] }}
            readAs="readAsText"
            fileListRenderer={fileListRenderer}
            onLoad={onFileUpload}
          >
            <FileDropzoneDefaultChildren
              primaryText={"Upload dashboard JSON file"}
              secondaryText={"Drag and drop here or click to browse"}
            />
          </FileDropzone>
        </div>
        <div
          className={css`
            margin-bottom: "10px";
            max-width: 600px;
          `}
        >
          <Form onSubmit={getDashboardFromJson} defaultValues={{ dashboardJson: "" }}>
            {({ register, errors }) => (
              <>
                <Field
                  label={"Import via dashboard JSON model"}
                  invalid={!!errors.dashboardJson}
                  error={errors.dashboardJson && errors.dashboardJson.message}
                >
                  <TextArea
                    {...register("dashboardJson", {
                      required: "Need a dashboard JSON model",
                      validate: validateDashboardJson,
                    })}
                    data-testid={selectors.components.DashboardImportPage.textarea}
                    id="dashboard-json-textarea"
                    rows={10}
                    placeholder={JSON_PLACEHOLDER}
                  />
                </Field>
                <HorizontalGroup>
                  <LinkButton
                    type="submit"
                    data-testid={selectors.components.DashboardImportPage.submit}
                    onClick={() => {
                      if (yourList.includes(shareDashboardItem)) {
                        appEvents.publish({
                          type: AppEvents.alertError.name,
                          payload: ["A dashboard with this uid or title has already been shared"],
                        });
                      } else {
                        yourList.push(shareDashboardItem);
                        sharedList.push(shareDashboardItem);
                        appEvents.publish({
                          type: AppEvents.alertSuccess.name,
                          payload: ["Dashboard shared successfully"],
                        });
                      }
                      return appEvents;
                    }}
                    href={`${config.appSubUrl}/a/geneva-app/dashboardgallery`}
                  >
                    Share
                  </LinkButton>
                  <LinkButton variant="secondary" href={`${config.appSubUrl}/a/geneva-app/dashboardgallery`}>
                    Cancel
                  </LinkButton>
                </HorizontalGroup>
              </>
            )}
          </Form>
        </div>
      </>
    );
  };

  return <div>{renderImportForm()}</div>;
};

// eslint-disable-next-line import/no-named-as-default-member
export class UploadGalleryDashboardPage extends React.PureComponent {
  override render() {
    return <UploadDashboardPage />;
  }
}

// const importStyles = stylesFactory((theme: GrafanaTheme2) => {
//   return {
//     option: css`
//       margin-bottom: "10px";
//       max-width: 600px;
//     `,
//     labelWithLink: css`
//       max-width: 100%;
//     `,
//     linkWithinLabel: css`
//       font-size: inherit;
//     `,
//   };
// });
