import { ResourceHealthStatus } from "api/constants";

export interface HealthQuery extends HealthQueryAdvancedSettings {
  healthQueryType?: string;
  topologyNodeId?: string;
  selectedResources?: HealthResource[];
  selectedResourcesVar?: string;
  watchdogResource?: HealthResource;
  selectedWatchdogResourceVar?: string;
  selectedMonitor?: Monitor;
  selectedMonitorVar?: string;
  monitor?: string;
  monitorDimensions?: string[];
  monitorDimensionValues?: string[][];
  monitorDimensionOperators?: string[];
  monitorUiType?: string;
}

export type HealthTransforms = "raw" | "friendly" | "percent";
interface HealthQueryAdvancedSettings {
  useResourceVars?: boolean;
  healthHistoryValueTransform?: HealthTransforms;
}

export interface HealthResource {
  id: string;
  name: string;
  healthStatus: number;
  offlineStatus: number;
  inRepair: boolean;
  suppressionState: number;
  suppressionReason: number;
  hasChildren: boolean;
  children: HealthResource[];
  resourceId?: ResourceId;
}

export interface ResourceId {
  type: string;
  name: string;
  topologyName: string;
  // TODO fix this usage of any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resourceDimensions?: any;
}

export interface MetaDataCollection {
  [key: string]: string | number | boolean;
}

export interface Monitor {
  name: string;
  healthStatus: number;
  healthy: boolean;
  message: string;
  monitorType: number;
  timeStamp: string;
  preAggThrottlingFraction: number;
  metadata: MetaDataCollection;
}

export interface Watchdog {
  category: string;
  monitors: Monitor[];
}

interface AnnotationMetadata {
  annotationType: string;
  correlationId: string;
  severity: string;
}
export interface GenevaAnnotation {
  content: string;
  contextId: string;
  displayName: string;
  id: string;
  modifiedBy: string;
  modifiedAt: string;
  monitorName: string;
  timestamp: string;
  resourceId: ResourceId;
  metadata?: AnnotationMetadata;
}

export enum AnnotationDataFrameFieldNames {
  id = "id",
  title = "title",
  text = "text",
  time = "time",
  color = "color",
}

export interface ResourceNode
  extends Omit<Partial<HealthResource>, "children">,
    Partial<Monitor>,
    Omit<Partial<Watchdog>, "monitors"> {
  children: ResourceNode[];
  monitors?: ResourceNode[];
}

export enum LinkConfigurationType {
  DGrep = "Microsoft.Online.Metrics.Common.EventConfiguration.DGrepJarvisLink, Microsoft.Online.Metrics.Common",
  Kusto = "Microsoft.Online.Metrics.Common.EventConfiguration.KustoJarvisLink, Microsoft.Online.Metrics.Common",
  Dashboard = "Microsoft.Online.Metrics.Common.EventConfiguration.DashboardConfiguration, Microsoft.Online.Metrics.Common",
  Actions = "Microsoft.Online.RecoveryService.Contract.Models.ActionConfiguration, Microsoft.Online.Metrics.Common",
  Generic = "Microsoft.Online.Metrics.Common.EventConfiguration.GenericCustomLink, Microsoft.Online.Metrics.Common",
  Trace = "Microsoft.Online.Metrics.Common.EventConfiguration.TracesLink, Microsoft.Online.Metrics.Common",
  Grafana = "Microsoft.Online.Metrics.Common.EventConfiguration.GrafanaLink, Microsoft.Online.Metrics.Common",
}

export interface CustomLinkConfiguration {
  id?: string | number;
  $type: LinkConfigurationType;
  title: string;
  autoExecute: boolean;
  executionDelay: string;
  preIncidentQueryTimeout: string;
  accountName?: string;
}

/**
 * Shadow of a class from
 * EngSys\MDA\MetricsAndHealth\src\Metrics.Common\EventConfiguration\MonitorConfiguration.cs
 * Note this definition is incomplete and paritally based on Jarvis copy of the above.
 * https://msazure.visualstudio.com/One/_git/EngSys-Amalgam?path=/src/mdm/models.ts
 */
export interface MonitorConfiguration {
  id: string;

  version: number;

  conditions: string[][];

  customLinks?: CustomLinkConfiguration[];

  templateType: unknown;

  templateSpecificParameters: unknown;

  templateConfiguration?: TemplateConfiguration;

  thresholds: unknown[];

  metadata?: unknown[];

  lookbackDuration: string;

  frequency: string;

  /**
   * The name of the dashboard to link to from this monitor.
   * Deprecated but must be used as a fallback as it is saved in many monitors.
   */
  metricsViewName?: string;

  /**
   * The namespace of the dashboard to link to.
   */
  dashboardNamespace?: string;

  /**
   * The name of the dashboard to link to within dashboardNamespace.
   * This supersedes metricsViewName.
   */
  dashboardName?: string;

  /**
   * This supersedes dashboardNamespace and dashboardName
   */
  dashboards?: unknown[];

  diagnosticsLink?: string;

  eventIdentifier?: unknown;

  /**
   * Name of resource type for the monitor
   */
  resourceType?: string;

  // State
  isSilent?: boolean;
  isDisabled?: boolean;

  // Last updated fields
  lastUpdatedBy?: string;
  lastUpdateTime?: string;
}

interface AlertEnrichmentConfigurationJS {
  customLinks: CustomLinkConfiguration[];
}

// https://msazure.visualstudio.com/One/_git/EngSys-MDA-MetricsAndHealth?path=%2Fsrc%2FDistributedMonitorService%2FMonitor.Common%2FModels%2FConfiguration%2FMonitorConfigurationV2.cs&version=GBmaster
export interface MonitorV2Configuration {
  schemaVersion: number;
  id?: string; // New monitor config doesn't have id.
  name: string;
  tenant: string;
  lastModifiedTime?: string;
  lastModifiedBy?: string;
  state: unknown;
  description: string;
  tags: string[];
  resourceType: string;
  lookback: unknown;
  frequency: unknown;
  timeSeries: {
    metric: string;
    name: string;
    namespace: string;
    samplingType: string;
    tenant: string;
  }[];
  expressions: unknown;
  targetDimensions: unknown;
  alertConditions: AlertConditionJS;
  outlets: unknown;
  advancedConfiguration: unknown;
  recoveryRules: unknown;
  autoMitigationConfiguration?: unknown;
  alertEnrichmentConfiguration: AlertEnrichmentConfigurationJS;
  customMetadata: unknown;
}
// The following types are copied from https://msazure.visualstudio.com/One/_git/EngSys-Amalgam?path=src/edit-monitor/monitor-config-contracts.ts
interface RawBatchPreviewResult {
  combinationResult?: CombinationPreviewResult[];
  alertConditions?: AlertConditionJS[];
  combinationName?: string;
}

export interface CombinationPreviewResult {
  dimensionCombination: unknown;
  evaluatedValue: PreviewEvaluatedResult[];
  message: string[];
}

interface PreviewEvaluatedResult {
  timestamp: string;
  eventEvaluatedResult?: EventEvaluatedResult[];
  evaluatedResult: unknown;
  alertConditionResult: AlertConditionEvaluatedResult[];
}

interface AlertConditionEvaluatedResult {
  name: string;
  violated: boolean;
  thresholdPredictionResults: unknown;
}

interface EventEvaluatedResult {
  eventName: string;
  value?: number;
}

interface monitorEvaluationV1Response {
  timeSeries?: { $values: [{ value: number[]; name: string }] };
}
export interface MonitorEvaluationResponse
  extends Partial<RawBatchPreviewResult>,
    Partial<monitorEvaluationV1Response> {}

export interface AlertConditionJS {
  id: string;
  name: string;
  severity?: number;
  healthStatus: ResourceHealthStatus;
  metadata: {
    [key: string]: string;
  };
  conditions: IThresholdConditionJS[];
  alertOutletIds: string[];
  fireOnSkip?: boolean;
  fireOnDivideByZero?: boolean;
}

interface IThresholdConditionJS {
  id: string;
  expressionId: string;
  value: number;
}

/**
 * Shadow of an enum from
 * EngSys\MDA\MetricsAndHealth\src\Metrics.Common\EventConfiguration\MonitorTemplateType.cs
 */
export enum MonitorTemplateType {
  /**
   * Unknown template type
   */
  Unknown = 0,

  /**
   * Users will provide a JS snippet that returns <see cref="MonitorResult"/>.
   */
  Custom = 1,

  /**
   * Performs the sum on the raw data
   */
  Sum2 = 2,

  /**
   * Performs an average on the raw data of 2 metrics
   */
  Average2 = 3,

  /**
   * Compares the sum of a metric in the present time window against a past time window
   */
  SumAnomaly = 4,

  /**
   * Compares the average of two metrics in the present time window against a past time window
   */
  AverageAnomaly = 5,

  /**
   * Performs the percentage operation on the numerators and denominators that are defined.
   */
  Percentage = 6,

  /**
   * Monitor template to count the number for failing buckets in lookback period and alert based
   * on failing threshold.
   */
  FailingBucket = 7,

  /**
   * Monitor template to alert when two or more events happen at the same time.
   */
  CorrelatedEvents = 8,

  /**
   * Monitor template to alert when we find anomaly points using Martingale Algorithm
   */
  MartingaleAnomaly = 9,

  /**
   * Monitor template to perform a linear regression and z-score based anomaly detection
   * on the incoming data point and alert when the current z-score is some provided number
   * of standard deviations away from the mean.
   */
  ZScoreAnomaly = 10,

  /**
   * Monitor template that correlates two or more events, within time buckets.
   * This is a hybrid of CorrelatedEvents and FailingBucket templates.
   */
  CorrelatedFailingBucket = 11,

  /**
   * Monitor template that leverage an external service that applies machine learning algorithms to check whether a timeseries
   * is deviating from its baseline.
   */
  DynamicBaseline = 13,

  /**
   * see https://eng.ms/docs/products/geneva/alerts/advancedtopics/monitoringoptions/peergroup
   */
  PeerGroup = 17,
}

interface TemplateConfiguration {
  templateType?: unknown;

  percentageTemplate?: unknown;

  failingBucketTemplate?: unknown;

  correlatedEventsTemplate?: unknown;

  zScoreAnomalyTemplate?: unknown;

  baselineAnomalyTemplate?: unknown;

  correlatedFailingBucketTemplate?: {
    expressions: { percentageTemplate: PercentageTemplate }[];
    alertRules: unknown;
  };

  peerGroupTemplate?: unknown;
}

interface PercentageTemplate {
  numerator: DataSource[];

  denominator: DataSource[];

  // Gets or sets the value indicating if the fraction has to be a ratio or if it needs to be multiplied by 100.
  evaluatePureFraction: boolean;

  multiplier?: number;
}

interface DataSource {
  fetchMetrics: TimeSeriesDataSource;
}

interface TimeSeriesDataSource {
  monitoringAccount: string;

  namespace: string;

  metric: string;

  samplingType: string;

  isNegative: boolean;

  conditions: string[][];
}
