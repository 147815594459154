//types of tags that can be added to each dashboard in the gallery
export enum GalleryTag {
  am = "Azure Monitor",
  geneva = "Geneva",
  adx = "Azure Data Explorer",
  prom = "Prometheus",
}

//each dashboard tile in the gallery will have these properties
export interface GalleryDashboard {
  title: string;
  description: string;
  tags: GalleryTag[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logo: any;
  uploaded?: Date;
  lastUpdated?: Date;
  json: Object;
  sharedByYou?: boolean;
  imported?: boolean;
}
