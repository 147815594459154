import { MutableDataFrame, FieldType, DataQueryResponseData } from "@grafana/data";
import { defaults } from "lodash";
import { GenevaResponse } from "../types/GenevaResponse";
import { defaultQuery, GenevaQuery } from "../types";
import { generateMetricName } from "../util/generateMetricName";

/**
 * Converts Geneva response to the one used by Grafana
 * @param request Geneva request
 * @param response Geneva response
 * @param fallbackResolutionInMilliseconds Fallback resolution, used if the one returned by the API is negative (@see https://msazure.visualstudio.com/One/_workitems/edit/9227889)
 */
export const convertGenevaResponse = (
  target: GenevaQuery,
  response: GenevaResponse,
  fallbackResolutionInMilliseconds: number,
  stamp?: string,
  startTime?: string,
  endTime?: string,
  timeshift?: string
): DataQueryResponseData => {
  // step determines how close in time (ms) the points will be to each other.
  const step =
    response.timeResolutionInMilliseconds > 0
      ? response.timeResolutionInMilliseconds
      : fallbackResolutionInMilliseconds;

  const startTimeEpoc = new Date(startTime || response.startTimeUtc).valueOf();
  const endTimeEpoc = new Date(endTime || response.endTimeUtc).valueOf();

  // rounding of start and end time
  const from = startTimeEpoc;
  const to = endTimeEpoc;

  // duration of the time range, in milliseconds.
  const duration = to - from;

  const series = response.timeSeriesList?.$values || [];

  const query = defaults(target, defaultQuery);
  const { refId } = query;
  const metricNames = series.map((serie) => generateMetricName(target, serie, stamp, timeshift));
  const fields = [{ name: "time", type: FieldType.time }];

  for (const metricName of metricNames) {
    fields.push({ name: metricName, type: FieldType.number });
  }

  const frame = new MutableDataFrame({
    refId,
    fields,
  });

  let count = 0;
  for (let t = 0; t < duration; t += step) {
    // TODO fix this usage of any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = { time: from + t };

    for (const serie of series) {
      const metricName: string = generateMetricName(target, serie, stamp, timeshift);
      // the two cases below are not coming from user input
      // eslint-disable-next-line security/detect-object-injection
      const value = serie.timeSeriesValues.$values[0].value[count];
      const valueIsNumber = typeof value === "number";

      // converting NaN to 0
      // eslint-disable-next-line security/detect-object-injection
      obj[metricName] = valueIsNumber ? value : null;
    }

    frame.add(obj);
    count++;
  }

  return frame;
};
