import { css } from "@emotion/css";
import { getDataSourceSrv } from "@grafana/runtime";
import { Icon } from "@grafana/ui";
import { queryClient } from "api/queryClient";
import { cloudConfigLoader } from "api/resources/cloudConfigLoader";
import { featureFlagsLoader } from "api/resources/featureFlagsLoader";
import { Tile } from "components/Tile";
import * as React from "react";
import { useMemo } from "react";
import { QueryClientProvider } from "react-query";
import { GenevaJsonData, TileContent } from "types";
import { generateExploreQuery } from "util/dataUtil";

const tiles: TileContent[] = [
  {
    title: "Geneva Metrics",
    serviceName: "metrics",
    icon: "MetricsIcon",
    heading: "Geneva Metrics",
    description: "Create and view dashboards based on Geneve Multi-dimensional Metrics (MDM)",
    nextSteps: [
      {
        link: "/dashboards?tag=Pinned%20from%20Jarvis",
        text: "Pinned from Jarvis Dashboards",
      },
      {
        link: "/dashboards/f/geneva/geneva",
        text: "Explore Out of Box dashboards",
      },
    ],
    learnHref: "https://eng.ms/docs/products/geneva/metrics/overview",
    learnHrefText: "Metric Overview | Geneva Monitoring Docs (eng.ms)",
  },
  {
    title: "Geneva Health",
    serviceName: "health",
    heading: "Geneva Health",
    icon: "HealthIcon",
    description: "View your service health model, resource health history and alerts",
    nextSteps: [
      {
        link: "/d/QTVw7iK7z",
        text: "Go to Health dashboard",
      },
    ],
    learnHref: "https://eng.ms/docs/products/geneva/alerts/advancedtopics/healthmodel",
    learnHrefText: "What is a Health Model? | Geneva Monitoring Docs (eng.ms)",
  },
  {
    title: "Geneva Traces",
    serviceName: "traces",
    icon: "TracesIcon",
    heading: "Geneva Traces",
    description: "Find and view trace details to investigate the performance of individual transactions",
    nextSteps: [
      {
        link: `explore?orgId=1&left=${generateExploreQuery("traces", "transactionSearch")}`,
        text: "Explore Geneva Traces",
      },
    ],
    learnHref: "https://eng.ms/docs/products/geneva/alerts/advancedtopics/healthmodel",
    learnHrefText: "What are Traces? | Geneva Monitoring Docs (eng.ms)",
  },
  {
    title: "IcM",
    serviceName: "icm",
    heading: "IcM",
    icon: "IcMIcon",
    description: "See the incident analytics report for your service",
    nextSteps: [
      {
        link: "/d/icm-geneva-canned-dashboard",
        text: "Go to IcM dashboard",
      },
    ],
    learnHref: "https://icmdocs.azurewebsites.net/",
    learnHrefText: "IcM website (icmdocs.azurewebsites.net) ",
  },
  {
    title: "SLI Insights",
    serviceName: "sli-slo",
    icon: "InsightsIcon",
    heading: "SLI Insights",
    description: "View your service health through Service Level Indicator (SLI) data for DRI scenarios",
    nextSteps: [
      {
        link: "/d/sli-insights-geneva-overview",
        text: "Go to SLI Insights Overview",
      },
      {
        link: "/d/sli-insights-geneva-customer-views",
        text: "Go to SLI Insights Customer Views",
      },
    ],
    learnHref: "https://eng.ms/docs/products/geneva/alerts/advancedtopics/healthmodel",
    learnHrefText: "Introduction to SLO/SLI | Geneva Monitoring Docs (eng.ms)",
  },
  {
    title: "Dashboard Gallery",
    serviceName: "dashGallery",
    featureFlags: ["dashGallery"],
    icon: "GrafanaIcon",
    heading: "Dashboard Gallery",
    description: "Share your dashboards with other internal teams and sicover partner team dashboards",
    nextSteps: [
      {
        link: "/a/geneva-app/dashboardgallery",
        text: "Go to Dashboard Gallery",
      },
      {
        link: "/a/geneva-app/dashboardgallery/share",
        text: "Upload a dashboard to the gallery",
      },
    ],
    learnHref: "https://eng.ms/docs/products/geneva/metrics/overview",
    learnHrefText: "Internal Dashboard Gallery | Geneva Monitoring Docs (eng.ms)",
  },
  // leaving this uncommented until we are ready to add them
  // {
  //   title: "Geneva Logs (Coming soon)",
  //   heading: "Geneva Logs (Coming soon)",
  //   description: "View, explore and create dashboards based on Geneva Logs DGrep",
  //   nextSteps: [],
  //   learnHref: "https://icmdocs.azurewebsites.net/",
  //   learnHrefText: "What are Logs? | Geneva Monitoring Docs (eng.ms)",
  // },
];

const GenevaHubPage = () => {
  const styles = getStyles();
  //get list of instance datasources
  const dataSourceList = getDataSourceSrv();
  //get list of DataSourceInstanceSettings
  const settingsList = dataSourceList.getList();
  //gets the first genevadatasource with currentuser or msi if none are found
  const dataSourceSettings = settingsList
    .filter((settings) => settings.type === "geneva-datasource")
    .sort((a, b) => {
      const genevaDataA: GenevaJsonData = a.jsonData;
      const genevaDataB: GenevaJsonData = b.jsonData;

      // Prioritize "currentuser" authType
      if (
        genevaDataA.azureCredentials?.authType === "currentuser" &&
        genevaDataB.azureCredentials?.authType !== "currentuser"
      ) {
        return -1;
      }
      if (
        genevaDataB.azureCredentials?.authType === "currentuser" &&
        genevaDataA.azureCredentials?.authType !== "currentuser"
      ) {
        return 1;
      }

      // Then prioritize "msi" authType
      if (genevaDataA.azureCredentials?.authType === "msi" && genevaDataB.azureCredentials?.authType !== "msi") {
        return -1;
      }
      if (genevaDataB.azureCredentials?.authType === "msi" && genevaDataA.azureCredentials?.authType !== "msi") {
        return 1;
      }

      return 0; // keep the same order
    });
  //get instance settings for first geneva datasource being used
  const datasourceFound = dataSourceSettings.length > 0 ? dataSourceSettings[0] : undefined;
  const instanceSettings = dataSourceList.getInstanceSettings(datasourceFound);
  //set feature flags equal to undefined if instanceSettings is undefined, otherwise use the featureFlagsLoader and acquired instanceSettings
  const featureFlags = !!instanceSettings
    ? featureFlagsLoader
        .withOptions({
          retry: 1,
          onError: (error) => {
            throw new Error(`failed to fetch feature flags: ${error}`);
          },
        })
        .useQuery(instanceSettings)
    : undefined;
  const cloudConfig = !!instanceSettings
    ? cloudConfigLoader
        .withOptions({
          retry: 1,
          onError: (error) => {
            throw new Error(`failed to fetch available services: ${error}`);
          },
        })
        .useQuery(instanceSettings)
    : undefined;
  //only show the dashboard gallery tile if the feature flag is enabled
  const filteredTiles: TileContent[] = useMemo(() => {
    const availableServices = cloudConfig?.data?.availableServices?.map((service) => service.name);
    const enabledFeatureFlags = featureFlags?.data;
    let filtered: TileContent[] = [];
    // filter based on available services
    if (!!availableServices) {
      filtered = tiles.filter((tile) => {
        return availableServices.includes(tile.serviceName);
      });
    }
    // filter based on feature flags
    if (!!enabledFeatureFlags) {
      filtered = filtered.filter((tile) => {
        // if the tile does not have any feature flags, include it by default
        if (!tile.featureFlags || tile.featureFlags.length === 0) {
          return true;
        }

        // check if all feature flags for the tile are enabled
        return tile.featureFlags.every((flag) => enabledFeatureFlags[`${flag}`]);
      });
    }

    return filtered;
  }, [cloudConfig?.data?.availableServices, featureFlags?.data]);

  return (
    <div>
      <div className={styles.tilesContainer}>
        <div className={styles.tiles}>
          {filteredTiles.map((tile) => (
            <div>
              <Tile tileContent={tile} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.contactContainer}>
        <a href="mailto:genevaingrafana@microsoft.com" target="_blank" rel="noreferrer" className={styles.href}>
          Contact us
        </a>{" "}
        |{" "}
        <a
          href="https://eng.ms/docs/products/geneva/dashboards/grafana/genevadatasource"
          target="_blank"
          rel="noreferrer"
          className={styles.href}
        >
          Docs <Icon name="external-link-alt" />
        </a>
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    tilesContainer: css`
      overflow-x: scroll;
      overflow-y: hidden;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-top: 10px;
    `,
    tiles: css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `,
    href: css`
      color: #62b4fb;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 18px;
    `,
    contactContainer: css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    `,
  };
};

export default () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GenevaHubPage></GenevaHubPage>
    </QueryClientProvider>
  );
};
