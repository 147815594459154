import React from "react";

export const MetricsIcon = () => {
  return (
    <div>
      <svg
        id="b4a9b507-f080-4dfa-ae5b-d825e132a26b"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
        width="50"
        height="50"
      >
        <defs>
          <linearGradient
            id="e1d73f47-752a-4159-9841-f0e298db8522"
            x1="6.57"
            y1="7.06"
            x2="6.57"
            y2="3.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#773adc" />
            <stop offset="1" stopColor="#b77af4" />
          </linearGradient>
          <linearGradient
            id="e8c6440a-2701-4507-8d04-c5dedd18bcf2"
            x1="11.02"
            y1="11.15"
            x2="11.02"
            y2="7.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#773adc" />
            <stop offset="1" stopColor="#b77af4" />
          </linearGradient>
          <linearGradient
            id="b40385c6-522c-4b7d-afa0-c8216f314bdf"
            x1="15.15"
            y1="3.79"
            x2="15.15"
            y2="0.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#773adc" />
            <stop offset="1" stopColor="#b77af4" />
          </linearGradient>
          <linearGradient
            id="a5c9d1e7-33fb-4353-b4bd-5d3732a54634"
            x1="2.85"
            y1="11.86"
            x2="2.85"
            y2="8.57"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#773adc" />
            <stop offset="1" stopColor="#b77af4" />
          </linearGradient>
        </defs>
        <title>Icon-manage-319</title>
        <polygon
          points="14.73 1.62 10.74 8.72 6.52 4.42 2.17 10.06 3.19 10.86 6.64 6.41 11.02 10.88 15.86 2.27 14.73 1.62"
          fill="#b796f9"
        />
        <ellipse cx="6.57" cy="5.41" rx="1.64" ry="1.65" fill="url(#e1d73f47-752a-4159-9841-f0e298db8522)" />
        <ellipse cx="11.02" cy="9.5" rx="1.64" ry="1.65" fill="url(#e8c6440a-2701-4507-8d04-c5dedd18bcf2)" />
        <ellipse cx="15.15" cy="2.15" rx="1.64" ry="1.65" fill="url(#b40385c6-522c-4b7d-afa0-c8216f314bdf)" />
        <ellipse cx="2.85" cy="10.22" rx="1.64" ry="1.65" fill="url(#a5c9d1e7-33fb-4353-b4bd-5d3732a54634)" />
        <rect x="5.67" y="9.92" width="2.58" height="7.58" rx="0.28" fill="#0078d4" />
        <rect x="9.78" y="12.54" width="2.58" height="4.96" rx="0.28" fill="#005ba1" />
        <rect x="13.89" y="7.38" width="2.58" height="10.12" rx="0.28" fill="#005ba1" />
        <rect x="1.56" y="12.54" width="2.58" height="4.96" rx="0.28" fill="#5ea0ef" />
      </svg>
    </div>
  );
};
