import React from "react";

import { GalleryActionRow } from "pages/GalleryActions/GalleryActionRow";
import { useSearchStateManager } from "pages/SearchState/SearchStateManager";

export function GalleryBrowseActions() {
  const [searchState, stateManager] = useSearchStateManager();

  return (
    <div>
      <GalleryActionRow
        showStarredFilter
        state={searchState}
        getTagOptions={stateManager.getTagOptions}
        onStarredFilterChange={stateManager.onStarredFilterChange}
        onSortChange={stateManager.onSortChange}
        onTagFilterChange={stateManager.onTagFilterChange}
      />
    </div>
  );
}
