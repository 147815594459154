import { DataSourceInstanceSettings } from "@grafana/data";
import { DataSourceWithBackend } from "@grafana/runtime";
import { DgrepProvider } from "dataproviders/DgrepProvider";
import { HealthProvider } from "dataproviders/HealthProvider";
import { IDataProvider } from "dataproviders/IDataProvider";
import { MetricsProvider } from "dataproviders/MetricsProvider";
import { TracesProvider } from "dataproviders/TracesProvider";
import {
  CurrentCloudConfig,
  GenevaJsonData,
  GenevaQuery,
  GenevaServiceType,
  TransactionSearchFilter,
  TransactionSearchFiterResponse,
} from "types";

export default class BackendDataSource extends DataSourceWithBackend<GenevaQuery, GenevaJsonData> {
  instanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  metricsProvider: IDataProvider;
  tracesProvider: IDataProvider;
  healthProvider: IDataProvider;
  dgrepProvider: IDataProvider;
  provider: { [key in GenevaServiceType]: IDataProvider };

  constructor(instanceSettings: DataSourceInstanceSettings<GenevaJsonData>) {
    super(instanceSettings);
    this.instanceSettings = instanceSettings;

    this.metricsProvider = new MetricsProvider(this, instanceSettings);
    this.tracesProvider = new TracesProvider(this, instanceSettings);
    this.healthProvider = new HealthProvider(instanceSettings);
    this.dgrepProvider = new DgrepProvider(this, instanceSettings);

    this.provider = {
      ["metrics"]: this.metricsProvider,
      ["traces"]: this.tracesProvider,
      ["health"]: this.healthProvider,
      ["jsexp"]: this.metricsProvider,
      ["dgrep"]: this.dgrepProvider,
    };
  }

  override filterQuery(query: GenevaQuery): boolean {
    if (!query.service) {
      return false;
    }

    return this.provider[query.service as GenevaServiceType]?.filterQuery(query) ?? true;
  }
  getStamps(account: string): Promise<string | undefined> {
    if (account && !!this.instanceSettings.jsonData?.cloud) {
      return this.getResource(`stamps?account=${account}`);
    }
    return Promise.resolve(undefined);
  }

  /**
   Dgrep Related backend Query
   */

  getDgrepNamespaces(env: string): Promise<string[] | undefined> {
    return this.getResource(`getDgrepNamespace?mdsEnv=${env}`);
  }

  getDgrepEvents(env: string, namespace: string): Promise<string[] | undefined> {
    return this.getResource(`getDgrepEvents?mdsEnv=${env}&mdsnamespace=${namespace}`);
  }

  getDgrepScopes(env: string, namespace: string, events: string[]): Promise<string[] | undefined> {
    return this.postResource(`getDgrepScopes?mdsEnv=${env}&mdsnamespace=${namespace}`, events);
  }

  getDgrepScopeValue(
    env: string,
    namespace: string,
    events: string[],
    scopeName: string
  ): Promise<string[] | undefined> {
    return this.postResource(
      `getDgrepScopeValue?mdsEnv=${env}&mdsnamespace=${namespace}&identity=${scopeName}`,
      events
    );
  }

  getDgrepFilters(env: string, namespace: string, event: string): Promise<string[] | undefined> {
    return this.getResource(`getDgrepFilters?mdsEnv=${env}&mdsnamespace=${namespace}&mdsEvent=${event}`);
  }

  /**
   *
   * @returns list of available endpoints
   */
  getSearchEndpoints(): Promise<string[] | undefined> {
    const data = {
      filters: [],
      projection: "logsEnvironment",
      pageSize: 100,
    };
    return this.postResource("logsEnvironments", data);
  }

  /**
   *
   * @param endpoint selected endpoint
   * @returns list of available namespaces for the selected endpoint
   */
  getSearchNamespaces(endpoint: string): Promise<string[] | undefined> {
    const data = {
      filters: [
        {
          fieldName: "logsEnvironment",
          operator: "==",
          values: [endpoint],
        },
      ],
      pageSize: 100,
      projection: "namespace",
    };
    return this.postResource("searchNamespaces", data);
  }

  /**
   *
   * @param endpoint selected endpoint
   * @param namespaces selected namespaces
   * @returns list of available filters for the selected endpoint and namespaces
   */
  getSearchFilters(endpoint: string, namespaces: string[]): Promise<TransactionSearchFiterResponse | undefined> {
    const params: Record<string, unknown> = {};
    params["logsEnvironment"] = endpoint;
    params["namespace"] = namespaces;
    return this.getResource("searchFilters", params);
  }

  /**
   *
   * @param filterName filter name to get the values for
   * @param selectedFilters all the already selected filters so that new filter values can be fetched accordingly
   * @returns list of available values for filterName
   */
  getSearchFilterValues(filterName: string, selectedFilters: TransactionSearchFilter[]): Promise<string[] | undefined> {
    const data = {
      filters: selectedFilters,
      projection: filterName,
      pageSize: 100,
    };
    return this.postResource("searchFilterValues", data);
  }

  /**
   * @returns list of available services for the datasource
   */
  loadCloudConfig(): Promise<CurrentCloudConfig | undefined> {
    const data = {
      host: window.location.host,
    };
    return this.postResource("loadCloudConfig", data);
  }

  async setCloudConfig() {
    const cloudConfig = await this.loadCloudConfig();
    if (this.instanceSettings.jsonData && !this.instanceSettings.jsonData.cloud) {
      this.instanceSettings.jsonData.cloud = cloudConfig?.cloud;
      // this is a fallback for datasources that have already been created and do not have cloud set
      // or for datasources that are new, when save and test was not clicked
      this.getResource(`setCloud?cloud=${cloudConfig?.cloud}`);
    }
    return cloudConfig;
  }
}
