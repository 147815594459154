import { AppRootProps } from "@grafana/data";
import { PluginPage } from "@grafana/runtime";
import { BrowseGalleryPage } from "pages/GalleryPages/BrowseGalleryPage";
import { UploadGalleryDashboardPage } from "pages/GalleryPages/ShareDashboardPage";
import GenevaHubPage from "pages/GenevaHubPage";
import { MetricsExplorePage } from "pages/MetricsExplorePage";
import { TraceExplorePage } from "pages/TraceExplorePage";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { PluginPropsContext } from "../../util/utils.plugin";
import { IcMDashboard } from "pages/IcMDashboard";
import { SliSloDashboard } from "pages/SliSloDashboard";

export class App extends React.PureComponent<AppRootProps> {
  override render() {
    return (
      <PluginPropsContext.Provider value={this.props}>
        <PluginPage>
          <Switch>
            <Route path="/a/geneva-app/dashboardgallery" exact>
              <BrowseGalleryPage />
            </Route>
            <Route path="/a/geneva-app/dashboardgallery/share" exact>
              <UploadGalleryDashboardPage />
            </Route>
            <Route path="/a/geneva-app" exact>
              <GenevaHubPage />
            </Route>
            <Route path="/a/geneva-app/metricsexplore" exact>
              <MetricsExplorePage />
            </Route>
            <Route path="/a/geneva-app/traceexplore" exact>
              <TraceExplorePage />
            </Route>
            <Route path="/a/geneva-app/icm" exact>
              <IcMDashboard />
            </Route>
            <Route path="/a/geneva-app/slislo" exact>
              <SliSloDashboard />
            </Route>
          </Switch>
        </PluginPage>
      </PluginPropsContext.Provider>
    );
  }
}
