import { DataSourceInstanceSettings } from "@grafana/data";
import { FetchError, FetchResponse, getBackendSrv } from "@grafana/runtime";
import { getOperationId, isDev, trackDependencyData } from "appInsights";
import { lastValueFrom } from "rxjs";
import { v4 } from "uuid";
import { GenevaJsonData } from "../types";

export async function makeGenevaRequest<T>(options: {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  path: string;
  method: "GET" | "POST";
  target: string;
  data?: unknown;
  name?: string;
  telemetryProperties?: { [key: string]: unknown };
}): Promise<FetchResponse<T>> {
  const startTime = new Date();

  const requestId =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !isDev() || (window as any).applicationInsights == undefined
      ? v4()
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).applicationInsights.context.telemetryTrace.traceID;

  return lastValueFrom(
    getBackendSrv().fetch<T>({
      url: `/api/datasources/${options.dataSourceInstanceSettings.id}/resources${options.path}`,
      method: options.method ?? "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        TraceGuid: requestId,
        operation_parentId: getOperationId(),
        Target: options.target,
      },
      requestId,
      data: options.data ? JSON.stringify(options.data) : undefined,
    })
  )
    .then((response) => {
      trackDependencyData(
        options.name || sanitizeName(options.path),
        startTime,
        options.target,
        response,
        options.telemetryProperties
      );
      return response;
    })
    .catch((response: unknown) => {
      if (isFetchError(response)) {
        trackDependencyData(
          options.name || sanitizeName(options.path),
          startTime,
          options.target,
          response,
          options.telemetryProperties
        );
      }
      throw response;
    });
}

function isFetchError(value: unknown): value is FetchError {
  return (
    value !== null &&
    typeof value !== "object" &&
    typeof (value as FetchError).status === "number" &&
    typeof (value as FetchError).config !== null &&
    typeof (value as FetchError).config === "object" &&
    typeof (value as FetchError).config.url === "string"
  );
}

//clean up name so that we it doesn't show / in the telemetry
const sanitizeName = (name: string) => {
  if (name.startsWith("/")) {
    return name.slice(1);
  }
  return name;
};
