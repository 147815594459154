import { Alert } from "@grafana/ui";
import { trackPageView } from "appInsights";
import * as React from "react";
import { generateExploreQuery } from "util/dataUtil";

export class TraceExplorePage extends React.PureComponent {
  override render() {
    const host = window.location.host;
    const isPublicCloud =
      host.includes("grafana.azure.com") || host.includes("localhost") || host.includes("msap-grafana.microsoft.com");
    const pageName = isPublicCloud ? "TraceExplorePage" : "TraceExplorePageUnsupported";
    trackPageView({ name: pageName, properties: { path: window.location.pathname } });
    if (isPublicCloud) {
      window.location.replace(`explore?orgId=1&left=${generateExploreQuery("traces", "transactionSearch")}`);
    }
    return (
      !isPublicCloud && (
        <div>
          <Alert title="This feature is not supported for non Public clouds" severity="warning" />
        </div>
      )
    );
  }
}
