import { GrafanaTheme2 } from "@grafana/data";
import { LayoutOrientation } from "panel/types";

export const MinInterval = 60000; // ms

export const MonitorHealthRootName = "MDM Monitors";
export const MonitorV2HealthRootName = "MDM Monitors V2";

export const MonitorHealthRootDisplayName = "Monitor Config Health";
export const MonitorV2HealthRootDisplayName = "Monitor V2 Config Health";

export const Health = {
  Topology: "Topology",
  ResourceHealth: "Resource Health",
  WatchdogHealth: "Watchdog Health",
  MonitorEvaluation: "Monitor Evaluation",
  SourceMetric: "Source Metric",
};

export enum ResourceHealthStatus {
  Healthy = 0,
  Unhealthy = 1,
  Degraded = 2,
  Unknown = 3,
}

export const GenevaHealthColors = {
  Healthy: "#107c10", //Green
  Unhealthy: "#c0050b", //Red
  Degraded: "#d47105", //Yellow/Orange
  Unknown: "#999", //Gray
  Selected: "#7397E9", //Blue border for selected nodes
};

export const getColorByHealthStatus = (status?: number) => {
  if (status === ResourceHealthStatus.Healthy) {
    return GenevaHealthColors.Healthy;
  } else if (status === ResourceHealthStatus.Unhealthy) {
    return GenevaHealthColors.Unhealthy;
  } else if (status === ResourceHealthStatus.Degraded) {
    return GenevaHealthColors.Degraded;
  }
  return !!status ? GenevaHealthColors.Unknown : "";
};

export const getBackgroundColorByOrientation = (layout: LayoutOrientation, theme: GrafanaTheme2) => {
  if (layout === "horizontal") {
    return theme.colors.background.secondary;
  } else {
    return theme.colors.secondary.main;
  }
};

export const getHoverBackgroundColorByOrientation = (layout: LayoutOrientation, theme: GrafanaTheme2) => {
  if (layout === "horizontal") {
    return theme.colors.emphasize(theme.colors.background.primary, 0.2);
  } else {
    return theme.colors.background.secondary;
  }
};
