import { GenevaQuery } from "../types";
import { GenevaSerie } from "../types/GenevaResponse";
import { populateTemplate } from "./populateTemplate";

const AggregationKey = "Aggregation";

const buildTemplateVariablesMap = (
  aggregation: string,
  dimensions: Array<{ key: string; value: string }>
): Map<string, string> =>
  dimensions.reduce((accum, item) => {
    accum.set(item.key, item.value);

    return accum;
  }, new Map([[AggregationKey, aggregation]]));

/**
 * Builds a default string in form: "Average WestUS MetricsStoreBackupManager_IN_1"
 */
const buildDefaultDimensionsString = (aggregation: string, dimensions: Array<{ key: string; value: string }>): string =>
  `${aggregation} ${dimensions.map((dimension) => dimension.value).join(" ")}`;

const buildExpandedDimensionsString = (
  aggregation: string,
  dimensions: Array<{ key: string; value: string }>
): string =>
  `${AggregationKey}: ${aggregation}, ${dimensions
    .map((dimension) => `${dimension.key}: ${dimension.value}`)
    .join(", ")}`;

/**
 * Generates a metric name from Geneva serie
 */
export const generateMetricName = (
  query: GenevaQuery,
  serie: GenevaSerie,
  stamp?: string,
  timeshift?: string
): string => {
  let name = generateMetricNameInternal(query, serie);
  if (stamp) name = `${name} ${stamp}`;
  if (timeshift) {
    return `${name} - ${timeshift}`;
  }
  return name;
};

const generateMetricNameInternal = (query: GenevaQuery, serie: GenevaSerie): string => {
  const aggregation = serie.timeSeriesValues.$values?.[0].key;
  const { useCustomSeriesNaming, customSeriesNaming } = query;
  const dimensions = serie.dimensionList.$values || [];

  if (!useCustomSeriesNaming) {
    return buildDefaultDimensionsString(aggregation, dimensions);
  }

  if (!customSeriesNaming) {
    return buildExpandedDimensionsString(aggregation, dimensions);
  }

  return populateTemplate(customSeriesNaming, buildTemplateVariablesMap(aggregation, dimensions));
};
