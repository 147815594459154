export interface DgrepQuery {
  queryId?: string;
  env?: string;
  events?: string[];
  namespaces?: string[];
  scopes?: Scope[];
  filters?: Filter[];
  kqlQuery?: string;
  maxResults?: string;
  resultFormat?: DGrepQueryFormat;
  filterQuery?: string;
  filterQueryType?: FilterQueryType;
  isCached?: boolean;
}

export type DGrepQueryFormat = "table" | "time_series" | "logs";

export enum FilterQueryType {
  SIMPLE = 0,
  //TODO: MQL not currently supported
  MQL = 1,
  KQL = 2,
}

export interface Scope {
  field?: string;
  value?: string[];
}

// eslint-disable-next-line import/no-unused-modules
export interface Filter {
  key?: string;
  operation?: number;
  value?: string;
}
