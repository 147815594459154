import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { queryClient } from "../api/queryClient";

type QueryOptions<LoaderResult> = Omit<
  UseQueryOptions<LoaderResult, unknown, LoaderResult, unknown[]>,
  "queryKey" | "queryFn"
>;

interface LoaderOptions<LoaderParams extends unknown[], LoaderResult, UseLoaderParams extends unknown[]> {
  /**
   * Unique id for this loader. Used as part of react-query query key.
   */
  id: string;

  /**
   * Optional helper to make using the useData hook on this loader more
   * ergonomic. Return false to disable the query.
   */
  useParams?: (...params: UseLoaderParams) => Readonly<LoaderParams> | LoaderParams | false;

  /**
   * Optional setting to make a key for the query cache that doesn't use
   * all the params provided to the loader
   * @param params
   */
  useKey?: (...params: LoaderParams) => Readonly<LoaderParams> | LoaderParams | string;

  /**
   * Perform any variable replacements (i.e. `getTemplateSrv().replace(someVariable)` )
   * here to ensure the cache is keyed on variable values instead of variable names.
   * */
  replaceVariables?: (...params: LoaderParams) => Readonly<LoaderParams> | LoaderParams;

  /**
   * Network request (or any promise returning function) to fetch the data.
   */
  load: (...params: LoaderParams) => Promise<LoaderResult>;

  /**
   * Any query option customizations over the base config in queryClient
   * should go here.
   */
  queryOptions?: QueryOptions<LoaderResult>;
}

interface Loader<LoaderParams extends unknown[], LoaderResult, UseLoaderParams extends unknown[] = LoaderParams> {
  /**
   * Access loader data in the context of a React component with this.
   */
  useQuery(...params: UseLoaderParams): UseQueryResult<LoaderResult>;

  /**
   * Access loader data outside the context of a React component with this.
   */
  load(...params: LoaderParams): Promise<LoaderResult>;

  withOptions(queryOptions: QueryOptions<LoaderResult>): Loader<LoaderParams, LoaderResult, UseLoaderParams>;
}

export function createLoader<
  LoaderParams extends unknown[],
  LoaderResult,
  UseLoaderParams extends unknown[] = LoaderParams
>(
  options: LoaderOptions<LoaderParams, LoaderResult, UseLoaderParams>
): Loader<LoaderParams, LoaderResult, UseLoaderParams> {
  return {
    useQuery(...useLoaderParams: UseLoaderParams) {
      const params = options.useParams?.(...useLoaderParams) ?? (useLoaderParams as unknown as LoaderParams);

      const variableReplacedParams = params ? options.replaceVariables?.(...params) ?? params : false;

      let queryKey: unknown[] = [options.id];
      if (Array.isArray(variableReplacedParams)) {
        queryKey = [options.id, ...(options.useKey?.(...variableReplacedParams) || variableReplacedParams)];
      }

      return useQuery(queryKey, () => options.load(...(variableReplacedParams as LoaderParams)), {
        enabled: params !== false,
        ...options.queryOptions,
      });
    },
    load: (...params: LoaderParams) => {
      const variableReplacedParams = options.replaceVariables?.(...params) ?? params;

      let queryKey: unknown[] = [options.id];
      if (Array.isArray(variableReplacedParams)) {
        queryKey = [options.id, ...(options.useKey?.(...variableReplacedParams) || variableReplacedParams)];
      }

      return queryClient.fetchQuery(queryKey, () => options.load(...variableReplacedParams), options.queryOptions);
    },
    withOptions: (queryOptions: QueryOptions<LoaderResult>) => {
      return createLoader({
        ...options,
        queryOptions: { ...options.queryOptions, ...queryOptions },
      });
    },
  };
}
