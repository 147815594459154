import { UrlQueryMap } from "@grafana/data";
import { SearchState } from "./SearchTypes";

/**
 * Remove undefined keys from url params object and format non-primitive values
 * @param params
 * @param folder
 */
export const parseRouteParams = (params: UrlQueryMap) => {
  const cleanedParams = Object.entries(params).reduce<Partial<SearchState>>((obj, [key, val]) => {
    if (!val) {
      return obj;
    } else if (key === "tag" && !Array.isArray(val)) {
      return { ...obj, tag: [val] as string[] };
    }

    return { ...obj, [key]: val };
  }, {});

  if (params.folder) {
    const folderStr = `folder:${params.folder}`;
    return {
      ...cleanedParams,
      query: `${folderStr} ${(cleanedParams.query ?? "").replace(folderStr, "")}`,
    };
  }

  return { ...cleanedParams };
};
