import { DataFrame, DataLink, DataQueryRequest, DataQueryResponse, DataSourceInstanceSettings } from "@grafana/data";
import { TemplateSrv, getDataSourceSrv, getTemplateSrv } from "@grafana/runtime";
import _ from "lodash";
import { lastValueFrom, map } from "rxjs";
import { ExemplarTraceIdDestination, GenevaJsonData, GenevaQuery } from "types";
import {
  convertPossibleArrayToString,
  findQueryParameterValues,
  getAbsoluteTime,
  namespaceInQueryText,
  sparklineTableTransform,
  stringifyTimeRangeValue,
} from "util/dataUtil";
import BackendDataSource from "../BackendDataSource";
import { evaluateExpression, isFrameInExpression } from "./Expressions";
import { IDataProvider } from "./IDataProvider";

export class MetricsProvider implements IDataProvider {
  backend: BackendDataSource;
  instanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  allowedAccounts: string[];
  templateSrv: TemplateSrv;

  constructor(backend: BackendDataSource, instanceSettings: DataSourceInstanceSettings<GenevaJsonData>) {
    this.backend = backend;
    this.templateSrv = getTemplateSrv();
    this.instanceSettings = instanceSettings;
    this.allowedAccounts = [];
  }

  changeQueryText(queryText: string, groupVal: string, groupUnit: string) {
    if (queryText.includes("zoom")) {
      const zoomRegEx = /(\| zoom .+ by) ([0-9]+)([mshd])/;
      const newQueryText = queryText.replace(zoomRegEx, `$1 ${groupVal}${groupUnit}`);
      return newQueryText;
    }
    return queryText;
  }
  generateResolutionReduction(interpolatedOptions: DataQueryRequest<GenevaQuery>) {
    const from = interpolatedOptions?.range.from ?? this.templateSrv.replace("$__from");
    const to = interpolatedOptions?.range.to ?? this.templateSrv.replace("$__to");
    const totalDuration = getAbsoluteTime(Number(to)) - getAbsoluteTime(Number(from));
    const [groupByVal, groupUnit] = stringifyTimeRangeValue(totalDuration);
    return [groupByVal, groupUnit];
  }

  processQueryParameters(
    queryParameters: Record<string, string | number>,
    interpolatedOptions: DataQueryRequest<GenevaQuery>
  ): Record<string, string | number> {
    const queryParametersForMDM: Record<string, string | number> = {};
    Object.entries(queryParameters as Record<string, string | number>).forEach(([key, val]) => {
      queryParametersForMDM[`${key}`] = this.templateSrv.replace(
        val as string,
        interpolatedOptions?.scopedVars,
        convertPossibleArrayToString
      );
    });
    return queryParametersForMDM;
  }

  getDataLinks(options: ExemplarTraceIdDestination, from: string, to: string): DataLink[] {
    const dataLinks: DataLink[] = [];
    const dataSourceSrv = getDataSourceSrv();

    if (options.type === "Grafana") {
      const dsSettings = options.datasourceUid
        ? dataSourceSrv.getInstanceSettings(options.datasourceUid)
        : this.instanceSettings;

      dataLinks.push({
        title: options.urlDisplayLabel || `Query with ${dsSettings?.name}`,
        url: "",
        internal: {
          query: {
            traceId: "${__value.raw}",
            service: "traces",
            queryType: "traces",
            traceQueryType: "traceID",
          },
          datasourceUid: dsSettings?.uid || this.instanceSettings.uid,
          datasourceName: dsSettings?.name ?? "Data source not found",
          // TODO: this doesn't work atm because field links
          // are not generated with dataframe context in ExemplarMarker.tsx in Grafana
          // panelsState: {
          //   trace: {
          //     spanId: "${__data.fields.spanId}",
          //   },
          // },
        },
      });
    }

    if (options.type === "Jarvis") {
      const jarvisLink =
        this.instanceSettings.jsonData.environment === "int"
          ? "https://portal-int.microsoftgeneva.com"
          : "https://portal.microsoftgeneva.com";
      dataLinks.push({
        title: options.urlDisplayLabel || `Go to ${jarvisLink}`,
        url: `${jarvisLink}/trace/details/\${__value.raw}/?fromDate=${from}&toDate=${to}`,
        targetBlank: true,
      });
    }
    return dataLinks;
  }

  updateDatalinkField(dataFrame: DataFrame, interpolatedOptions: DataQueryRequest<GenevaQuery>) {
    const { exemplarTraceIdDestinations: destinations } = this.instanceSettings.jsonData;
    const from = interpolatedOptions?.range.from ?? this.templateSrv.replace("$__from");
    const to = interpolatedOptions?.range.to ?? this.templateSrv.replace("$__to");
    if (destinations?.length) {
      for (const exemplarTraceIdDestination of destinations) {
        const traceIDField = dataFrame.fields.find((field) => field.name === exemplarTraceIdDestination.name);
        if (traceIDField) {
          const links = this.getDataLinks(
            exemplarTraceIdDestination,
            new Date(from as unknown as string).toISOString(),
            new Date(to as unknown as string).toISOString()
          );
          traceIDField.config.links = traceIDField.config.links?.length
            ? [...traceIDField.config.links, ...links]
            : links;
          //TODO: could also think about bringing this to top of the list of labels?
        }
      }
    }
  }

  prepareTarget(target: GenevaQuery, operationId: string, interpolatedOptions: DataQueryRequest<GenevaQuery>): void {
    if (target.jsExpression) {
      return;
    }
    const record: Record<string, string> = {
      operation_parentId: operationId,
    };
    target.telemetryProperties = record;

    target.queryType = "metrics";
    target.customSeriesNaming = target.customSeriesNaming?.trim();

    if (target.resAggFunc && target.queryText) {
      // change zoom interval in query text to match dashboard time range if locked to time range
      if (target.lockedToTimeRange) {
        const [groupByVal, groupUnit] = this.generateResolutionReduction(interpolatedOptions);
        target.groupByValue = groupByVal;
        target.groupByUnit = groupUnit;

        const queryText = this.changeQueryText(target.queryText, groupByVal, groupUnit);
        target.queryText = queryText;
      }
      if (target.metricsQueryType === "query") {
        target.groupByValue = undefined;
        target.groupByUnit = undefined;
      }
    }
    target.queryParameters = this.processQueryParameters(
      target.queryParameters ||
        // usually queryParameters will be supplied by the editor, with values specified by author
        // but if editors weren't opened, see if we can find any query params here
        findQueryParameterValues(target.queryText ?? "", this.templateSrv.getVariables()),
      interpolatedOptions
    );

    if (interpolatedOptions.app === "explore") {
      target.isFromExplore = true;
    }
  }

  filterQuery(query: GenevaQuery): boolean {
    const filter = !query.hide;
    if (query.metricsQueryType === "jsexp") {
      if (!query.jsExpression || !filter) {
        return false;
      } else {
        return true;
      }
    }
    const samplingTypeFilter = query.metricsQueryType === "ui" && !query.samplingType ? false : true;
    const metricsFilter =
      filter &&
      !!query.account &&
      (!!query.namespace || (!query.namespace && namespaceInQueryText(query.queryText ?? ""))) &&
      samplingTypeFilter;
    return metricsFilter;
    // if (this.instanceSettings.jsonData.authType === "msi") {
    //   this.allowedAccounts = await accountLoader.load(this.instanceSettings);
    //   !this.allowedAccounts.includes(query.account);
    //   //TODO: Add toast notification for skipped query here
    // }
  }

  public async query(req: DataQueryRequest<GenevaQuery>): Promise<DataQueryResponse> {
    const expressionQueries = req.targets.filter((target) => target.jsExpression && !target.hide);
    const expressionResponses: DataFrame[] = [];
    req.targets = req.targets.filter((target) => target.service === "metrics");
    return lastValueFrom(
      this.backend.query(req).pipe(
        map(async (response: DataQueryResponse) => {
          for (const expressionQuery of expressionQueries) {
            try {
              const expResponse = await evaluateExpression(expressionQuery, response);
              if (expResponse && expResponse.length > 0) {
                expressionResponses.push(...expResponse);
              }
            } catch (error) {
              response.errors = [
                {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  message: (error as any).message,
                  refId: expressionQuery.refId,
                },
              ];
            }
          }

          const nonExpressionResponses = response.data.filter((frame) => {
            return expressionQueries.every((expressionQuery) => {
              return !isFrameInExpression(frame.refId, expressionQuery.jsExpression);
            });
          });

          if (expressionResponses.length > 0) {
            response.data = [...nonExpressionResponses, ...expressionResponses];
          }
          const [exemplarFrames, framesWithoutExemplars] = _.partition<DataFrame>(
            response.data,
            (df) => df.meta?.custom?.resultType === "exemplar"
          );

          exemplarFrames.forEach((dataFrame) => {
            return this.updateDatalinkField(dataFrame, req);
          });

          // instantiate new dataframe arrays for frames with and without sparkline conversions
          const normalFrames: DataFrame[] = [];
          const sparklineFrames: DataFrame[] = [];
          // populate both dataframe arrays based on sparkline conversion boolean in frame metadata
          for (const frame of framesWithoutExemplars) {
            if (frame.meta?.custom?.["sparklineConversion"]) {
              sparklineFrames.push(frame);
            } else {
              normalFrames.push(frame);
            }
          }
          // return response with data altered according to frame types
          return {
            ...response,
            data: [...sparklineTableTransform(sparklineFrames), ...normalFrames, ...exemplarFrames],
          } as DataQueryResponse;
        })
      )
    );
  }
}
