import { FetchError, FetchResponse } from "@grafana/runtime";
import { createDistributedTraceContextFromTrace } from "@microsoft/applicationinsights-common";
import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
  IExceptionTelemetry,
  IPageViewTelemetry,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { getUserEmail } from "api/user";
import _ from "lodash";
import { stringify } from "util/stringify";
import { v4 } from "uuid";
import { PluginInfo } from "./PluginInfo";

class AppInsights {
  private readonly instance: ApplicationInsights;
  private userEmail: string;

  constructor(userEmail: string) {
    this.userEmail = userEmail;
    this.instance = new ApplicationInsights({
      config: {
        instrumentationKey: isDev() ? "9f2e584d-41c6-4cd0-9aac-b2a0b870ec86" : "25aeb2f5-a73f-4f99-9e82-f71a79284848",
        disableFetchTracking: true,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (isDev() && (window as any).applicationInsights) {
      this.instance.core.setTraceCtx(
        createDistributedTraceContextFromTrace(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).applicationInsights.context.telemetryTrace,
          undefined
        )
      );
    }

    this.instance.loadAppInsights();
    this.instance.context.application.ver = PluginInfo.version;
    this.instance.setAuthenticatedUserContext(this.userEmail);

    this.instance.addTelemetryInitializer((envelope: ITelemetryItem) => {
      // See https://github.com/grafana/grafana/issues/31599 for why we need to do this.
      if (envelope.data?.message === "ErrorEvent: ResizeObserver loop limit exceeded") {
        return false;
      }

      if (envelope.data?.message === "ErrorEvent: ResizeObserver loop completed with undelivered notifications.") {
        return false;
      }

      if (!envelope.data) {
        envelope.data = {};
      }

      if (envelope.tags) {
        envelope.tags["ai.user.authUserId"] = this.userEmail;
      }

      envelope.data["host"] = window.location.host;
      const region = getAMGRegion();
      if (region) {
        envelope.data["region"] = region;
      }
      return;
    });
  }

  getInstance(): ApplicationInsights {
    return this.instance;
  }
}

let singleton: AppInsights;
const userPromise = getUserEmail();

const getAppInsights = async (): Promise<ApplicationInsights> => {
  if (!singleton) {
    const email = await userPromise;
    singleton = new AppInsights(email || "");
  }
  return singleton.getInstance();
};

const getAMGRegion = () => {
  const host = window.location.host;
  if (host.includes("grafana.azure.com") || host.includes("azgrafana") || host.includes("msap-grafana.microsoft.com")) {
    const splits = host.split(".");
    return splits && splits.length > 1 ? splits[1] : undefined;
  }
  return undefined;
};

export const getOperationId = () => {
  const guid = v4();
  if (singleton) {
    return singleton.getInstance().context.telemetryTrace.traceID || guid;
  }
  return guid;
};

export const updateOperationId = () => {
  if (singleton) {
    singleton.getInstance().context.telemetryTrace.traceID = v4();
  }
};

export const trackDependencyData = async (
  name: string,
  startTime: Date,
  target: string,
  response: FetchResponse | FetchError,
  properties?: { [key: string]: unknown }
) => {
  const success = "ok" in response && response.ok;
  const appInsights = await getAppInsights();
  appInsights.trackDependencyData({
    name: name,
    id: response.config.requestId ?? v4(),
    startTime,
    duration: Date.now() - startTime.valueOf(),
    responseCode: response.status,
    success,
    // Only send response data on failure so avoid storing
    // huge amounts of redundant and irrelevant information.
    data: success ? undefined : stringify(response.data),
    properties: _.merge(properties, {
      target,
      url: response.config.url,
      method: response.config.method,
    }),
  });
};

export const trackException = async (exception: IExceptionTelemetry, customProperties?: ICustomProperties) => {
  const appInsights = await getAppInsights();
  appInsights.trackException(exception, customProperties);
};

export const trackPageView = async (pageView: IPageViewTelemetry) => {
  const appInsights = await getAppInsights();
  appInsights.trackPageView(pageView);
};

export const trackEvent = async (event: IEventTelemetry, customProperties?: ICustomProperties) => {
  const appInsights = await getAppInsights();
  appInsights.trackEvent(event, customProperties);
};

export const isDev = (): boolean => Boolean(PluginInfo.version && PluginInfo.version.indexOf("dev") !== -1);
