import { css } from "@emotion/css";
import React, { FormEvent } from "react";

import { GrafanaTheme2 } from "@grafana/data";
import { Checkbox, HorizontalGroup, useStyles2 } from "@grafana/ui";
import { SearchState } from "pages/SearchState/SearchTypes";
import { GallerySortPicker } from "../SearchComponents/SortFilter/GallerySortPicker";
import { GalleryTagFilter, TermCount } from "../SearchComponents/Tags/GalleryTagFilter";

interface Props {
  onSortChange: (value?: string) => void;
  onStarredFilterChange?: (event: FormEvent<HTMLInputElement>) => void;
  onTagFilterChange: (tags: string[]) => void;
  getTagOptions: () => Promise<TermCount[]>;
  state: SearchState;
  showStarredFilter?: boolean;
}

export const GalleryActionRow = ({
  onSortChange,
  onStarredFilterChange = () => {},
  onTagFilterChange,
  getTagOptions,
  state,
  showStarredFilter,
}: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div>
      <div className={styles.actionRow}>
        <HorizontalGroup spacing="md" width="auto">
          <GalleryTagFilter
            isClearable={false}
            tags={state.tag}
            tagOptions={getTagOptions}
            onChange={onTagFilterChange}
          />

          {showStarredFilter && (
            <div className={styles.checkboxWrapper}>
              <Checkbox label={"Starred"} onChange={onStarredFilterChange} value={state.starred} />
            </div>
          )}
        </HorizontalGroup>

        <HorizontalGroup spacing="md" width="auto">
          <GallerySortPicker
            onChange={(change) => onSortChange(change?.value)}
            value={state.sort}
            placeholder={"Sort"}
            isClearable
          />
        </HorizontalGroup>
      </div>
    </div>
  );
};

GalleryActionRow.displayName = "ActionRow";

const getStyles = (theme: GrafanaTheme2) => {
  return {
    actionRow: css`
      display: none;

      ${theme.breakpoints.up("md")} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: ${theme.spacing(2)};
        width: 100%;
      }
    `,
    checkboxWrapper: css`
      label {
        line-height: 1.2;
      }
    `,
  };
};
