import { DataSourceInstanceSettings } from "@grafana/data";
import { getTemplateSrv } from "@grafana/runtime";
import _ from "lodash";
import { useDataSourceInstanceSettings } from "stores/DataSourceInstanceSettingsStore";
import { GenevaJsonData } from "types";
import { createLoader } from "util/createLoader";
import { doubleEncode, getStampsFromVar } from "util/dataUtil";
import { makeGenevaRequest } from "../makeGenevaRequest";
import { stampLoader } from "../resources/stampLoader";

export const samplingTypeLoader = createLoader({
  id: "samplingTypes",

  useParams: (account?: string | null, namespace?: string | null, metric?: string | null, stamp?: string) => {
    const instanceSettings = useDataSourceInstanceSettings();
    return !!account && !!namespace && !!metric && ([instanceSettings, account, namespace, metric, stamp] as const);
  },

  replaceVariables: (datasourceInstanceSettings, account: string, namespace: string, metric: string, stamp?: string) =>
    [
      datasourceInstanceSettings,
      getTemplateSrv().replace(account),
      getTemplateSrv().replace(namespace),
      getTemplateSrv().replace(metric),
      _.first(getStampsFromVar(stamp)),
    ] as const,

  load: async (
    dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>,
    account: string,
    namespace: string,
    metric: string,
    defaultStamp?: string
  ) => {
    const stamp = defaultStamp ? defaultStamp : await stampLoader.load(dataSourceInstanceSettings, account);
    const target = `https://${stamp}/user-api/v1/hint/tenant/${doubleEncode(account)}/component/${doubleEncode(
      namespace
    )}/event/${doubleEncode(metric)}/metric`;

    const response = await makeGenevaRequest<string[]>({
      dataSourceInstanceSettings,
      method: "GET",
      path: "/samplingTypes",
      target,
    });

    return response.data;
  },
});
