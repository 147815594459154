import { DataQueryResponseData, DataSourceInstanceSettings, ScopedVars, TimeRange } from "@grafana/data";
import { getTemplateSrv } from "@grafana/runtime";
import { trackEvent } from "appInsights";
import {
  convertHealthAnnotationsResponse,
  convertHealthHistoryResponse,
  convertHealthHistorySeriesResponse,
  convertHealthTopologyResponse,
} from "converters/convertHealthResponse";
import _ from "lodash";
import { AlertConditionJS, CombinationPreviewResult, GenevaJsonData, GenevaQuery, MonitorVar } from "types";
import { getStampsFromVar } from "util/dataUtil";
import { getMonitorDisplayName, isV2Monitor } from "util/monitorUtils";
import { Health, MinInterval } from "../constants";
import { healthLoader } from "./healthLoader";

export async function makeHealthQuery(
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>,
  queries: GenevaQuery[],
  range: TimeRange,
  scopedVars: ScopedVars
) {
  const targets: GenevaQuery[] = queries.filter(
    (target) => !target.hide && !!target.account && target.service === "health"
  );
  const from = range.from.valueOf();
  const to = range.to.valueOf();
  const responseData: DataQueryResponseData[] = [];

  for (const target of targets) {
    const stamp = _.first(getStampsFromVar(target.stamp));
    if (stamp) {
      trackEvent(
        { name: "query health with stamp" },
        {
          stamp,
          account: target.account,
        }
      );
    }
    if (target.healthQueryType === Health.Topology) {
      const result = await healthLoader.topology.load({
        dataSourceInstanceSettings,
        account: target.account || "",
        node: target.topologyNodeId,
        stamp,
      });
      const datum = convertHealthTopologyResponse(
        dataSourceInstanceSettings,
        result,
        target.refId,
        target.account,
        stamp
      );
      responseData.push(datum);
    } else {
      if (target.useResourceVars) {
        if (target.healthQueryType === Health.MonitorEvaluation && target.selectedMonitorVar && target.account) {
          const monitorVar = getTemplateSrv().replace(target.selectedMonitorVar, scopedVars);
          let monitorConfig: MonitorVar | undefined;
          try {
            monitorConfig = JSON.parse(monitorVar);
          } catch (_) {
            monitorConfig = undefined;
          }

          if (monitorConfig) {
            const monitor = await healthLoader.monitor.load({
              dataSourceInstanceSettings,
              account: target.account,
              monitorConfig,
              stamp,
            });

            if (monitor) {
              const result = await healthLoader.monitorEvaluation.load({
                dataSourceInstanceSettings,
                account: target.account,
                metadata: monitor.metadata,
                from,
                to,
                stamp,
              });
              if (isV2Monitor(monitor.metadata)) {
                const response = [
                  ..._.map(result.alertConditions, (alertCondition: AlertConditionJS) => {
                    const firstCond = alertCondition.conditions[0].value;
                    const len = (to - from) / MinInterval;
                    return Array(len).fill(firstCond);
                  }),
                  ..._.map(result.combinationResult, (combination: CombinationPreviewResult) =>
                    combination.evaluatedValue.map((m) => _.first(m.eventEvaluatedResult)?.value)
                  ),
                ];
                const resourceNames = [
                  ..._.map(result.alertConditions, (alertCondition: AlertConditionJS) => alertCondition.name),
                  result.combinationName ?? "",
                ];
                const datum = convertHealthHistoryResponse(
                  response,
                  resourceNames,
                  target.healthHistoryValueTransform,
                  target.refId,
                  from,
                  to
                );
                responseData.push(datum);
              } else {
                const datum = convertHealthHistoryResponse(
                  result.timeSeries?.$values.map((m) => m.value) ?? [[]],
                  [getMonitorDisplayName(monitor)],
                  target.healthHistoryValueTransform,
                  target.refId,
                  from,
                  to
                );
                responseData.push(datum);
              }
            }
          }
        }
        if (target.healthQueryType === Health.WatchdogHealth && target.selectedWatchdogResourceVar && target.account) {
          const resolvedVar = getTemplateSrv().replace(target.selectedWatchdogResourceVar, scopedVars);

          if (resolvedVar) {
            const watchdogResource = await healthLoader.topology.load({
              dataSourceInstanceSettings,
              account: target.account,
              node: resolvedVar,
              stamp,
            });
            const monitors = await healthLoader.monitors.load({
              dataSourceInstanceSettings,
              account: target.account,
              resource: watchdogResource,
              stamp,
            });

            if (!!target.isAnnotationsMode) {
              const result = await healthLoader.watchdogAnnotations.load({
                dataSourceInstanceSettings,
                account: target.account,
                resource: watchdogResource,
                monitors: monitors,
                from,
                to,
                stamp,
              });
              const datum = convertHealthAnnotationsResponse(result, target.refId);
              responseData.push(datum);
            } else {
              const result = await healthLoader.watchdogHistory.load({
                dataSourceInstanceSettings,
                account: target.account,
                resource: watchdogResource,
                monitors: monitors,
                from,
                to,
                stamp,
              });
              const datum = convertHealthHistoryResponse(
                result,
                monitors.map((m) => getMonitorDisplayName(m)),
                target.healthHistoryValueTransform,
                target.refId,
                from,
                to
              );
              responseData.push(datum);
            }
          }
        } else {
          const nodeIdsVar = getTemplateSrv().replace(target.selectedResourcesVar, scopedVars);
          if (nodeIdsVar) {
            const healthResources = await healthLoader.topology.load({
              dataSourceInstanceSettings,
              account: target.account || "",
              node: nodeIdsVar,
              stamp,
            });
            const result = await healthLoader.resourceHistory.load({
              dataSourceInstanceSettings,
              account: target.account || "",
              resources: [healthResources],
              from,
              to,
              stamp,
            });
            const datum = convertHealthHistoryResponse(
              result,
              [healthResources.name],
              target.healthHistoryValueTransform,
              target.refId,
              from,
              to
            );
            responseData.push(datum);
          }
        }
      } else {
        if (
          target.healthQueryType === Health.WatchdogHealth &&
          target.watchdogResource &&
          target.watchdogResource.resourceId &&
          target.account
        ) {
          const monitors = await healthLoader.monitors.load({
            dataSourceInstanceSettings,
            account: target.account,
            resource: target.watchdogResource,
            stamp,
          });
          if (!!target.isAnnotationsMode) {
            const result = await healthLoader.watchdogAnnotations.load({
              dataSourceInstanceSettings,
              account: target.account,
              resource: target.watchdogResource,
              monitors: monitors,
              from,
              to,
              stamp,
            });
            const datum = convertHealthAnnotationsResponse(result, target.refId);
            responseData.push(datum);
          } else {
            const result = await healthLoader.watchdogHistory.load({
              dataSourceInstanceSettings,
              account: target.account,
              resource: target.watchdogResource,
              monitors: monitors,
              from,
              to,
              stamp,
            });
            const datum = convertHealthHistoryResponse(
              result,
              monitors.map((m) => m.name),
              target.healthHistoryValueTransform,
              target.refId,
              from,
              to
            );
            responseData.push(datum);
          }
        } else if (target.healthQueryType === Health.MonitorEvaluation && target.selectedMonitor && target.account) {
          const result = await healthLoader.monitorEvaluation.load({
            dataSourceInstanceSettings,
            account: target.account,
            metadata: target.selectedMonitor?.metadata,
            from,
            to,
            stamp,
          });

          if (isV2Monitor(target.selectedMonitor?.metadata)) {
            const response = [
              ..._.map(result.alertConditions, (alertCondition: AlertConditionJS) => {
                const firstCond = alertCondition.conditions[0].value;
                const len = (to - from) / MinInterval;
                return Array(len).fill(firstCond);
              }),
              ..._.map(result.combinationResult, (combination: CombinationPreviewResult) =>
                combination.evaluatedValue.map((m) => _.first(m.eventEvaluatedResult)?.value)
              ),
            ];
            const resourceNames = [
              ..._.map(result.alertConditions, (alertCondition: AlertConditionJS) => alertCondition.name),
              result.combinationName ?? "",
            ];
            const datum = convertHealthHistoryResponse(
              response,
              resourceNames,
              target.healthHistoryValueTransform,
              target.refId,
              from,
              to
            );
            responseData.push(datum);
          } else {
            const datum = convertHealthHistoryResponse(
              result.timeSeries?.$values.map((m) => m.value) ?? [[]],
              [getMonitorDisplayName(target.selectedMonitor)],
              target.healthHistoryValueTransform,
              target.refId,
              from,
              to
            );
            responseData.push(datum);
          }
        } else if (target.monitorUiType === "dropdown" && target.monitor) {
          const promises = await healthLoader.monitorEvaluationSimple.load({
            dataSourceInstanceSettings,
            account: target.account ?? "",
            namespace: target.namespace ?? "",
            metric: target.metric ?? "",
            monitor: target.monitor ?? "",
            monitorDimensions: target.monitorDimensions ?? [],
            monitorDimensionValues: target.monitorDimensionValues ?? [],
            monitorDimensionOperators: target.monitorDimensionOperators ?? [],
            from,
            to,
            stamp,
          });
          const result = await Promise.all(promises);

          const datum = result.map((r) =>
            convertHealthHistoryResponse(
              r.timeSeries?.$values.map((m) => m.value) ?? [],
              r.timeSeries?.$values.map((m) => m.name) ?? [],
              target.healthHistoryValueTransform,
              target.refId,
              from,
              to
            )
          );
          responseData.push(...datum);
        } else if (target.healthQueryType === Health.SourceMetric && target.selectedMonitor && target.account) {
          const result = await healthLoader.sourceMetrics.load({
            dataSourceInstanceSettings,
            account: target.account,
            metadata: target.selectedMonitor?.metadata,
            from,
            to,
            stamp,
            service: target.service,
            refId: target.refId,
          });
          responseData.push(...result);
        } else {
          const result = await healthLoader.resourceHistory.load({
            dataSourceInstanceSettings,
            account: target.account || "",
            resources: target.selectedResources || [],
            from,
            to,
            stamp,
          });
          const datum = convertHealthHistorySeriesResponse(target, result, from, to);
          responseData.push(datum);
        }
      }
    }
  }

  return { data: responseData };
}
