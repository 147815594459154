import { DataSourceInstanceSettings } from "@grafana/data";
import BackendDataSource from "BackendDataSource";
import { GenevaJsonData } from "types";
import { createLoader } from "util/createLoader";

export const featureFlagsLoader = createLoader({
  id: "featurFlags",

  load: async (dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>) => {
    const backend = new BackendDataSource(dataSourceInstanceSettings);
    const flags: { [key: string]: boolean } = await backend.getResource(`/featureflags`);
    return flags;
  },
});
