export interface TraceQuery {
  traceId?: string;
  traceQueryType?: TraceQueryType;
  transactionSearchParams?: TransactionSearchParams;
}

export interface TransactionSearchFilter {
  filterType: TransactionSearchFilterType;
  fieldName?: TraceFilterName | string;
  filterCategory?: string;
  operator?: string;
  values?: string[];
}
interface TransactionSearchParams {
  filters?: TransactionSearchFilter[];
  search?: string;
}

export type TraceTagType = "tags" | "serviceTags";
export type TraceFilterName = "kind" | "logsEnvironment" | "namespace" | "dataRegion";
export type TraceQueryType = "transactionSearch" | "traceID";
export enum TraceEventType {
  Internal = "0",
  Server = "1",
  Client = "2",
  Producer = "3",
  Consumer = "4",
  All = "5",
}
export type TransactionSearchFilterType = "string" | "integer" | "bool" | "double" | "datetime";
export interface TransactionSearchFilterAtt {
  fieldName: string;
  displayName: string;
  type: TransactionSearchFilterType;
}
export interface TransactionSearchFiterResponse {
  attributes: TransactionSearchFilterAtt[];
  customAttributes: TransactionSearchFilterAtt[];
}
