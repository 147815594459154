import { AppPlugin } from "@grafana/data";
import { App } from "components/App";
import { AppConfig } from "components/AppConfig";

// These exports are used by Grafana itself and so
// need to be kept around even though they aren't
// used within our codebase.

// eslint-disable-next-line import/no-unused-modules
export const plugin = new AppPlugin<{}>().setRootPage(App).addConfigPage({
  title: "Configuration",
  icon: "cog",
  body: AppConfig,
  id: "configuration",
});
