// Keep track of all of the dashboards that can be displayed in the gallery demo
export const workbooksDashboard = {
  annotations: {
    list: [
      {
        builtIn: 1,
        datasource: {
          type: "grafana",
          uid: "-- Grafana --",
        },
        enable: true,
        hide: true,
        iconColor: "rgba(0, 211, 255, 1)",
        name: "Annotations & Alerts",
        type: "dashboard",
      },
    ],
  },
  editable: true,
  fiscalYearStartMonth: 0,
  graphTooltip: 0,
  links: [],
  liveNow: false,
  panels: [
    {
      datasource: {
        type: "datasource",
        uid: "grafana",
      },
      gridPos: {
        h: 8,
        w: 12,
        x: 0,
        y: 0,
      },
      id: 2,
      options: {
        code: {
          language: "plaintext",
          showLineNumbers: false,
          showMiniMap: false,
        },
        content: "# This represents a panel that queries the Geneva datasource!",
        mode: "markdown",
      },
      pluginVersion: "9.5.15",
      title: "Geneva",
      type: "text",
    },
    {
      datasource: {
        type: "datasource",
        uid: "grafana",
      },
      gridPos: {
        h: 8,
        w: 12,
        x: 0,
        y: 8,
      },
      id: 1,
      options: {
        code: {
          language: "plaintext",
          showLineNumbers: false,
          showMiniMap: false,
        },
        content: "# This represents a panel that queries the Azure Monitor datasource!",
        mode: "markdown",
      },
      pluginVersion: "9.5.15",
      title: "Azure Monitor",
      type: "text",
    },
  ],
  refresh: "",
  schemaVersion: 38,
  style: "dark",
  tags: [],
  templating: {
    list: [],
  },
  time: {
    from: "now-6h",
    to: "now",
  },
  timepicker: {},
  timezone: "",
  title: "Workbooks Team Dashboard",
  version: 0,
  weekStart: "",
};

export const lensExplorerDashboard = {
  annotations: {
    list: [
      {
        builtIn: 1,
        datasource: {
          type: "grafana",
          uid: "-- Grafana --",
        },
        enable: true,
        hide: true,
        iconColor: "rgba(0, 211, 255, 1)",
        name: "Annotations & Alerts",
        type: "dashboard",
      },
    ],
  },
  editable: true,
  fiscalYearStartMonth: 0,
  graphTooltip: 0,
  links: [],
  liveNow: false,
  panels: [
    {
      datasource: {
        type: "datasource",
        uid: "grafana",
      },
      gridPos: {
        h: 8,
        w: 12,
        x: 0,
        y: 0,
      },
      id: 2,
      options: {
        code: {
          language: "plaintext",
          showLineNumbers: false,
          showMiniMap: false,
        },
        content: "# This represents a panel that queries the Geneva datasource!",
        mode: "markdown",
      },
      pluginVersion: "9.5.15",
      title: "Geneva",
      type: "text",
    },
    {
      datasource: {
        type: "datasource",
        uid: "grafana",
      },
      gridPos: {
        h: 8,
        w: 12,
        x: 0,
        y: 8,
      },
      id: 1,
      options: {
        code: {
          language: "plaintext",
          showLineNumbers: false,
          showMiniMap: false,
        },
        content: "# This represents a panel that queries the Azure Data Explorer datasource!",
        mode: "markdown",
      },
      pluginVersion: "9.5.15",
      title: "Azure Data Explorer",
      type: "text",
    },
  ],
  refresh: "",
  schemaVersion: 38,
  style: "dark",
  tags: [],
  templating: {
    list: [],
  },
  time: {
    from: "now-6h",
    to: "now",
  },
  timepicker: {},
  timezone: "",
  title: "Lens Explorer Dashboard",
  version: 0,
  weekStart: "",
};

export const promDashboard = {
  annotations: {
    list: [
      {
        builtIn: 1,
        datasource: {
          type: "grafana",
          uid: "-- Grafana --",
        },
        enable: true,
        hide: true,
        iconColor: "rgba(0, 211, 255, 1)",
        name: "Annotations & Alerts",
        type: "dashboard",
      },
    ],
  },
  editable: true,
  fiscalYearStartMonth: 0,
  graphTooltip: 0,
  links: [],
  liveNow: false,
  panels: [
    {
      datasource: {
        type: "datasource",
        uid: "grafana",
      },
      gridPos: {
        h: 8,
        w: 12,
        x: 0,
        y: 8,
      },
      id: 1,
      options: {
        code: {
          language: "plaintext",
          showLineNumbers: false,
          showMiniMap: false,
        },
        content: "# This represents a panel that queries the Prometheus datasource!",
        mode: "markdown",
      },
      pluginVersion: "9.5.15",
      title: "Prometheus",
      type: "text",
    },
  ],
  refresh: "",
  schemaVersion: 38,
  style: "dark",
  tags: [],
  templating: {
    list: [],
  },
  time: {
    from: "now-6h",
    to: "now",
  },
  timepicker: {},
  timezone: "",
  title: "Prometheus Team Dashboard",
  version: 0,
  weekStart: "",
};

export const designDashboard = {
  annotations: {
    list: [
      {
        builtIn: 1,
        datasource: {
          type: "grafana",
          uid: "-- Grafana --",
        },
        enable: true,
        hide: true,
        iconColor: "rgba(0, 211, 255, 1)",
        name: "Annotations & Alerts",
        type: "dashboard",
      },
    ],
  },
  editable: true,
  fiscalYearStartMonth: 0,
  graphTooltip: 0,
  links: [],
  liveNow: false,
  panels: [
    {
      datasource: {
        type: "datasource",
        uid: "grafana",
      },
      gridPos: {
        h: 8,
        w: 12,
        x: 0,
        y: 0,
      },
      id: 1,
      options: {
        code: {
          language: "plaintext",
          showLineNumbers: false,
          showMiniMap: false,
        },
        content: "# This represents a panel that queries the Azure Monitor datasource!",
        mode: "markdown",
      },
      pluginVersion: "10.0.2",
      title: "Azure Monitor",
      type: "text",
    },
  ],
  refresh: "",
  schemaVersion: 38,
  style: "dark",
  tags: [],
  templating: {
    list: [],
  },
  time: {
    from: "now-6h",
    to: "now",
  },
  timepicker: {},
  timezone: "",
  title: "Design Team Dashboard",
  version: 0,
  weekStart: "",
};
