import React from "react";

export const TracesIcon = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 25 25" fill="none">
        <path
          d="M19.7746 10.6392H13.2566C13.1637 10.6392 13.0747 10.6023 13.0091 10.5367C12.9434 10.4711 12.9066 10.3821 12.9066 10.2892V7.80001H11.1555V10.2892C11.1552 10.382 11.1182 10.4708 11.0526 10.5364C10.9871 10.6019 10.8982 10.6389 10.8055 10.6392H4.211C4.02505 10.6392 3.8467 10.7131 3.71522 10.8446C3.58372 10.9761 3.50986 11.1544 3.50986 11.3403V14.5653H5.26091V12.7737C5.26122 12.6808 5.29836 12.5917 5.36418 12.5261C5.43001 12.4605 5.51915 12.4237 5.61208 12.4237H11.1555V17.1035H12.9066V12.4309H18.3736C18.4664 12.4309 18.5554 12.4677 18.6209 12.5334C18.6866 12.599 18.7235 12.688 18.7235 12.7808V14.6083H20.4806V11.3403C20.4806 11.2479 20.4623 11.1563 20.4267 11.0709C20.3912 10.9855 20.3391 10.908 20.2734 10.8429C20.2079 10.7777 20.13 10.7262 20.0444 10.6912C19.9588 10.6563 19.8671 10.6386 19.7746 10.6392Z"
          fill="#005BA1"
        />
        <path
          d="M14.8405 1.24132H9.08681C8.73588 1.24132 8.45137 1.52582 8.45137 1.87676V7.6304C8.45137 7.98135 8.73588 8.26585 9.08681 8.26585H14.8405C15.1914 8.26585 15.476 7.98135 15.476 7.6304V1.87676C15.476 1.52582 15.1914 1.24132 14.8405 1.24132Z"
          fill="url(#trace-linear-gradient0)"
        />
        <path
          d="M22.2098 14.3802H17.318C17.0197 14.3802 16.7778 14.6221 16.7778 14.9205V19.8122C16.7778 20.1106 17.0197 20.3524 17.318 20.3524H22.2098C22.5081 20.3524 22.75 20.1106 22.75 19.8122V14.9205C22.75 14.6221 22.5081 14.3802 22.2098 14.3802Z"
          fill="url(#trace-linear-gradient1)"
        />
        <path
          d="M6.68198 14.3802H1.79025C1.49188 14.3802 1.25 14.6221 1.25 14.9205V19.8122C1.25 20.1106 1.49188 20.3524 1.79025 20.3524H6.68198C6.98033 20.3524 7.22222 20.1106 7.22222 19.8122V14.9205C7.22222 14.6221 6.98033 14.3802 6.68198 14.3802Z"
          fill="url(#trace-linear-gradient2)"
        />
        <path
          d="M14.5205 16.6198H9.62879C9.33042 16.6198 9.08854 16.8617 9.08854 17.16V22.0518C9.08854 22.3501 9.33042 22.592 9.62879 22.592H14.5205C14.8189 22.592 15.0608 22.3501 15.0608 22.0518V17.16C15.0608 16.8617 14.8189 16.6198 14.5205 16.6198Z"
          fill="url(#trace-linear-gradient3)"
        />
        <defs>
          <linearGradient
            id="trace-linear-gradient0"
            x1="11.9636"
            y1="1.24132"
            x2="11.9636"
            y2="8.26585"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#54AEF0" />
            <stop offset="1" stopColor="#1988D9" />
          </linearGradient>
          <linearGradient
            id="trace-linear-gradient1"
            x1="19.7639"
            y1="14.3802"
            x2="19.7639"
            y2="20.3524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#54AEF0" />
            <stop offset="1" stopColor="#1988D9" />
          </linearGradient>
          <linearGradient
            id="trace-linear-gradient2"
            x1="4.23609"
            y1="14.3802"
            x2="4.23609"
            y2="20.3524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#54AEF0" />
            <stop offset="1" stopColor="#1988D9" />
          </linearGradient>
          <linearGradient
            id="trace-linear-gradient3"
            x1="12.0746"
            y1="16.6198"
            x2="12.0746"
            y2="22.592"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#54AEF0" />
            <stop offset="1" stopColor="#1988D9" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
