import { DataSourceJsonData, DataSourceSettings } from "@grafana/data";
import { DataQuery } from "@grafana/schema";
import { DgrepQuery } from "types/DGrep";
import { HealthQuery } from "types/Health";
import { ExemplarTraceIdDestination, MetricsQuery, MissingDataBehavior } from "types/Metrics";
import { TraceQuery } from "types/Traces";
import { Health } from "../api/constants";
import { AzureCredentials } from "../configuration/AzureCredentials";

export type GenevaServiceType = "metrics" | "health" | "traces" | "jsexp" | "dgrep";
export type GenevaSupportedClouds = "public" | "mooncake" | "fairfax" | "ussec" | "usnat";
export interface GenevaQuery extends DataQuery, MetricsQuery, HealthQuery, TraceQuery, DgrepQuery {
  service: GenevaServiceType;
  account?: string;
  stamp?: string[] | string;
  isAnnotationsMode?: boolean;
  missingDataBehavior?: MissingDataBehavior;
  telemetryProperties?: Record<string, string>;
}

export const defaultQuery: Partial<GenevaQuery> = {
  account: "",
  namespace: "",
  service: "metrics",
  metric: "",
  metricsQueryType: "ui",
  samplingType: "",
  dimension: "",
  dimensionFilters: [],
  useCustomSeriesNaming: false,
  groupByValue: "1",
  groupByUnit: "m",
  lockedToTimeRange: false,
  useGrafanaQueryOptionsInterval: true,
  healthQueryType: Health.Topology,
  topologyNodeId: undefined,
  orderBy: "desc",
  showTop: "40",
  orderAggFunc: "avg",
  monitorUiType: "modal",
  dimensionAggregate: [],
  queryType: "metrics",
  dimensionQueryable: [],
  dimensionRegex: [],
  missingDataBehavior: "keepMissingData",
  traceQueryType: "transactionSearch",
  showExemplars: false,
  seriesLimit: "100",
  showLabels: false,
  jsExpression: "",
  layerName: undefined,
  exclusions: undefined,
  env: "",
  kqlQuery: "",
  filterQueryType: 0,
  resultFormat: "table",
  maxResults: "5000",
};

export type GenevaDataSourceSettings = DataSourceSettings<GenevaJsonData>;

export interface GenevaJsonData extends DataSourceJsonData {
  cloud?: GenevaSupportedClouds;
  oauthPassThru?: boolean;
  azureCredentials?: AzureCredentials;
  environment?: string;
  exemplarTraceIdDestinations?: ExemplarTraceIdDestination[];
}

export interface CurrentCloudConfig {
  cloud: GenevaSupportedClouds;
  availableServices: GenevaServiceConfig[];
}

export interface GenevaServiceConfig {
  name: string;
  endpoints: GenevaServiceEndpoint[];
}

export interface GenevaServiceEndpoint {
  environment: string;
  url: string;
}
