import { generateQueryText } from "@azure-observability-ecg-grafana/data-migration";
import { DataQueryResponseData, DataSourceInstanceSettings } from "@grafana/data";
import { getTemplateSrv } from "@grafana/runtime";
import { trackEvent } from "appInsights";
import { convertGenevaResponse } from "converters/convertGenevaResponse";
import { convertHealthTopologyResponse, convertWatchdogTopologyResponse } from "converters/convertHealthResponse";
import _ from "lodash";
import { SuppressionRule } from "panel/types";
import { useDataSourceInstanceSettings } from "stores/DataSourceInstanceSettingsStore";
import {
  GenevaAnnotation,
  GenevaJsonData,
  GenevaServiceType,
  HealthResource,
  MetaDataCollection,
  Monitor,
  MonitorConfiguration,
  MonitorEvaluationResponse,
  MonitorV2Configuration,
  MonitorVar,
  ResourceId,
  ResourceNode,
  Watchdog,
} from "types";
import { DimensionList, GenevaResponse } from "types/GenevaResponse";
import { createLoader } from "util/createLoader";
import { doubleEncode, getStampsFromVar } from "util/dataUtil";
import {
  generateMonitorQuery,
  getMetric,
  getMonitorDimensions,
  getMonitorId,
  getNamespace,
  isV2Monitor,
} from "util/monitorUtils";
import { transformAnnotationLinks } from "util/transformAnnotationLinks";
import {
  MonitorHealthRootDisplayName,
  MonitorHealthRootName,
  MonitorV2HealthRootDisplayName,
  MonitorV2HealthRootName,
} from "../constants";
import { makeGenevaRequest } from "../makeGenevaRequest";
import { samplingTypeLoader } from "../metrics/samplingTypeLoader";
import { stampLoader } from "../resources/stampLoader";

enum PreviewResultType {
  SourceMetrics = 1,
  EventEvaluatedValue = 2,
  EvaluatedValue = 4,
  PredictedRange = 8,
}
interface WatchdogAnnotationHistoryLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  resource: ResourceNode;
  monitors: Monitor[];
  from: number;
  to: number;
  stamp?: string;
}

interface ResourceHistoryLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  resources: ResourceNode[];
  from: number;
  to: number;
  stamp?: string;
}

interface MonitorEvaluationSimpleParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  namespace: string;
  metric: string;
  monitor: string;
  monitorDimensions: string[];
  monitorDimensionValues: string[][];
  monitorDimensionOperators: string[];
  from: number;
  to: number;
  stamp?: string;
}

interface TopologyDirectChildrenLoaderParams {
  stamp?: string;
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  node?: string;
}

interface BatchNodesLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  nodeIds: string[];
  stamp?: string;
}

interface WatchdogLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  resourceType: string;
  resourceName: string;
  stamp?: string;
}

interface MonitorsLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  resource: ResourceNode;
  stamp?: string;
}

interface MonitorLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  monitorConfig: MonitorVar;
  stamp?: string;
}

interface MonitorConfigV1Params {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  component: string;
  event: string;
  stamp?: string;
}

interface MonitorConfigV2Params {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  monitorId: string;
  stamp?: string;
}

interface SuppressionRulesParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  node: string;
  stamp?: string;
}

interface MonitorEvaluationLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  metadata: MetaDataCollection;
  from: number;
  to: number;
  stamp?: string;
}

interface SourceMetricsLoaderParams {
  dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>;
  account: string;
  metadata: MetaDataCollection;
  from: number;
  to: number;
  stamp?: string;
  service: GenevaServiceType;
  refId: string;
}

const healthQueries = {
  healthWatchdogAnnotations: "healthWatchdogAnnotations",
  healthWatchdogHistory: "healthWatchdogHistory",
  healthWatchdog: "healthWatchdog",
  healthBatchNodes: "healthBatchNodes",
  healthResourceHistory: "healthResourceHistory",
  healthTopology: "healthTopology",
  healthDirectChildren: "healthDirectChildren",
  suppressionRules: "suppressionRules",
  monitorEvaluation: "monitorEvaluation",
  monitorConfigV1: "monitorConfigv1",
  monitorConfigV2: "monitorConfigv2",
  monitor: "monitor",
  monitorEvaluationSimple: "monitorEvaluationSimple",
};

const getMonitorV1Config = async (params: MonitorConfigV1Params) => {
  const { account, component, event, dataSourceInstanceSettings, stamp } = params;
  const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);

  const target = `https://${targetStamp}/user-api/v1/config/metrics/tenant/${doubleEncode(
    account
  )}/component/${doubleEncode(component)}/event/${doubleEncode(event)}/monitor`;

  const response = await makeGenevaRequest<MonitorConfiguration[]>({
    dataSourceInstanceSettings,
    method: "GET",
    path: "/health",
    target,
    name: healthQueries.monitorConfigV2,
  });
  return response.data;
};

const getMonitorV2Config = async (params: MonitorConfigV2Params) => {
  const { account, monitorId, dataSourceInstanceSettings, stamp } = params;
  const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);

  const target = `https://${targetStamp}/user-api/v1/config/monitor/tenant/${doubleEncode(
    account
  )}/configuration/${doubleEncode(monitorId)}`;

  const response = await makeGenevaRequest<MonitorV2Configuration>({
    dataSourceInstanceSettings,
    method: "GET",
    path: "/health",
    target,
    name: healthQueries.monitorConfigV2,
  });
  return response.data;
};

export const healthLoader = {
  watchdogAnnotations: createLoader({
    id: healthQueries.healthWatchdogAnnotations,
    replaceVariables: replaceParams,
    load: async ({
      dataSourceInstanceSettings,
      account,
      resource,
      monitors,
      from,
      to,
      stamp,
    }: WatchdogAnnotationHistoryLoaderParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const target = `https://${targetStamp}/user-api/v3/data/health/health-annotations/tenant/${doubleEncode(
        account
      )}/batch-watchdog?from=${from}&to=${to}&normalized=true`;
      const data = monitors.map((m) => {
        return {
          watchdogName: m.name,
          resourceId: resource.resourceId,
        };
      });

      const response = await makeGenevaRequest<
        {
          $values: {
            content: string;
            contextId: string;
            displayName: string;
            id: string;
            modifiedBy: string;
            modifiedAt: string;
            monitorName: string;
            timestamp: string;
            resourceId: ResourceId;
            metadata?: { annotationType?: string; severity?: string };
          }[];
        }[]
      >({
        dataSourceInstanceSettings,
        data,
        path: "/health",
        method: "POST",
        target,
        name: healthQueries.healthWatchdogAnnotations,
      });

      const annotations: GenevaAnnotation[][] = [];

      if (response && response.data.length > 0) {
        response.data.forEach((r) => {
          const annValues = r["$values"];
          if (annValues && annValues.length > 0) {
            const ann: GenevaAnnotation[] = annValues.map((a) => {
              let content = transformAnnotationLinks(a["content"]);

              if (a.metadata?.severity) {
                content = `Severity: ${a.metadata.severity}\n${content}`;
              }

              return {
                content,
                contextId: a["contextId"],
                displayName: a["displayName"],
                id: a["id"],
                modifiedBy: a["modifiedBy"],
                modifiedAt: a["modifiedAt"],
                monitorName: a["monitorName"],
                timestamp: a["timestamp"],
                resourceId: a["resourceId"],
              };
            });
            annotations.push(ann);
          }
        });
      }

      return annotations;
    },
  }),

  watchdogHistory: createLoader({
    id: healthQueries.healthWatchdogHistory,
    replaceVariables: replaceParams,
    load: async ({
      dataSourceInstanceSettings,
      account,
      resource,
      monitors,
      from,
      to,
      stamp,
    }: WatchdogAnnotationHistoryLoaderParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const target = `https://${targetStamp}/user-api/v3/data/health/tenant/${doubleEncode(
        account
      )}/batch-watchdog-health-history?from=${from}&to=${to}&normalized=true`;
      const data = monitors.map((m) => {
        return {
          watchdogName: m.name,
          resourceId: resource.resourceId,
        };
      });

      const response = await makeGenevaRequest<number[][] | undefined>({
        dataSourceInstanceSettings,
        method: "POST",
        path: "/health",
        target,
        data,
        name: healthQueries.healthWatchdogHistory,
      });

      return response.data ?? [];
    },
  }),

  monitorEvaluation: createLoader({
    id: healthQueries.monitorEvaluation,
    replaceVariables: replaceParams,
    load: async ({ dataSourceInstanceSettings, account, metadata, from, to, stamp }: MonitorEvaluationLoaderParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      if (!targetStamp || targetStamp === "") {
        throw new Error("no stamp selected");
      }
      if (isV2Monitor(metadata)) {
        const configuration = await getMonitorV2Config({
          dataSourceInstanceSettings,
          account,
          monitorId: getMonitorId(metadata),
        });
        const previewTarget = `https://${targetStamp}/user-api/v1/tenant/${account}/monitor/preview`;

        const preview = await makeGenevaRequest<MonitorEvaluationResponse>({
          dataSourceInstanceSettings,
          method: "POST",
          path: "/preview",
          target: previewTarget,
          name: healthQueries.monitorEvaluation,
          data: {
            combinations: [getMonitorDimensions(metadata)],
            endTime: to,
            monitorConfiguration: configuration,
            previewResultTypes: [
              PreviewResultType.EvaluatedValue,
              PreviewResultType.EventEvaluatedValue,
              PreviewResultType.PredictedRange,
            ],
            requestId: Date.now() + "",
            startTime: from,
          },
        });

        return (
          {
            ...preview.data,
            alertConditions: configuration.alertConditions,
            combinationName: _.values(getMonitorDimensions(metadata)).join(" "),
          } ?? []
        );
      } else {
        const monitorQuery = _.reduce(
          getMonitorDimensions(metadata),
          (result, value, key) => {
            result += `/${key}/${value}`;
            return result;
          },
          ""
        );
        const target = `https://${targetStamp}/user-api/v2/data/monitorMetrics/monitoringAccount/${doubleEncode(
          account
        )}/metricNamespace/${doubleEncode(getNamespace(metadata))}/metric/${doubleEncode(
          getMetric(metadata)
        )}/monitor/${doubleEncode(getMonitorId(metadata))}${monitorQuery}?StartTime=${from}&EndTime=${to}`;

        const response = await makeGenevaRequest<MonitorEvaluationResponse>({
          dataSourceInstanceSettings,
          method: "GET",
          path: "/health",
          target,
          name: healthQueries.monitorEvaluation,
        });
        return response.data ?? [];
      }
    },
  }),

  sourceMetrics: createLoader({
    id: healthQueries.healthWatchdog,
    replaceVariables: replaceParams,
    queryOptions: { staleTime: Infinity },
    load: async ({
      dataSourceInstanceSettings,
      account,
      metadata,
      from,
      to,
      stamp,
      service,
      refId,
    }: SourceMetricsLoaderParams): Promise<DataQueryResponseData[]> => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);

      const dataTarget = `https://${targetStamp}/user-api/query/v1/language/jarvis/monitoringAccount/${doubleEncode(
        account
      )}`;

      if (isV2Monitor(metadata)) {
        const monitorConfig = await getMonitorV2Config({
          dataSourceInstanceSettings,
          account,
          monitorId: getMonitorId(metadata),
          stamp: targetStamp,
        });

        trackEvent(
          { name: "track monitor source metrics" },
          {
            type: "V2",
            account,
            monitorId: getMonitorId(metadata),
          }
        );

        return await Promise.all(
          monitorConfig.timeSeries.map(async (t) => {
            const dimensions = getMonitorDimensions(metadata);
            const queryStatement = generateQueryText(
              t.metric,
              "",
              "avg",
              "desc",
              t.samplingType,
              "",
              "",
              "",
              _.keys(dimensions),
              _.values(dimensions).map((dim) => [String(dim)])
            );
            const data = {
              service,
              refId,
              endTimeUTC: new Date(to),
              metricNamespace: t.namespace,
              monitoringAccount: account,
              queryStatement,
              resolutionInMilliseconds: 60000,
              resolutionReductionAggregationType: "None",
              selectionCount: 100,
              startTimeUTC: new Date(from),
            };
            const sourceMetricResponse = await makeGenevaRequest<GenevaResponse>({
              dataSourceInstanceSettings,
              method: "POST",
              path: "/sourceMetric",
              target: dataTarget,
              name: "source metric",
              data,
            });

            return convertGenevaResponse(data, sourceMetricResponse.data, 60000);
          })
        );
      } else {
        const monitorConfig = await getMonitorV1Config({
          dataSourceInstanceSettings,
          account,
          component: getNamespace(metadata),
          event: getMetric(metadata),
        });

        trackEvent(
          { name: "track monitor source metrics" },
          {
            type: "V1",
            account,
            component: getNamespace(metadata),
            event: getMetric(metadata),
            templateType: monitorConfig.map((m) => m.templateType).join(),
          }
        );
        const queryStatements = monitorConfig.map((m) => generateMonitorQuery(m, metadata));

        const requests = queryStatements.map((queryStatement) => ({
          service,
          refId,
          endTimeUTC: new Date(to),
          metricNamespace: getNamespace(metadata),
          monitoringAccount: account,
          queryStatement,
          resolutionInMilliseconds: 60000,
          resolutionReductionAggregationType: "None",
          selectionCount: 100,
          startTimeUTC: new Date(from),
        }));
        return await Promise.all(
          requests.map(async (request) => {
            const sourceMetricResponse = await makeGenevaRequest<GenevaResponse>({
              dataSourceInstanceSettings,
              method: "POST",
              path: "/sourceMetric",
              target: dataTarget,
              name: "source metric",
              data: request,
            });

            return convertGenevaResponse(request, sourceMetricResponse.data, 60000);
          })
        );
      }
    },
  }),

  watchdog: createLoader({
    id: healthQueries.healthWatchdog,
    replaceVariables: replaceParams,
    queryOptions: { staleTime: 0 },
    load: async ({
      dataSourceInstanceSettings,
      account,
      resourceType,
      resourceName,
      stamp,
    }: WatchdogLoaderParams): Promise<Watchdog[]> => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const target = `https://${targetStamp}/user-api/v3/data/health/topology/tenant/${doubleEncode(
        account
      )}/resource-type/null/resource-name/null/watchdogs?resourceType=${doubleEncode(
        resourceType
      )}&resourceName=${doubleEncode(resourceName)}`;

      const response = await makeGenevaRequest<Watchdog[]>({
        dataSourceInstanceSettings,
        method: "GET",
        path: "/health",
        target,
        name: healthQueries.healthWatchdog,
      });

      return response.data ?? [];
    },
  }),

  monitors: createLoader({
    id: healthQueries.healthWatchdog,
    replaceVariables: replaceParams,
    queryOptions: { staleTime: 0 },
    load: async ({
      dataSourceInstanceSettings,
      account,
      resource,
      stamp,
    }: MonitorsLoaderParams): Promise<Monitor[]> => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const target = `https://${targetStamp}/user-api/v3/data/health/topology/tenant/${doubleEncode(
        account
      )}/resource-type/${doubleEncode(resource.resourceId?.type)}/resource-name/${doubleEncode(
        resource.resourceId?.name
      )}/watchdogs`;

      const response = await makeGenevaRequest<Watchdog[]>({
        dataSourceInstanceSettings,
        method: "GET",
        path: "/health",
        target,
        name: healthQueries.healthWatchdog,
      });

      const monitors = response.data.reduce((acc, curr) => {
        acc.push(...curr.monitors);
        return acc;
      }, [] as Monitor[]);

      return monitors;
    },
  }),

  monitor: createLoader({
    id: healthQueries.monitor,
    replaceVariables: replaceParams,
    queryOptions: { staleTime: 0 },
    load: async ({
      dataSourceInstanceSettings,
      account,
      monitorConfig,
      stamp,
    }: MonitorLoaderParams): Promise<Monitor> => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      if (!targetStamp || targetStamp === "") {
        throw new Error("no stamp selected");
      }
      const target = `https://${targetStamp}/user-api/v3/data/health/tenant/${doubleEncode(
        account
      )}/resource-type/${doubleEncode(monitorConfig.resourceType)}/resource-name/${doubleEncode(
        monitorConfig.resourceName
      )}/watchdog-name/${doubleEncode(monitorConfig.monitorId)}`;

      const response = await makeGenevaRequest<Monitor>({
        dataSourceInstanceSettings,
        method: "GET",
        path: "/monitor",
        target,
        name: healthQueries.monitor,
      });

      return response.data;
    },
  }),

  batchNode: createLoader({
    id: healthQueries.healthBatchNodes,
    replaceVariables: replaceParams,
    queryOptions: { staleTime: 0 },
    load: async ({ dataSourceInstanceSettings, account, nodeIds, stamp }: BatchNodesLoaderParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const target = `https://${targetStamp}/user-api/v3/data/health/topology/tenant/${doubleEncode(
        account
      )}/batch-nodes`;
      const response = await makeGenevaRequest<
        {
          id: string;
          name: string;
          healthStatus: number;
          offlineStatus: number;
          inRepair: boolean;
          suppressionState: number;
          suppressionReason: number;
          hasChildren: boolean;
          resourceId?: ResourceId;
        }[]
      >({
        dataSourceInstanceSettings,
        path: "/heath",
        method: "POST",
        target,
        data: nodeIds,
        name: healthQueries.healthBatchNodes,
      });

      return response.data.map((c) => {
        return <HealthResource>(<unknown>{
          id: c["id"],
          name: c["name"],
          healthStatus: c["healthStatus"],
          offlineStatus: c["offlineStatus"],
          inRepair: c["inRepair"],
          suppressionState: c["suppressionState"],
          suppressionReason: c["suppressionReason"],
          hasChildren: c["hasChildren"],
          children: [],
          resourceId: c["resourceId"],
        });
      });
    },
  }),

  resourceHistory: createLoader({
    id: healthQueries.healthResourceHistory,
    replaceVariables: replaceParams,
    load: async ({ dataSourceInstanceSettings, account, resources, from, to, stamp }: ResourceHistoryLoaderParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const target = `https://${targetStamp}/user-api/v3/data/health/tenant/${doubleEncode(
        account
      )}/batch-resource-health-history?from=${from}&to=${to}&normalized=true`;
      const data = resources.map((r) => r.resourceId);

      const response = await makeGenevaRequest<number[][] | undefined>({
        path: "/health",
        method: "POST",
        target,
        dataSourceInstanceSettings,
        data,
        name: healthQueries.healthResourceHistory,
      });

      return response.data ?? [];
    },
  }),

  topology: createLoader({
    id: healthQueries.healthTopology,
    useParams: (account?: string, node?: string, stamp?: string) => {
      const dataSourceInstanceSettings = useDataSourceInstanceSettings();
      return account ? ([{ dataSourceInstanceSettings, account, node, stamp }] as const) : false;
    },

    replaceVariables: (params) =>
      [
        {
          dataSourceInstanceSettings: params.dataSourceInstanceSettings,
          account: getTemplateSrv().replace(params.account),
          node: params.node ? getTemplateSrv().replace(params.node) : undefined,
          stamp: _.first(getStampsFromVar(params.stamp)),
        },
      ] as const,
    queryOptions: { staleTime: 0 },

    load: async ({ dataSourceInstanceSettings, account, node, stamp }: TopologyDirectChildrenLoaderParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      if (!targetStamp || targetStamp === "") {
        throw new Error("no stamp selected");
      }
      const targetBase = `https://${targetStamp}/user-api/v3/data/health/topology/nodes/tenant/${doubleEncode(
        account
      )}`;
      const target = node ? `${targetBase}/?nodeId=${doubleEncode(unmapMdmConfigNames(node))}` : targetBase;
      const response = await makeGenevaRequest<{ root?: RawHealthResource }>({
        dataSourceInstanceSettings,
        method: "GET",
        path: "/health",
        target,
        name: healthQueries.healthTopology,
      });

      return convertResponse(response.data);
    },
  }),

  monitorConfigv1: createLoader({
    id: healthQueries.monitorConfigV1,
    useParams: (account?: string | null, component?: string | null, event?: string | null, stamp?: string) => {
      const dataSourceInstanceSettings = useDataSourceInstanceSettings();
      return (
        !!account &&
        !!component &&
        !!event &&
        ([{ dataSourceInstanceSettings, account, component, event, stamp }] as const)
      );
    },
    replaceVariables: replaceParams,
    load: async ({
      dataSourceInstanceSettings,
      account,
      component,
      event,
      stamp,
    }: MonitorConfigV1Params): Promise<MonitorConfiguration[]> =>
      getMonitorV1Config({
        dataSourceInstanceSettings,
        account,
        component,
        event,
        stamp,
      }),
  }),

  monitorConfigv2: createLoader({
    id: healthQueries.monitorConfigV2,
    replaceVariables: replaceParams,
    load: async ({
      dataSourceInstanceSettings,
      account,
      monitorId,
    }: MonitorConfigV2Params): Promise<MonitorV2Configuration> => {
      return getMonitorV2Config({
        dataSourceInstanceSettings,
        account,
        monitorId,
      });
    },
  }),

  async loadTopologyDataFrame(params: TopologyDirectChildrenLoaderParams) {
    // TODO fix params
    return convertHealthTopologyResponse(params.dataSourceInstanceSettings, await healthLoader.topology.load(params));
  },

  async loadMonitorsTopolgyDataFrame(params: WatchdogLoaderParams) {
    return convertWatchdogTopologyResponse(await healthLoader.watchdog.load(params));
  },

  directChildren: createLoader({
    id: healthQueries.healthDirectChildren,
    replaceVariables: replaceParams,
    queryOptions: { staleTime: 0 },
    load: async ({ dataSourceInstanceSettings, account, node, stamp }: TopologyDirectChildrenLoaderParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      if (!targetStamp || targetStamp === "") {
        throw new Error("no stamp selected");
      }
      const targetBase = `https://${targetStamp}/user-api/v3/data/health/topology/nodes/tenant/${doubleEncode(
        account
      )}/?onlyDirectChildren=true`;
      const target = node ? `${targetBase}&nodeId=${doubleEncode(unmapMdmConfigNames(node))}` : targetBase;

      const response = await makeGenevaRequest<{ root?: RawHealthResource }>({
        dataSourceInstanceSettings,
        method: "GET",
        path: "/health",
        target,
        name: healthQueries.healthDirectChildren,
      });

      return convertResponse(response.data);
    },
  }),

  setSuppression: async (
    dataSourceInstanceSettings: DataSourceInstanceSettings<GenevaJsonData>,
    account: string,
    nodeId: string,
    time: number,
    reinstate: boolean,
    stamp?: string
  ) => {
    const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
    account = getTemplateSrv().replace(account);
    const target = `https://${targetStamp}/user-api/v3/data/health/topology/tenant/${doubleEncode(
      account
    )}/nodeId/${doubleEncode(nodeId)}/suppression-state/${reinstate ? "Unsuppress" : "Suppress"}`;
    const data = time;

    return await makeGenevaRequest<unknown>({
      path: "/suppress",
      method: "POST",
      target,
      dataSourceInstanceSettings,
      data,
      name: reinstate ? "Unsuppress" : "Suppress",
    });
  },

  suppressionRules: createLoader({
    useParams: (account?: string, node?: string) => {
      const dataSourceInstanceSettings = useDataSourceInstanceSettings();
      return !!account && !!node && ([{ dataSourceInstanceSettings, account, node }] as const);
    },
    id: healthQueries.suppressionRules,
    replaceVariables: replaceParams,
    queryOptions: { staleTime: 0 },
    load: async ({ dataSourceInstanceSettings, account, node, stamp }: SuppressionRulesParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const target = `https://${targetStamp}/user-api/v3/data/health/topology/tenant/${account}/nodeId/${doubleEncode(
        account
      )}/nodeId/${doubleEncode(node)}/suppression-rules`;
      const response = await makeGenevaRequest<SuppressionRule[]>({
        path: "/health",
        method: "GET",
        target,
        dataSourceInstanceSettings,
        name: healthQueries.suppressionRules,
      });
      return _.first(response.data);
    },
  }),

  monitorEvaluationSimple: createLoader({
    id: healthQueries.monitorEvaluationSimple,
    replaceVariables: (params) =>
      [
        {
          ...params,
          account: getTemplateSrv().replace(params.account),
          namespace: getTemplateSrv().replace(params.namespace),
          metric: getTemplateSrv().replace(params.metric),
          stamp: _.first(getStampsFromVar(params.stamp)),
        },
      ] as const,
    queryOptions: { staleTime: Infinity },
    load: async ({
      dataSourceInstanceSettings,
      account,
      namespace,
      metric,
      monitor,
      monitorDimensions,
      monitorDimensionValues,
      monitorDimensionOperators,
      from,
      to,
      stamp,
    }: MonitorEvaluationSimpleParams) => {
      const targetStamp = stamp ? stamp : await stampLoader.load(dataSourceInstanceSettings, account);
      const samplingTypes = await samplingTypeLoader.load(
        dataSourceInstanceSettings,
        account,
        namespace,
        metric,
        stamp
      );

      const data: { [key: string]: { Item1: boolean; Item2: string[] } } = {};
      for (let i = 0; i < monitorDimensions.length; i++) {
        // i here is not passed from user input
        /* eslint-disable security/detect-object-injection */
        data[monitorDimensions[i]] = {
          Item1: monitorDimensionOperators[i] === "!in", // False means include item2 and true means exclude item2
          Item2: monitorDimensionValues[i],
          /* eslint-enable security/detect-object-injection */
        };
      }
      const target = `https://${targetStamp}/user-api/flight/dq/batchedReadv3/V2/monitoringAccount/${account}/metricNamespace/${namespace}/metric/${metric}?samplingType=${samplingTypes[0]}&startTime=${from}&endTime=${to}&operator=NotEqual&reducer=Average&operand=-86&selectionType=TopValues&top=10&orderBy=Descending`;
      const response = await makeGenevaRequest<{
        results: {
          $values: {
            dimensionList: DimensionList;
          }[];
        };
      }>({
        path: "/health",
        method: "POST",
        target,
        dataSourceInstanceSettings,
        name: healthQueries.monitorEvaluationSimple,
        data,
      });

      const dimensionValues = response.data.results.$values;

      return dimensionValues.map(async (v) => {
        const conditions = v.dimensionList.$values.map((d) => `/${d.key}/${d.value}`).join("");
        const target = `https://${targetStamp}/user-api/v2/data/monitorMetrics/monitoringAccount/${account}/metricNamespace/${namespace}/metric/${metric}/monitor/${monitor}${conditions}?StartTime=${from}&EndTime=${to}`;
        return await makeGenevaRequest<MonitorEvaluationResponse>({
          path: "/monitorEvaluationSimple",
          method: "GET",
          target,
          dataSourceInstanceSettings,
          name: healthQueries.monitorEvaluationSimple,
        }).then((response) => response.data);
      });
    },
  }),
};
interface RawHealthResource {
  id: string;
  name: string;
  healthStatus: number;
  offlineStatus: number;
  inRepair: boolean;
  suppressionState: number;
  suppressionReason: number;
  hasChildren: boolean;
  children?: RawHealthResource[];
  resourceId?: ResourceId;
}

const mapMdmConfigNames = (name: string) => {
  if (name) {
    switch (name.toLowerCase()) {
      case MonitorHealthRootName.toLowerCase():
        return MonitorHealthRootDisplayName;
      case MonitorV2HealthRootName.toLowerCase():
        return MonitorV2HealthRootDisplayName;
      default:
        return name;
    }
  }
  return name;
};

const unmapMdmConfigNames = (name: string) => {
  if (name) {
    switch (name.toLowerCase()) {
      case MonitorHealthRootDisplayName.toLowerCase():
        return MonitorHealthRootName;
      case MonitorV2HealthRootDisplayName.toLowerCase():
        return MonitorV2HealthRootName;
      default:
        return name;
    }
  }
  return name;
};

function convertResponse(response: { root?: RawHealthResource }) {
  if (response && response["root"]) {
    const c = response["root"];
    const root: HealthResource = {
      id: c["id"],
      name: mapMdmConfigNames(c["name"]),
      healthStatus: c["healthStatus"],
      offlineStatus: c["offlineStatus"],
      inRepair: c["inRepair"],
      suppressionState: c["suppressionState"],
      suppressionReason: c["suppressionReason"],
      hasChildren: c["hasChildren"],
      resourceId: c["resourceId"],
      children: [],
    };
    convertChildren(root, c);
    return root;
  }
  return <HealthResource>{};
}

function convertChildren(root: HealthResource, response: RawHealthResource) {
  if (response["children"] && response["children"].length > 0) {
    response["children"].forEach((c) => {
      const r: HealthResource = {
        id: c["id"],
        name: mapMdmConfigNames(c["name"]),
        healthStatus: c["healthStatus"],
        offlineStatus: c["offlineStatus"],
        inRepair: c["inRepair"],
        suppressionState: c["suppressionState"],
        suppressionReason: c["suppressionReason"],
        hasChildren: c["hasChildren"],
        children: [],
        resourceId: c["resourceId"],
      };
      root.children.push(r as Monitor & HealthResource);
      convertChildren(r, c);
    });
  }
}

function replaceParams<T extends { account: string; stamp?: string }>(params: T): [T] {
  return [
    {
      ...params,
      account: getTemplateSrv().replace(params.account),
      stamp: _.first(getStampsFromVar(params.stamp)),
    },
  ];
}
