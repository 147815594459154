/* eslint-disable import/no-unused-modules */

import { locationService } from "@grafana/runtime";
import { debounce } from "lodash";
import { TermCount } from "pages/SearchComponents/Tags/GalleryTagFilter";
import { FormEvent } from "react";
import { StateManagerBase } from "./SearchStateManagerBase";
import {
  GrafanaSearcher,
  SEARCH_SELECTED_LAYOUT,
  SEARCH_SELECTED_SORT,
  SearchQuery,
  SearchState,
  SearchTab,
  defaultQueryParams,
  initialState,
} from "./SearchTypes";
import { parseRouteParams } from "./SearchUtils";

const searcher: GrafanaSearcher | undefined = undefined;

export function getGrafanaSearcher(): GrafanaSearcher {
  // if (!searcher) {
  //   const sqlSearcher = new SQLSearcher();
  //   const useBluge = config.featureToggles.panelTitleSearch;
  //   searcher = useBluge ? new BlugeSearcher(sqlSearcher) : sqlSearcher;

  //   if (useBluge && location.search.includes("do-frontend-query")) {
  //     searcher = new FrontendSearcher(searcher);
  //   }
  // }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return searcher!;
}
const getLocalStorageTab = () => {
  const selectedLayout = localStorage.getItem(SEARCH_SELECTED_LAYOUT);
  if (selectedLayout === SearchTab.SharedByYou) {
    return SearchTab.SharedByYou;
  } else {
    return SearchTab.Shared;
  }
};
export class SearchStateManager extends StateManagerBase<SearchState> {
  updateLocation = debounce((query) => locationService.partial(query, true), 300);
  doSearchWithDebounce = debounce(() => this.doSearch(), 300);
  lastQuery?: SearchQuery;

  lastSearchTimestamp = 0;

  initStateFromUrl(folderUid?: string, doInitialSearch = true) {
    const stateFromUrl = parseRouteParams(locationService.getSearchObject());

    // Force list view when conditions are specified from the URL
    if (stateFromUrl.query || stateFromUrl.datasource || stateFromUrl.panel_type) {
      stateFromUrl.currTab = SearchTab.SharedByYou;
    }

    const currTab = getLocalStorageTab();
    const prevSort = localStorage.getItem(SEARCH_SELECTED_SORT) ?? undefined;
    const sort = currTab === SearchTab.SharedByYou ? stateFromUrl.sort || prevSort : null;

    stateManager.setState({
      ...initialState,
      ...stateFromUrl,
      currTab,
      sort: sort ?? initialState.sort,
      prevSort,
      folderUid: folderUid,
    });

    if (doInitialSearch && this.hasSearchFilters()) {
      this.doSearch();
    }
  }

  /**
   * Updates internal and url state, then triggers a new search
   */
  setStateAndDoSearch(state: Partial<SearchState>) {
    const sort = state.sort || this.state.sort || localStorage.getItem(SEARCH_SELECTED_SORT) || undefined;

    // Set internal state
    this.setState({ sort, ...state });

    // Update url state
    this.updateLocation({
      query: this.state.query.length === 0 ? null : this.state.query,
      tag: this.state.tag,
      datasource: this.state.datasource,
      panel_type: this.state.panel_type,
      starred: this.state.starred ? this.state.starred : null,
      sort: this.state.sort,
    });

    // Prevent searching when user is only clearing the input.
    // We don't show these results anyway
    if (this.hasSearchFilters()) {
      this.doSearchWithDebounce();
    }
  }

  onCloseSearch = () => {
    this.updateLocation({
      search: null,
      folder: null,
      ...defaultQueryParams,
    });
  };

  onClearSearchAndFilters = () => {
    this.setStateAndDoSearch({
      query: "",
      datasource: undefined,
      tag: [],
      panel_type: undefined,
      starred: undefined,
      sort: undefined,
    });
  };

  onQueryChange = (query: string) => {
    this.setStateAndDoSearch({ query });
  };

  onRemoveTag = (tagToRemove: string) => {
    this.setStateAndDoSearch({ tag: this.state.tag.filter((tag) => tag !== tagToRemove) });
  };

  onTagFilterChange = (tags: string[]) => {
    this.setStateAndDoSearch({ tag: tags });
  };

  onAddTag = (newTag: string) => {
    if (this.state.tag && this.state.tag.includes(newTag)) {
      return;
    }

    this.setStateAndDoSearch({ tag: [...this.state.tag, newTag] });
  };

  onStarredFilterChange = (e: FormEvent<HTMLInputElement>) => {
    const starred = e.currentTarget.checked;
    this.setStateAndDoSearch({ starred });
  };

  onClearStarred = () => {
    this.setStateAndDoSearch({ starred: false });
  };

  onSortChange = (sort: string | undefined) => {
    if (sort) {
      localStorage.setItem(SEARCH_SELECTED_SORT, sort);
    } else {
      localStorage.removeItem(SEARCH_SELECTED_SORT);
    }

    if (this.state.currTab === SearchTab.Shared) {
      this.setStateAndDoSearch({ sort, currTab: SearchTab.SharedByYou });
    } else {
      this.setStateAndDoSearch({ sort });
    }
  };

  onLayoutChange = (tab: SearchTab) => {
    localStorage.setItem(SEARCH_SELECTED_LAYOUT, tab);

    if (this.state.sort && tab === SearchTab.Shared) {
      this.setStateAndDoSearch({ currTab: tab, prevSort: this.state.sort, sort: undefined });
    } else {
      this.setStateAndDoSearch({ currTab: tab, sort: this.state.prevSort });
    }
  };

  hasSearchFilters() {
    return (
      this.state.query ||
      this.state.tag.length ||
      this.state.starred ||
      this.state.panel_type ||
      this.state.sort ||
      this.state.currTab === SearchTab.Shared
    );
  }

  getSearchQuery() {
    const q: SearchQuery = {
      query: this.state.query,
      tags: this.state.tag,
      ds_uid: this.state.datasource,
      panel_type: this.state.panel_type,
      location: this.state.folderUid, // This will scope all results to the prefix
      sort: this.state.sort,
      explain: this.state.explain,
      withAllowedActions: this.state.explain, // allowedActions are currently not used for anything on the UI and added only in `explain` mode
      starred: this.state.starred,
    };

    // Only dashboards have additional properties
    if (q.sort?.length && !q.sort.includes("name")) {
      q.kind = ["dashboard", "folder"]; // skip panels
    }

    if (!q.query?.length) {
      q.query = "*";
      if (!q.location) {
        q.kind = ["dashboard", "folder"]; // skip panels
      }
    }

    if (q.panel_type?.length) {
      q.kind = ["panel"];
    }

    return q;
  }

  private doSearch() {
    this.lastQuery = this.getSearchQuery();

    this.setState({ loading: true });

    const searcher = getGrafanaSearcher();

    const searchTimestamp = Date.now();
    const searchPromise = this.state.starred ? searcher.starred(this.lastQuery) : searcher.search(this.lastQuery);

    searchPromise
      .then((result) => {
        // Only keep the results if it's was issued after the most recently resolved search.
        // This prevents results showing out of order if first request is slower than later ones
        if (searchTimestamp > this.lastSearchTimestamp) {
          this.setState({ result, loading: false });
          this.lastSearchTimestamp = searchTimestamp;
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  // This gets the possible tags from within the query results
  getTagOptions = (): Promise<TermCount[]> => {
    const query = this.lastQuery ?? {
      kind: ["dashboard", "folder"],
      query: "*",
    };
    return getGrafanaSearcher().tags(query);
  };
}

let stateManager: SearchStateManager;

export function getSearchStateManager() {
  if (!stateManager) {
    const selectedTab = localStorage.getItem(SEARCH_SELECTED_LAYOUT) as SearchTab;
    const currTab = selectedTab ?? initialState.currTab;

    stateManager = new SearchStateManager({ ...initialState, currTab });
  }

  return stateManager;
}

export function useSearchStateManager() {
  const stateManager = getSearchStateManager();
  const state = stateManager.useState();

  return [state, stateManager] as const;
}
